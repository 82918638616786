import { Component, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

import { CodesService } from '../services/codes.service';
import { AuthService } from '../auth.service';

import { trigger, state, style, animate, transition } from '@angular/animations';
import { Permissions } from '../model/permissions';

@Component({
	selector: 'app-admin',
	host: {
        '(document:click)': 'handleClick($event)',
    },
	templateUrl: './admin.component.html',
	styleUrls: ['./admin.component.css'],
	
	animations: [
    trigger('hideAdminNav', [
      state('active', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('inactive', style({
        transform: 'translate3d(-100%, 0, 0)'
      })),
      transition('active => inactive', animate('400ms ease-in-out')),
      transition('inactive => active', animate('400ms ease-in-out'))
    ]),
  ]
})
export class AdminComponent implements OnInit {
	adminNav:boolean = false;

	permissions:Permissions = new Permissions();

	companiesPermission 	= true;
	usersPermission 		= true;
	locationsPermission 	= true;
	gatewaysPermission 		= true;
	silosPermission 		= true;
	codesPermission 		= true;
	adminPermission			= true;
	userType				= '';

	private state:string = 'inactive';
	public elementRef;
	constructor( private router: Router, private codes: CodesService, private elmRef: ElementRef, private authService: AuthService) {
		this.elementRef = elmRef;
		codes.getCodes();
	}

	ngOnInit() {
    	this.companiesPermission 	= this.permissions.hasCompaniesPermission();
		this.usersPermission 		= this.permissions.hasUsersPermission();
		this.locationsPermission 	= this.permissions.hasLocationsPermission();
		this.gatewaysPermission 	= this.permissions.hasGatewaysPermission();
		this.silosPermission 		= this.permissions.hasSilosPermission();
		this.codesPermission 		= this.permissions.hasCodesPermission();	
		this.adminPermission 		= this.permissions.hasAdminPermission();	   
	}

	closeNav(){
		// console.log('here')
		// this.state = 'inactive';
		// this.adminNav = true;
	}

	openNav(){
		// this.adminNav = false;
	}

	toggleNav(){
		this.state = (this.state === 'inactive' ? 'active' : 'inactive');
	}

	handleClick(event){
       let clickedComponent = event.target;
       let inside = false;
       do {
           if (clickedComponent === this.elementRef.nativeElement) {
               inside = true;
           }
           clickedComponent = clickedComponent.parentNode;
       } while (clickedComponent);
       if(inside){
           // console.log('inside',event);
       }else{
           // console.log('outside',event);
       }
      // this.toggleNav();
   }
}

/*animations: [
		trigger('hideAdminNav', [
			state('inactive', style({transform: 'translateX(0) scale(1)'})),
			state('active',   style({transform: 'translateX(0) scale(1.1)'})),
			transition('inactive => active', animate('100ms ease-in')),
			transition('active => inactive', animate('100ms ease-out')),
			transition('void => inactive', [
			 	style({transform: 'translateX(-100%) scale(1)'}),
			 	animate(100)
			]),
			transition('inactive => void', [
			  animate(100, style({transform: 'translateX(100%) scale(1)'}))
			]),
			transition('void => active', [
			 	style({transform: 'translateX(0) scale(0)'}),
			 	animate(200)
			]),
			transition('active => void', [
				animate(200, style({transform: 'translateX(0) scale(0)'}))
			])
		])
	]*/