import * as lkeys from 'lowercase-keys';
interface LocationJson{
	name: 				string;
	location_id: 		number;
	addr1:	 			string;
	addr2:	 			string;
	city: 				string;
	state: 				string;
	zip: 				string;
	country: 			string;
	email:				string;
	phone: 				string;
	fax: 				string;
	google_map: 		string;
	timezone: 			string;
	latitude: 			string;
	longitude: 			string;
	observes_dst: 		string;
	status_cde:			string;
	group_name:			string;
	group_id:			number;
	site_contact_name: 	string;
	total_rows:			number;
	state_id:			number;
	country_id:			number;
}

export class Location implements LocationJson{

	static fromJSON( jsonObj ): Location{
		let location = Object.create( Location.prototype );

		let obj = Object.assign( location, lkeys(jsonObj));
		return obj;
	}

	constructor(
				 public name: string
				, public location_id: number
				, public addr1: string
				, public addr2: string
				, public city: string
				, public state: string
				, public zip: string
				, public country: string
				, public email: string
				, public phone: string
				, public fax: string
				, public google_map: string
				, public timezone: string
				, public latitude: string
				, public longitude: string
				, public observes_dst: string
				, public status_cde: string
				, public group_name: string
				, public group_id: number
				, public site_contact_name: string
				, public total_rows: number
				, public state_id: number
				, public country_id: number
			 ){ 		
    }

    
   
}
