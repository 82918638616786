// tslint:disable: indent
import { Component, OnInit, OnDestroy, ElementRef, Compiler } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormControl, ReactiveFormsModule, FormArray } from '@angular/forms';

import { UsersService } from '../../services/users.service';
import { GroupsService } from '../../services/groups.service';
import { LocationsService } from '../../services/locations.service';
import { DevicesService } from '../../services/devices.service';
import { CountriesService } from '../../services/countries.service';
import { StatesService } from '../../services/states.service';

import { User } from '../../model/user';
import { Group } from '../../model/group';
import { Location } from '../../model/location';
import { Device } from '../../model/device';
import { Permissions } from '../../model/permissions';
import { Country } from '../../model/country';
import { State } from '../../model/state';

import { FilterPipe } from '../../pipe';
// import * as $ from 'jquery';
declare var $: any;
@Component({
	selector: 'app-user-edit',
	templateUrl: './user-edit.component.html',
	styleUrls: ['./user-edit.component.css'],
})
export class UserEditComponent implements OnInit, OnDestroy {
	public permissions = new Permissions();
	private user: Observable<User[]>;
	private groups: Observable<Group[]>;
	private locations: Observable<Location[]>;
	private userLocations: Observable<Location[]>;
	private devices: Observable<Device[]>;
	private userDevices: Observable<Device[]>;
	public countries: Observable<Country[]>;
	public states: Observable<State[]>;
	isAdmin: boolean = false;
	loading = false;

	private user$;
	private locations$;
	private userDevices$;
	private devices$;

	public hasError: boolean;
	public message: string = '';

	// private user_id;
	private iid;
	user_id = new UntypedFormControl(0);
	user_name = new UntypedFormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(20)]);
	password = new UntypedFormControl('', [
		Validators.required,
		Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/),
	]);
	confirmpassword = new UntypedFormControl('');
	addr1 = new UntypedFormControl('');
	addr2 = new UntypedFormControl('');
	city = new UntypedFormControl('');
	company = new UntypedFormControl('');
	country_id = new UntypedFormControl('', [Validators.required]);
	email = new UntypedFormControl('', [Validators.required, Validators.pattern(/\S+@\S+\.\S+/)]);
	fax = new UntypedFormControl('');
	name = new UntypedFormControl('', [Validators.required]);
	phone = new UntypedFormControl('', [Validators.required, Validators.maxLength(25)]);
	state_id = new UntypedFormControl('', [Validators.required]);
	status_cde = new UntypedFormControl('a');
	zip = new UntypedFormControl('');
	user_type = new UntypedFormControl('F');
	group_id = new UntypedFormControl('', [Validators.required]);
	queryString = new UntypedFormControl('');
	queryStringDevice = new UntypedFormControl('');

	// userFormss: FormGroup;
	userFormss: UntypedFormGroup = this.builder.group({
		user_id: this.user_id,
		user_name: this.user_name,
		// password: 	this.password,
		addr1: this.addr1,
		addr2: this.addr2,
		city: this.city,
		company: this.company,
		country_id: this.country_id,
		email: this.email,
		fax: this.fax,
		name: this.name,
		phone: this.phone,
		state_id: this.state_id,
		status_cde: this.status_cde,
		zip: this.zip,
		user_type: this.user_type,
		group_id: this.group_id,
		queryString: this.queryString,
		queryStringDevice: this.queryStringDevice,
	});

	userPassword: UntypedFormGroup = this.builder.group({ user_id: this.user_id, password: this.password, confirmpassword: this.confirmpassword });

	disableBtn = false;
	userTypes: any = [];
	selectedLocations: any = [];
	filteredDevices: any = [];
	selectedDevices: any = [];
	isEditable: boolean = true;
	canUpdate = false;

	constructor(
		private groupsService: GroupsService,
		private usersService: UsersService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private builder: UntypedFormBuilder,
		private locationsService: LocationsService,
		private deviceService: DevicesService,
		private elRef: ElementRef,
		private countriesService: CountriesService,
		private statesService: StatesService
	) {
		this.usersService.hasError.subscribe((x) => (this.hasError = x));
		this.usersService.message.subscribe((x) => (this.message = x));

		const codes = JSON.parse(localStorage.getItem('codes'));

		for (let x of codes) {
			if (x.TYPE.toLowerCase() === 'user_type') {
				if (this.getUserType() === 'D' && (x.CODE.toUpperCase() === 'D' || x.CODE.toUpperCase() === 'F')) {
					this.userTypes.push(x);
				} else if (this.getUserType() === 'A' || this.getUserType() === 'B') {
					this.userTypes.push(x);
				}
			}
		}
		this.isUserAdmin();
		this.canUpdate = ['A', 'B', 'D'].filter((type) => this.getUserType() === type).length > 0;
	}

	ngOnInit() {
		if (!this.permissions.hasUsersPermission()) {
			this.router.navigateByUrl('devices');
		}

		this.iid = 0;
		this.iid = this.activatedRoute.snapshot.params['id'];
		// this.iid = parseInt(this.iid, 0) || null;
		this.usersService.getUser(this.iid);
		this.locations = this.locationsService.locations;

		this.userLocations = this.locationsService.userLocations;
		this.setUserLocations();

		this.groupsService.getGroups();
		this.groups = this.groupsService.groups;

		this.deviceService.getUserDevices(this.iid);
		this.userDevices = this.deviceService.userDevices;

		this.user = this.usersService.user;
		this.countries = this.countriesService.getCountries();
		this.states = this.statesService.getStates();

		this.user$ = this.user.subscribe((usr) => {
			this.loading = true;
			usr
				.filter((x) => x.user_id === this.iid)
				.map((u) => {
					this.user_id = new UntypedFormControl(u.user_id);
					this.user_name = new UntypedFormControl(u.user_name, [Validators.required, Validators.minLength(3), Validators.maxLength(20)]);
					this.addr1 = new UntypedFormControl(u.addr1);
					this.addr2 = new UntypedFormControl(u.addr2);
					this.city = new UntypedFormControl(u.city);
					this.company = new UntypedFormControl(u.company);
					this.country_id = new UntypedFormControl(u.country_id, [Validators.required]);
					this.email = new UntypedFormControl(u.email, [Validators.required, Validators.pattern(/\S+@\S+\.\S+/)]);
					this.fax = new UntypedFormControl(u.fax);
					this.name = new UntypedFormControl(u.name, [Validators.required]);
					this.phone = new UntypedFormControl(u.phone, [Validators.required, Validators.maxLength(25)]);
					this.state_id = new UntypedFormControl(u.state_id, [Validators.required]);
					this.status_cde = new UntypedFormControl(u.status_cde.trim().toLowerCase());
					this.zip = new UntypedFormControl(u.zip);
					this.user_type = new UntypedFormControl(u.user_type.trim().toUpperCase(), [Validators.required]);
					this.group_id = new UntypedFormControl(u.group_id);
					this.queryString = new UntypedFormControl('');
					this.queryStringDevice = new UntypedFormControl('');

					this.locationsService.getLocations(null, 1, 1000, u.group_id);
					this.setUserDevices(u.group_id);

					this.locationsService.getUserLocations(this.iid, this.group_id.value);

					this.userFormss = this.builder.group({
						user_id: this.user_id,
						user_name: this.user_name,
						addr1: this.addr1,
						addr2: this.addr2,
						city: this.city,
						company: this.company,
						country_id: this.country_id,
						email: this.email,
						fax: this.fax,
						name: this.name,
						phone: this.phone,
						state_id: this.state_id,
						status_cde: this.status_cde,
						zip: this.zip,
						user_type: this.user_type,
						group_id: this.group_id,
						queryString: this.queryString,
						queryStringDevice: this.queryStringDevice,
					});

					this.user_id = new UntypedFormControl(u.user_id);
					this.userPassword = this.builder.group({ user_id: this.user_id, password: this.password, confirmpassword: this.confirmpassword });
					this.loading = false;

					let uType = u.user_type.trim().toUpperCase();
					this.isEditable = !(this.getUserType() === 'A' || this.getUserType() === 'B') && (uType === 'A' || uType === 'B') ? false : true;
					this.isUserAdmin();
				});
		});
		this.groups.subscribe((g) => {
			console.log('ffff', g[0])

			if (g.length == 1) {
				g.map((a) => this.userFormss.controls['group_id'].setValue(a.group_id));
			}
		});
	}

	isUserAdmin() {
		this.userFormss.get('user_type').valueChanges.subscribe((value) => {
			if (value == 'D' || value == 'F') {
				this.userFormss.get('group_id').setValidators(Validators.required);
			} else {
				this.userFormss.get('group_id').setValidators(null);
			}
			this.userFormss.get('group_id').updateValueAndValidity();
		});
	}

	saveUser() {
		this.message = '';
		if (!this.canUpdate) {
			this.hasError = true;
			this.message = 'Sorry! you do not have permission to edit';
			this.scrollTop();
			return false;
		}
		let userObj = { user: this.userFormss.value, locations: this.selectedLocations, devices: this.selectedDevices };
		this.usersService.saveUser(userObj, this.iid);
		this.usersService.hasError.subscribe((x) => (this.hasError = x));
		this.usersService.message.subscribe((x) => {
			this.message = x;
			this.scrollTop();
		});
		this.usersService.user_id.subscribe((x) => {
			this.userFormss.controls['user_id'].setValue(x);
			this.locationsService.getUserLocations(x, this.group_id.value);
			this.setUserLocations();
		});
	}

	deleteUser() {
		this.userFormss.controls['status_cde'].setValue('z');
		this.message = '';
		if (!this.canUpdate) {
			this.hasError = true;
			this.message = 'Sorry! you do not have permission to delete';
			this.scrollTop();
			return false;
		}
		let userObj = { user_id: this.userFormss.value.user_id };
		this.usersService.deleteUser(userObj);
		this.usersService.hasError.subscribe((x) => (this.hasError = x));
		this.usersService.message.subscribe((x) => {
			this.message = x;
			this.scrollTop();
		});
		this.usersService.user_id.subscribe((x) => {
			this.userFormss.controls['user_id'].setValue(x);
			this.locationsService.getUserLocations(x, this.group_id.value);
			this.setUserLocations();
		});
		return false;
	}

	getLocationsByGroupID(gid) {
		this.selectedDevices.splice(0);
		this.selectedLocations.splice(0);
		this.locationsService.getLocations(null, 1, 1000, gid);
		this.getDevicesByLocation(gid);
	}

	preventSubmit() {
		return false;
	}

	savePassword(v) {
		this.usersService.savePassword(v.value);
		this.usersService.hasError.subscribe((x) => {
			this.hasError = x;
			if (this.hasError == false) {
				$('#updatePassword').modal('hide');
			}
		});
		this.usersService.message.subscribe((x) => (this.message = x));
	}

	addLocation(name, id, e) {
		let index = this.selectedLocations.map((x) => x.id).indexOf(id);
		if (e.target.checked && index < 0) {
			this.selectedLocations.push({ name: name, id: id });
			this.getDevicesByLocation();
		} else {
			this.removeLocation(id);
		}
	}

	removeLocation(id) {
		let el = this.elRef.nativeElement.querySelector('.loc_' + id);
		if (el) el.checked = false;
		this.selectedLocations.forEach((item, index) => {
			if (id == item.id) {
				this.selectedLocations.splice(index, 1);
				this.getDevicesByLocation();
			}
		});
	}
	isLocationSelected(id) {
		let index = this.selectedLocations.map((x) => x.id).indexOf(id);
		return index < 0 ? false : true;
	}

	setUserLocations() {
		this.selectedLocations = [];
		this.locations$ = this.userLocations.subscribe(
			(x) => {
				console.log("selectedLocations", this.selectedLocations, x);
				this.selectedLocations.splice(0);
				x.map((l) => {
					this.selectedLocations.push({ name: l.name, id: l.location_id });
				});
			},
			() => {},
			() => {
				this.getDevicesByLocation();
			}
		);
	}

	getDevicesByLocation(gid?) {
		if (!gid) {
			gid = this.userFormss.value.group_id;
		}
		let locList = this.selectedLocations.map(item => item.id).join(',');
		// this.selectedLocations.forEach((item, index) => {
		// 	locList = locList + ',' + item.id;
		// });

		this.deviceService.getDevicesAdminByLocationId(locList, gid).subscribe((x) => {
			this.filteredDevices.splice(0);
			x.map((l) => {
				this.filteredDevices.push({ name: l.device_name, id: l.device_id });
			});
		});
	}
	isDeviceSelected(id) {
		let index = this.selectedDevices.map((x) => x.id).indexOf(id);
		return index < 0 ? false : true;
	}

	setUserDevices(gid?) {
		this.selectedDevices = [];
		this.userDevices$ = this.userDevices.subscribe((x) => {

			console.log("SELECTED DEVICES", this.selectedDevices);

			this.selectedDevices.splice(0);
			x.map((l) => {
				this.selectedDevices.push({ name: l.device_name, id: l.device_id });
			});
			this.getDevicesByLocation(gid);
		});
	}

	addDevice(name, id, e) {
		let index = this.selectedDevices.map((x) => x.id).indexOf(id);
		if (e.target.checked && index < 0) {
			this.selectedDevices.push({ name: name, id: id });
		} else {
			this.removeDevice(id);
		}
	}

	removeDevice(id) {
		let el = this.elRef.nativeElement.querySelector('.device_' + id);
		if (el) el.checked = false;
		this.selectedDevices.forEach((item, index) => {
			if (id == item.id) {
				this.selectedDevices.splice(index, 1);
			}
		});
	}

	resetPassword(user_name, email) {
		this.message = '';
		let reset = { user_name: user_name, email: email };
		this.usersService.resetPasswordAdmin(reset);
		this.usersService.hasError.subscribe((x) => (this.hasError = x));
		this.usersService.message.subscribe((x) => (this.message = x));
		return false;
	}

	ngOnDestroy() {
		if (this.user$) this.user$.unsubscribe();
		// if( this.groups$ ) this.groups$.unsubscribe();
		if (this.locations$) this.locations$.unsubscribe();
		// if( this.userLocations$ ) this.userLocations$.unsubscribe();
		if (this.devices$) this.devices$.unsubscribe();
		if (this.userDevices$) this.userDevices$.unsubscribe();
	}

	/* logged in user type*/
	getUserType() {
		return this.permissions.userType;
	}

	isLoggedInUser(id) {
		return id === this.permissions.user_id;
	}

	getGroupByID(id): any {
		let jj: any = {};
		this.groups.subscribe((x) =>
			x
				.filter((a) => a.group_id === id)
				.map((b) => {
					jj = b;
				})
		);
		return jj;
	}

	resendWelcomeEmail() {
		this.hasError = false;
		this.message = '';
		console.log(this.user_id.value);
		if (!this.canUpdate) {
			return false;
		}
		this.usersService.resendWelcomeEmail(this.user_id.value).subscribe(
			(resp) => {
				this.message = `Welcome email has been sent to ${this.name.value}`;
			},
			(err) => {
				console.log(err);
				this.hasError = true;
				this.message = `There was an error sending welcome email to ${this.name.value}. Try again or contact administrator.`;
				this.scrollTop();
			},
			() => {
				this.scrollTop();
			}
		);
		return false;
	}
	scrollTop() {
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}
}
