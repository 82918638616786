import { Injectable } 							from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';


import { environment} from '../../environments/environment';
import { Group } from '../model/group';
import { UtilService } from './util.service';

@Injectable()
export class GroupsService {
	private groupUrl = environment.apiurl + '/location/getGroups';

	public hasError:Subject<boolean> = new Subject<boolean>();
	public message:Subject<string> = new Subject<string>();
	public group_id:Subject<number> = new Subject<number>();

	private _groups: BehaviorSubject<Group[]> = new BehaviorSubject<Group[]>( [] );
	public groups: Observable<Group[]> 		= this._groups.asObservable();

	// constructor (private http: Http) {}
	constructor (private util: UtilService, private router: Router) {}

	public getGroups(group_id?:number,page?:number,recordsPerPage?:number,status_cde:string=''): void {
		const url = this.groupUrl // + '&group_id=' + group_id + '&page=' + page + '&recordsPerPage=' + recordsPerPage  + '&status_cde=' + status_cde;

		const params: any = {};
		if(group_id !=null) params.group_id = group_id;
		if(page !=null) params.page = page;
		if(recordsPerPage !=null) params.recordsPerPage = recordsPerPage;
		if(status_cde !=null) params.status_cde = status_cde;
		
		
		this.util._getData( url, params )
			.pipe(map(ret=> {
				console.log("return", ret);
				
				return ret.data;
			}))
			.subscribe( groupJSON =>{

				const groups = groupJSON.map(g => Group.fromJSON( g ));
				this._groups.next( groups );
			})
	}

	public setGroup(body): void {
		let url = environment.apiurl + '/location/saveGroup';
		this.util._postData( url,body )
			.subscribe( 
							res=>{
									console.log('group id',res.data);
									this.group_id.next(res.data.group_id);
									this.router.navigateByUrl('/administration/group/edit/' + res.data.group_id);
								},
							(err)=>{
								console.log("Save Group Error", err.error.message);
								console.log('Error saving group'); this.hasError.next(true); 
								this.message.next( err.error.message )
								console.log(err);
							},
							()=>{console.log('saving group completed');this.hasError.next(false); this.message.next('Company information is saved successfully.')}
						)
	}
	
	public deleteGroup(body){
		let url = environment.apiurl + '/location/deleteGroup';
		this.util._postData( url,body )
			.subscribe( 
							()=>this.router.navigateByUrl('/administration/groups'),
							()=>{console.log('Error deleting Rules')},
							()=>console.log('deleting device completed')
						)
	}

	
	public getAllGroups( ):Observable<Group[]>{

		return this.util._requestData(this.groupUrl)
		  .pipe(map(ret=>ret.data))
	      .pipe(map(cJSON => {
		        const grps = cJSON.map(jsonobj => Group.fromJSON(jsonobj));
		        return grps;
		      }))
	}
}
