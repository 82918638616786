import { Injectable } from '@angular/core';
import { Router } from '@angular/router';


@Injectable({
	providedIn: 'root',
})
export class TwoFAGaurdService  {
	constructor(private router: Router) {}

	canActivate() {
		const twoFA = localStorage.getItem('set2fa');
		if (twoFA && twoFA.length) {
			return true;
		} else {
			this.router.navigateByUrl('/login');
			return false;
		}
	}
}
