import { Component, OnInit, OnDestroy  } from '@angular/core';
import {Router, ActivatedRoute, Params}  from '@angular/router';
import { Observable } from 'rxjs';
import { FormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';

import { RulesService } from '../../services/rules.service';
import { Rules } from '../../model/rules';
import { DevicesService } from '../../services/devices.service';
import { Device } from '../../model/device';
import { Permissions } from '../../model/permissions';

@Component({
  selector: 'app-rules-notification',
  templateUrl: './rules-notification.component.html',
  styleUrls: ['./rules-notification.component.css']
})
export class RulesNotificationComponent implements OnInit {
	public permissions:Permissions = new Permissions();
  	public hasError:boolean;
	public message:string;
	notificationModel: any = {};
	device_id:number;

	private llrules: Observable<Rules>;
	private lrules: Observable<Rules>;
	private hrules: Observable<Rules>;
	private hhrules: Observable<Rules>;
	private statusrules: Observable<Rules>;

	private _llrules: Rules;
	private _lrules: Rules;
	private _hrules: Rules;
	private _hhrules: Rules;
	private _statusrules: Rules;
	device_name:string = '';
	public canUpdate = false;

	constructor( 
			private activatedRoute: ActivatedRoute, 
			private devicesService:DevicesService, 
			private rulesServices:RulesService,
			private router: Router, 
			private formbuilder: UntypedFormBuilder
			) { 

			for(let t of [1,2,3,4,5,6]){
				
				this.notificationModel['email_' + t] 				   = '';
				this.notificationModel['email_' + t + '_name'] 		   = '';				
				this.notificationModel['email_' + t + '_name_ll' ]     = false;
				this.notificationModel['email_' + t + '_name_l' ]      = false;
				this.notificationModel['email_' + t + '_name_h' ]      = false;
				this.notificationModel['email_' + t + '_name_hh' ]     = false;
				this.notificationModel['email_' + t + '_name_status' ] = false;

				this.notificationModel['sms_' + t] 				   = '';
				this.notificationModel['sms_' + t + '_name'] 		   = '';				
				this.notificationModel['sms_' + t + '_name_ll' ]     = false;
				this.notificationModel['sms_' + t + '_name_l' ]      = false;
				this.notificationModel['sms_' + t + '_name_h' ]      = false;
				this.notificationModel['sms_' + t + '_name_hh' ]     = false;
				this.notificationModel['sms_' + t + '_name_status' ] = false;
			}
			
			this.activatedRoute.params.subscribe((params: Params) => {
			    this.device_id = params['id'];
			    let level = ['ll','l','h','hh','status'];

			    level.forEach((item, index) => {
				    this.notificationModel[item] = { 
												'deviceChannel_id': 0, 'device_id': this.device_id, 'type': item,
												'email_1': '', 'email_1_name': '',  
												'email_2': '', 'email_2_name': '', 
												'email_3': '', 'email_3_name': '', 
												'email_4': '', 'email_4_name': '', 
												'email_5': '', 'email_5_name': '', 
												'email_6': '', 'email_6_name': '', 

												'sms_1': '', 'sms_1_name': '',  
												'sms_2': '', 'sms_2_name': '', 
												'sms_3': '', 'sms_3_name': '', 
												'sms_4': '', 'sms_4_name': '', 
												'sms_5': '', 'sms_5_name': '', 
												'sms_6': '', 'sms_6_name': '', 
											}
				});
				this.devicesService.getDeviceByIdAdmin(this.device_id);	
				this.devicesService.deviceAdmin.subscribe(x=>{
														x.filter(y=>y.device_id == this.device_id)
														.map(d=>{
																	this.device_name = d.device_name;
																})		
													});
			 });

			 this.canUpdate = ['A','B','D'].filter(type=>this.getUserType() === type).length > 0; 
	}

	ngOnInit() {
		this.rulesServices.getRules( this.device_id );
		this.llrules		= this.rulesServices.llrules;
		this.lrules			= this.rulesServices.lrules;
		this.hrules			= this.rulesServices.hrules;
		this.hhrules		= this.rulesServices.hhrules;	
		this.statusrules	= this.rulesServices.statusrules;	

		let l 		=  this.llrules.subscribe(x=> { this._llrules = x; this.setNotification(x,'ll'); } );
		let ll 		=  this.lrules.subscribe(x=> { this._lrules = x; this.setNotification(x,'l'); } );
		let h 		=  this.hrules.subscribe(x=> { this._hrules = x; this.setNotification(x,'h'); } );
		let hh 		=  this.hhrules.subscribe(x=> { this._hhrules = x; this.setNotification(x,'hh'); } );
		let status 	=  this.statusrules.subscribe(x=> { this._statusrules = x; this.setNotification(x,'status'); } );
	}

	saveNotification(){
		this.hasError = false;
		this.message  = '';

		if(!this.canUpdate){
			this.hasError = true;
			this.message = 'Sorry! You do not have permission to edit notifications.';
			return false;
		}
		
		console.log("this.notificationModel before", this.notificationModel);

		let level = ['ll','l','h','hh','status'];
		([1,2,3,4,5,6]).forEach((item)=>{
			level.forEach((threshold)=>{
				if(this.notificationModel['sms_'+item + '_name_' + threshold]){
					this.notificationModel[threshold]['sms_' + item] = this.notificationModel['sms_'+item];
					this.notificationModel[threshold]['sms_' + item + '_name'] = this.notificationModel['sms_'+item + '_name'];
				}	
				if(this.notificationModel['email_'+item + '_name_' + threshold]){
					this.notificationModel[threshold]['email_' + item] = this.notificationModel['email_'+item];
					this.notificationModel[threshold]['email_' + item + '_name'] = this.notificationModel['email_'+item + '_name'];
				}				
			})
		})
		
		
		console.log("this.notificationModel", this.notificationModel);

		this.rulesServices.saveRules(this.notificationModel,'saveNotifications',this.device_id);
		this.rulesServices.hasError.subscribe(x=>this.hasError=x);
		this.rulesServices.message.subscribe(x=>this.message=x);
	}

	updateNotification(event,type, index,notificationMethod){
		// console.log(this.notificationModel)
		if( event.target.checked ){			
			this.notificationModel[type][notificationMethod+ '_' + index + '_name'] = this.notificationModel[notificationMethod+ '_' + index + '_name'] || ''; 
			this.notificationModel[type][notificationMethod+ '_' + index ] = this.notificationModel[notificationMethod+ '_' + index ] || ''; 
		}else{  
			this.notificationModel[type][notificationMethod+ '_' + index + '_name'] = '';
			this.notificationModel[type][notificationMethod+ '_' + index ] = '';
		}
	}

	setNotification(r,level:string){				
		
		for(let t of [1,2,3,4,5,6]){
			if( eval('r.email_' + t) && eval('r.email_' + t).toString().length ){
				this.notificationModel['email_' + t] = eval('r.email_' + t);
				this.notificationModel['email_' + t + '_name'] = eval('r.email_' + t + '_name');
			}
			if(  eval('r.email_' + t) ){
				this.notificationModel['email_' + t + '_name_' + level] = eval('r.email_' + t).toString().length?true:false;
			}

			if( eval('r.sms_' + t) && eval('r.sms_' + t).toString().length ){
				this.notificationModel['sms_' + t] = eval('r.sms_' + t);
				this.notificationModel['sms_' + t + '_name'] = eval('r.sms_' + t + '_name');
			}
			if(  eval('r.sms_' + t) ){
				this.notificationModel['sms_' + t + '_name_' + level] = eval('r.sms_' + t).toString().length?true:false;
			}
		}
		
		this.notificationModel[level] = { 
											'deviceChannel_id': r.deviceChannel_id, 'device_id': this.device_id, 'type': level,
											'email_1': r.email_1 ? r.email_1 : '', 'email_1_name': r.email_1_name ? r.email_1_name : '',  
											'email_2': r.email_2 ? r.email_2 : '', 'email_2_name': r.email_2_name ? r.email_2_name : '', 
											'email_3': r.email_3 ? r.email_3 : '', 'email_3_name': r.email_3_name ? r.email_3_name : '', 
											'email_4': r.email_4 ? r.email_4 : '', 'email_4_name': r.email_4_name ? r.email_4_name : '', 
											'email_5': r.email_5 ? r.email_5 : '', 'email_5_name': r.email_5_name ? r.email_5_name : '', 
											'email_6': r.email_6 ? r.email_6 : '', 'email_6_name': r.email_6_name ? r.email_6_name : '', 

											'sms_1': r.sms_1 ? r.sms_1 : '', 'sms_1_name': r.sms_1_name ? r.sms_1_name : '',  
											'sms_2': r.sms_2 ? r.sms_2 : '', 'sms_2_name': r.sms_2_name ? r.sms_2_name : '', 
											'sms_3': r.sms_3 ? r.sms_3 : '', 'sms_3_name': r.sms_3_name ? r.sms_3_name : '', 
											'sms_4': r.sms_4 ? r.sms_4 : '', 'sms_4_name': r.sms_4_name ? r.sms_4_name : '', 
											'sms_5': r.sms_5 ? r.sms_5 : '', 'sms_5_name': r.sms_5_name ? r.sms_5_name : '', 
											'sms_6': r.sms_6 ? r.sms_6 : '', 'sms_6_name': r.sms_6_name ? r.sms_6_name : '', 
										}
	}

	testNotification( index, notificationMethod ){
		this.hasError = false;
		this.message = '';

		let msgTo = this.notificationModel[notificationMethod+ '_' + index ]; 
		let name = this.notificationModel[notificationMethod+ '_' + index  + '_name']; 
		let body = {'messageTo': msgTo, 'name': name, 'notificationMethod':notificationMethod};

		this.rulesServices.sendTestNotification(body,this.device_id);		
		this.rulesServices.hasError.subscribe(x=>this.hasError=x);
		this.rulesServices.message.subscribe(x=>this.message=x);
	}

	getUserType(){
		return this.permissions.userType;
	}
}
