@for (device of devices | async; track device) {
  <div id="device-container">
    <div class="row">
      <nav class="navbar navbar-dark bg-dark col-12">
        <li
          class="navbar-brand nav-item text-truncate"
          data-toggle="tooltip"
          data-html="true"
          title="{{ device.location_name }} / {{ device.device_name }}"
          >
          <strong>{{ device.location_name }} / {{ device.device_name }}</strong>
        </li>
      </nav>
    </div>
    <div class="row px-0">
      <div class="col-sm-12 col-md-4 px-1 monitor" [ngClass]="[device.getAlertType()]" id="device-detail">
        <div class="row mx-0">
          @if (message.length > 0) {
            <div class="col-12 px-1 alert" [ngClass]="{ 'alert-danger': hasError, 'alert-success': !hasError }">
              <span style="float: right" (click)="clearMessage()"><i class="fa fa-times-circle-o" aria-hidden="true"></i></span>
              {{ message }}
            </div>
          }
        </div>
        <nav class="navbar col-12 p-0 mx-0 d-block d-md-none card">
          <ul class="nav nav-pills nav-fill" role="tablist">
            <li class="nav-item">
              <a class="nav-link active px-2" data-toggle="tab" href="#overview" role="tab">Overview</a>
            </li>
            <li class="nav-item">
              <a class="nav-link px-2" data-toggle="tab" href="#details" role="tab">Details</a>
            </li>
            <li class="nav-item">
              <a class="nav-link px-2" data-toggle="tab" href="#alarms" role="tab">Alarms</a>
            </li>
            <li class="nav-item">
              <a class="nav-link px-2" data-toggle="tab" href="#history" role="tab">History</a>
            </li>
          </ul>
        </nav>
        <div class="row px-2">
          <div class="tab-content col-12">
            <div class="row p-0 m-0">
              <div id="bottom-nav-sm-" class="col-12 px-1 d-none d-md-block">
                <div class="card">
                  <ul class="nav nav-pills nav-fill" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active px-2" data-toggle="tab" href="#overview" role="tab">Overview</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link px-2" data-toggle="tab" href="#details" role="tab">Details</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link px-2" data-toggle="tab" href="#alarms" role="tab">Alarms</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="tab-pane active p-1 m-1 monitor" [ngClass]="[device.getAlertType()]" id="overview" role="tabpanel">
              <div class="col-12 py-2">
                <div class="row">
                  <div class="col-12">
                    <app-silo-img [device]="device" [showStatus]="true"></app-silo-img>
                  </div>
                </div>
                <div class="row px-2 mt-2">
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn btn-info pt-1 mr-1"
                      [disabled]="measurebtn !== 'Measure' || device.islocked"
                      (click)="takeSmuMeasurement(device.device_id)"
                      >
                      {{ measurebtn }}
                    </button>
                    @if (permissions.canSuspendMeasurement() && device.device_type_code == 'smu344n') {
                      <button
                        type="button"
                        class="btn btn-info pt-1 mr-1"
                        (click)="suspendMeasurement()"
                        >
                        {{ suspend_resume }}
                      </button>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane p-1 m-1 monitor" id="details" role="tabpanel" [ngClass]="[device.getAlertType()]">
              <app-silo-detail [device]="device" class="mt-2 py-2 col-12"></app-silo-detail>
            </div>
            <div class="tab-pane p-1 m-1 monitor" id="alarms" role="tabpanel" [ngClass]="[device.getAlertType()]">
              <app-alarms class="py-1 d-flex flex-row flex-wrap" [device]="device" [displayClass]="'col-12 col-sm-6'"></app-alarms>
            </div>
            <div class="tab-pane p-1 m-1 d-md-none" id="history" role="tabpanel">
              <app-highchart-example class="col-12" [device]="device"></app-highchart-example>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="col-8 d-none d-md-block">
        <app-highchart-example class="col-12" [device]="device" [id]="'large'"></app-highchart-example>
        <!-- <app-line-chart [device]="device"></app-line-chart> -->
      </div>
    </div>
  </div>
}
