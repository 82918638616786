<div click-outside (clickOutside)="onClickOutside($event)">
  <div class="row d-none d-md-block" style="position: fixed; margin-top: -6px; z-index: 10; width: 100% !important">
    <div class="card col-12">
      <div class="row">
        <div class="col-11">
          <!-- <div class="d-flex justify-content-center col-12 p-0 mt-0 mb-2"> -->
          <form name="form" class="card-deck" #f="ngForm" novalidate>
            <div class="form-group mx-0 my-0 p-0 col-12 d-flex justify-content-center">
              <div style="position: relative" class="mr-1">
                <input
                  type="text"
                  name="groupFilter"
                  [(ngModel)]="model.groupFilter"
                  #groupFilter="ngModel"
                  (focus)="showGroups()"
                  class="form-control mr-1"
                  placeholder="Companies"
                  />
                  <div class="filterCheckBox" [hidden]="!showGroupFilter">
                    <div id="locationContainer" class="card w-100 px-2">
                      @for (group of groups | async | FilterPipe: model.groupFilter; track group) {
                        <div>
                          <input
                            type="checkbox"
                            name="grp"
                            class="grp_{{ group.group_id }}"
                            value="{{ group.group_id }}"
                            (click)="addGroup(group.name, group.group_id, $event)"
                            [checked]="isGroupSelected(group.group_id)"
                            />
                            &nbsp;<label for="grp_{{ group.group_id }}" style="margin-bottom: 0">{{ group.name }}</label>
                          </div>
                        }
                      </div>
                    </div>
                  </div>

                  <div style="position: relative" class="mr-1">
                    <input
                      type="text"
                      name="locationFilter"
                      [(ngModel)]="model.locationFilter"
                      #locationFilter="ngModel"
                      (focus)="showLocations()"
                      class="form-control mr-1"
                      placeholder="Locations"
                      />
                      <div class="filterCheckBox" [hidden]="!showLocationFilter">
                        <div id="locationContainer" class="card w-100 px-2">
                          @for (location of filteredLocations | FilterPipe: model.locationFilter; track location) {
                            <div>
                              <input
                                type="checkbox"
                                name="loc"
                                class="loc_{{ location.location_id }}"
                                value="{{ location.location_id }}"
                                (click)="addLocation(location.name, location.location_id, $event)"
                                [checked]="isLocationSelected(location.location_id)"
                                />
                                &nbsp;<label for="loc_{{ location.location_id }}" style="margin-bottom: 0">{{ location.name }}</label>
                              </div>
                            }
                          </div>
                        </div>
                      </div>

                      <div style="position: relative" class="mr-1">
                        <input
                          type="text"
                          name="materialFilter"
                          [(ngModel)]="model.deviceFilter"
                          #deviceFilter="ngModel"
                          (focus)="showDevices()"
                          class="form-control mr-1"
                          placeholder="Silos"
                          />
                          <div class="filterCheckBox" [hidden]="!showDeviceFilter">
                            <div id="locationContainer" class="card w-100 px-2">
                              @for (dlist of listOfdevice | FilterPipe: model.deviceFilter; track dlist) {
                                <div>
                                  <input
                                    type="checkbox"
                                    name="loc"
                                    class="loc_{{ dlist.device_name }}"
                                    value="{{ dlist.device_name }}"
                                    (click)="addDevice(dlist.device_name, dlist.device_id, $event)"
                                    [checked]="isDeviceSelected(dlist.device_id)"
                                    />
                                    &nbsp;<label for="loc_{{ dlist.device_name }}" style="margin-bottom: 0">{{ dlist.device_name }}</label>
                                  </div>
                                }
                              </div>
                            </div>
                          </div>

                          <div style="position: relative" class="mr-1">
                            <select
                              class="form-control ml-auto mr-1"
                              name="hasAlert"
                              [(ngModel)]="model.hasAlert"
                              #locatimaterialonID="ngModel"
                              (focus)="hideFilters()"
                              >
                              <option value="" [selected]="model.hasAlert == ''">All Alarms</option>
                              <option value="1" [selected]="model.hasAlert == 1">Triggered Alarms</option>
                              <option value="0" [selected]="model.hasAlert == 0">Un-Triggered Alarms</option>
                            </select>
                          </div>

                          <div style="position: relative" class="mr-1">
                            <input
                              type="text"
                              class="form-control ml-auto mr-1 px-0"
                              name="searchString"
                              [(ngModel)]="model.searchString"
                              #searchString="ngModel"
                              placeholder="Keyword"
                              (focus)="hideFilters()"
                              />
                            </div>
                            <div style="position: relative" class="mr-1">
                              <div class="btn-group">
                                <button type="button" class="btn btn-primary" name="Search" value="Search" (click)="onSearch(); hideFilters()">
                                  Search
                                </button>
                                @if (permissions.hasManagerOrTechnicianRole()) {
                                  <div class="btn-group">&nbsp;</div>
                                }
                                @if (permissions.hasManagerOrTechnicianRole()) {
                                  <button type="button" class="btn btn-primary" (click)="measureAll()">
                                    Measure All
                                  </button>
                                }
                              </div>
                            </div>
                          </div>
                        </form>
                      <!-- </div> -->
                    </div>
                    <div class="btn-group col-1" role="group">
                      <button class="form-control m-0 pr-0 btn btn-default" routerLink="/devices/list">
                        <i class="fa fa-list-alt" aria-hidden="true"></i>
                      </button>
                      <button class="form-control m-0 pl-1 btn btn-default" routerLink="/devices/card">
                        <i class="fa fa-th" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                  <!-- End  row card hidden-sm-down -->
                </div>
              </div>
              <div class="row d-block d-md-none">
                <div class="card col-12">
                  <div class="row">
                    <div class="col-10">
                      <button class="form-control col-1 m-0 pr-0 btn btn-default" data-target="#sm-filter" (click)="isFilterVisible = !isFilterVisible">
                        <i class="fa fa-filter" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>

                  <div
                    class="show-filter col-12"
                    id="sm-filter"
                    [class.show-filter]="!isFilterVisible"
                    style="position: fixed; z-index: 10; top: 6rem; right: 0"
                    >
                    <div class="card col-12">
                      <div class="form-group mx-0 my-0">
                        <form name="form" #f="ngForm" novalidate>
                          <input
                            type="text"
                            class="form-control col-12 mt-2"
                            name="searchString"
                            [(ngModel)]="model.searchString"
                            #searchString="ngModel"
                            placeholder="Keyword"
                            (focus)="hideFilters()"
                            />
                            <input
                              type="text"
                              name="groupFilter"
                              [(ngModel)]="model.groupFilter"
                              #groupFilter="ngModel"
                              (focus)="showGroups()"
                              class="form-control mt-2"
                              placeholder="Companies"
                              />
                              <div class="filterCheckBox" [hidden]="!showGroupFilter">
                                <div id="locationContainer" class="card px-2">
                                  <div>
                                    Companies
                                    <span style="float: right" (click)="hideFilters()"><i class="fa fa-times-circle-o" aria-hidden="true"></i></span>
                                  </div>
                                  @for (group of groups | async | FilterPipe: model.groupFilter; track group) {
                                    <div>
                                      <input
                                        type="checkbox"
                                        name="grp"
                                        class="grp_{{ group.group_id }}"
                                        value="{{ group.group_id }}"
                                        (click)="addGroup(group.name, group.group_id, $event)"
                                        [checked]="isGroupSelected(group.group_id)"
                                        />
                                        &nbsp;<label for="grp_{{ group.group_id }}" style="margin-bottom: 0">{{ group.name }}</label>
                                      </div>
                                    }
                                  </div>
                                </div>

                                <input
                                  type="text"
                                  name="locationFilter"
                                  [(ngModel)]="model.locationFilter"
                                  #locationFilter="ngModel"
                                  (focus)="showLocations()"
                                  class="form-control mt-2"
                                  placeholder="Locations"
                                  />
                                  <div class="filterCheckBox" [hidden]="!showLocationFilter">
                                    <div id="locationContainer" class="card px-2">
                                      <div>
                                        Locations<span style="float: right" (click)="hideFilters()"
                                        ><i class="fa fa-times-circle-o" aria-hidden="true"></i
                                      ></span>
                                    </div>
                                    @for (location of filteredLocations | FilterPipe: model.locationFilter; track location) {
                                      <div>
                                        <input
                                          type="checkbox"
                                          name="loc"
                                          class="loc_{{ location.location_id }}"
                                          value="{{ location.location_id }}"
                                          (click)="addLocation(location.name, location.location_id, $event)"
                                          [checked]="isLocationSelected(location.location_id)"
                                          />
                                          &nbsp;<label for="loc_{{ location.location_id }}" style="margin-bottom: 0">{{ location.name }}</label>
                                        </div>
                                      }
                                    </div>
                                  </div>

                                  <input
                                    type="text"
                                    name="materialFilter"
                                    [(ngModel)]="model.deviceFilter"
                                    #deviceFilter="ngModel"
                                    (focus)="showDevices()"
                                    class="form-control mr-1"
                                    placeholder="Silos"
                                    />
                                    <div class="filterCheckBox" [hidden]="!showDeviceFilter">
                                      <div id="locationContainer" class="card px-2">
                                        <div>
                                          Silos<span style="float: right" (click)="hideFilters()"><i class="fa fa-times-circle-o" aria-hidden="true"></i></span>
                                        </div>
                                        @for (dlist of listOfdevice | FilterPipe: model.deviceFilter; track dlist) {
                                          <div>
                                            <input
                                              type="checkbox"
                                              name="device"
                                              class="loc_{{ dlist.device_name }}"
                                              value="{{ dlist.device_name }}"
                                              (click)="addDevice(dlist.device_name, dlist.device_id, $event)"
                                              [checked]="isDeviceSelected(dlist.device_id)"
                                              />
                                              &nbsp;<label for="loc_{{ dlist.device_name }}" style="margin-bottom: 0">{{ dlist.device_name }}</label>
                                            </div>
                                          }
                                        </div>
                                      </div>

                                      <div class="ml-0 mr-1 pt-1 px-0 mr-1">
                                        <input
                                          type="checkbox"
                                          name="hasAlert"
                                          [(ngModel)]="model.hasAlert"
                                          #hasAlert="ngModel"
                                          [checked]="model.hasAlert == 1"
                                          value="1"
                                          (click)="hideFilters()"
                                          />
                                          Alarm Triggered
                                        </div>

                                        <!---
                                        <input type="submit" class="btn btn-primary mt-2" name="Search" value="Apply" (click)="hideFilters()" />
                                        <input
                                          type="button"
                                          class="btn btn-primary mt-2"
                                          name="Cancel"
                                          value="Close"
                                          (click)="isFilterVisible = false; hideFilters()"
                                          /> --->

                                          <div class="btn-group">
                                            <button type="button" class="btn btn-primary" name="Search" (click)="onSearch(); hideFilters()">Apply</button>
                                            <button type="button" class="btn btn-primary" name="Cancel" (click)="isFilterVisible = false; hideFilters()">Cancel</button>
                                            @if (permissions.hasManagerOrTechnicianRole()) {
                                              <button type="button" class="btn btn-primary" (click)="measureAll(); hideFilters()">
                                                Measure All
                                              </button>
                                            }
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- End row  hidden-md-up -->
                            </div>
                            <div class="row">
                              <div class="d-none d-md-block col-12" style="margin-top: 2rem">
                                <div class="row show-list mx-0" [class.show-list]="isClassVisible">
                                  @for (device of devices | async; track device) {
                                    <app-silo-summary
                                      class="col-12 col-sm-6 col-md-4 col-xl-3 px-1 py-1"
                                      [device]="device"
                                      (click)="showDeviceDetail(device.device_id)"
                                    ></app-silo-summary>
                                  }
                                </div>

                                <div class="row mx-0" [class.show-list]="!isClassVisible" [hidden]="isLoading">
                                  <app-table-view class="col-12 px-1" [devices]="devices" [model]="model"></app-table-view>
                                </div>
                                @if (isLoading) {
                                  <div>
                                    <app-loading-spinner class="col-12"></app-loading-spinner>
                                  </div>
                                }
                              </div>
                            </div>
                            <div class="d-block d-md-none">
                              <div class="row">
                                @for (device of devices | async; track device) {
                                  <app-silo-summary
                                    class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-1 py-1"
                                    [device]="device"
                                    (click)="showDeviceDetail(device.device_id)"
                                  ></app-silo-summary>
                                }
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-2">
                                <select
                                  class="form-control ml-auto col-12 mt-2"
                                  name="recordsPerPage"
                                  [(ngModel)]="model.recordsPerPage"
                                  #recordsPerPage="ngModel"
                                  (ngModelChange)="onSearch()"
                                  >
                                  <option [value]="10" [selected]="10 === model.recordsPerPage">10 records per page</option>
                                  <option [value]="20" [selected]="20 === model.recordsPerPage">20 records per page</option>
                                  <option [value]="30" [selected]="30 === model.recordsPerPage">30 records per page</option>
                                  <option [value]="40" [selected]="40 === model.recordsPerPage">40 records per page</option>
                                  <option [value]="50" [selected]="50 === model.recordsPerPage">50 records per page</option>
                                </select>
                              </div>
                              @if (totalPages > 1) {
                                <nav aria-label="Page navigation col-10" class="mt-2">
                                  <ul class="pagination">
                                    @if (model.page == 1) {
                                      <li class="page-item">
                                        <a class="page-link btn disabled" aria-label="Previous">
                                          <span aria-hidden="true">&laquo;</span>
                                          <span class="sr-only">Previous</span>
                                        </a>
                                      </li>
                                    }
                                    @if (model.page > 1) {
                                      <li class="page-item">
                                        <a class="page-link" aria-label="Previous" (click)="goToPage(model.page - 1)">
                                          <span aria-hidden="true">&laquo;</span>
                                          <span class="sr-only">Previous</span>
                                        </a>
                                      </li>
                                    }
                                    @for (d of arr(totalPages).fill(1); track d; let i = $index) {
                                      <li class="page-item" [class.active]="i + 1 == model.page">
                                        <a class="page-link" (click)="goToPage(i + 1)">{{ i + 1 }}</a>
                                      </li>
                                    }
                                    <li class="page-item">
                                      @if (model.page < totalPages) {
                                        <a class="page-link" aria-label="Next" (click)="goToPage(model.page + 1)">
                                          <span aria-hidden="true">&raquo;</span>
                                          <span class="sr-only">Next</span>
                                        </a>
                                      }
                                    </li>
                                  </ul>
                                </nav>
                              }
                            </div>
