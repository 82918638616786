// Section 1
import { State, Action, StateContext, Selector, Select } from "@ngxs/store";
import { Location } from "../../model/location";
import {
  LocationChecked,
  FetchLocations,
  LocationGroupIDs,
  LocationSort,
  LocationFilter,
} from "../actions/locations.action";

import { LocationsService } from "../../services/locations.service";
import { SearchAndSortArray } from "../../model/searchAndSortArray";
import { Injectable } from "@angular/core";

// Section 2
export class LocationStateModel {
  locations: Location[];
  selectedLocationIDs: number[];
  groupIDs: number[];
  sortBy: string;
  direction: number;
  searchString: string;
  filterByList: string[];
  exactMatch: boolean;
}

@State<LocationStateModel>({
  name: "locations",
  defaults: {
    locations: [],
    selectedLocationIDs: [],
    groupIDs: [],
    sortBy: "name",
    direction: 1,
    searchString: "",
    filterByList: ["group_name", "name", "city", "state"],
    exactMatch: false,
  },
})
@Injectable()
export class LocationState {
  constructor(private service: LocationsService) {}

  @Selector()
  static getLocations(state: LocationStateModel) {
    let loc;
    if (state.groupIDs.length === 0) {
      loc = state.locations;
    } else {
      loc = state.locations.filter(
        (a) => state.groupIDs.indexOf(a.group_id) > -1
      );
    }
    let time = new Date();
    loc.map((a) => {
      a.selected = false;
      a.selected = state.selectedLocationIDs.includes(a.location_id);
    });

    let obj = new SearchAndSortArray(
      loc,
      state.filterByList,
      state.searchString,
      state.exactMatch,
      state.sortBy,
      state.direction
    );

    loc = obj.search();
    loc = obj.sort();
    /*
     */

    return loc;
  }

  @Action(FetchLocations)
  getLocations({ getState, setState }: StateContext<LocationStateModel>) {
    const state = getState();
    let posts: Location[] = [];

    this.service.getAllLocations().subscribe((x) => {
      setState({ ...state, locations: x });
    });
  }

  @Action(LocationChecked)
  check(
    { getState, patchState }: StateContext<LocationStateModel>,
    { payload }: LocationChecked
  ) {
    let st = getState().selectedLocationIDs;
    let idx = st.indexOf(payload);

    if (idx === -1) {
      st.push(payload);
    } else {
      st.splice(idx, 1);
    }
    patchState({ selectedLocationIDs: st });
  }
  @Selector()
  static getSelectedLocationIDs(state: LocationStateModel) {
    return state.selectedLocationIDs;
  }

  @Action(LocationGroupIDs)
  selectedGroups(
    { getState, patchState }: StateContext<LocationStateModel>,
    { payload }: LocationGroupIDs
  ) {
    let state = getState();

    payload.subscribe((p) => {
      let locIds = state.locations
        .filter(
          (l) =>
            p.includes(l.group_id) &&
            state.selectedLocationIDs.includes(l.location_id)
        )
        .map((ll) => ll.location_id);
      patchState({
        selectedLocationIDs: locIds,
        groupIDs: p,
      });
    });
  }

  @Action(LocationSort)
  locationSort(
    { getState, patchState }: StateContext<LocationStateModel>,
    { payload }: LocationSort
  ) {
    let state = getState();
    let sortBy = state.sortBy;
    let direction = state.direction;

    if (sortBy === payload) {
      direction = direction * -1;
    } else {
      direction = 1;
    }

    patchState({ sortBy: payload, direction });
  }

  @Selector()
  static getSortField(state: LocationStateModel) {
    return state.sortBy;
  }

  @Selector()
  static getSortDirection(state: LocationStateModel) {
    return state.direction === 1 ? "sort-asc" : "sort-desc";
  }

  @Action(LocationFilter)
  LocationFilter(
    { getState, patchState }: StateContext<LocationStateModel>,
    { payload }: LocationFilter
  ) {
    let state = getState();
    let filterByList = payload.filterByList.split(",");
    let exactMatch = payload.exactMatch;
    let searchString = payload.searchString;
    patchState({ filterByList, searchString, exactMatch });
  }
}
