
<table class="table table-striped">
  <thead class="thead-dark">
    <th>
      Description
    </th>
    <th>
      Data
    </th>
  </thead>
  <tbody>
    <tr>
      <td>
        <strong>Silo Content:</strong>
      </td>
      <td>
        {{ device.material }}
      </td>
    </tr>
    <tr>
      <td>
        <strong>Last Measurement:</strong>
      </td>
      <td>
        {{ device.timestamp | date: 'shortTime': '' + device.timezone}}, {{ device.timestamp | date: 'shortDate'}}
      </td>
    </tr>
    <tr>
      <td>
        <strong>Shape:</strong>
      </td>
      <td>
        {{ device.shape_label }}
      </td>
    </tr>
    <tr>
      <td>
        <strong>Height:</strong>
      </td>
      <td>
        {{ device.height | number:'1.1-2' }} {{ device.linear_label }}
      </td>
    </tr>
    <tr>
      <td>
        <strong>Distance:</strong>
      </td>
      <td>
        {{ device.distance | number:'1.1-2' }} {{ device.linear_label }}
      </td>
    </tr>
    <tr>
      <td>
        <strong>Level:</strong>
      </td>
      <td>
        {{ device.level | number:'1.1-2' }} {{ device.linear_label }}
      </td>
    </tr>
    <tr>
      <td>
        <strong>Volume:</strong>
      </td>
      <td>
        {{ device.volume | number:'1.1-2' }} {{ device.volume_label }}
      </td>
    </tr>
    <tr>
      <td>
        <strong>Volume Ullage:</strong>
      </td>
      <td>
        {{ device.volume_ullage | number : '1.2-2'}} {{ device.volume_label }}
      </td>
    </tr>
    <tr>
      <td>
        <strong>Weight:</strong>
      </td>
      <td>
        {{ device.weight | number:'1.1-2' }} {{ device.weight_label }}
      </td>
    </tr>
    <tr>
      <td>
        <strong>Weight Ullage:</strong>
      </td>
      <td>
        {{ device.weight_ullage | number:'1.1-2' }} {{ device.weight_label }}
      </td>
    </tr>
    <tr>
      <td>
        <strong>Percent:</strong>
      </td>
      <td>
        {{ device.percent | number:'1.1-2' }}%
      </td>
    </tr>
    <tr>
      <td>
        <strong>Percent Ullage:</strong>
      </td>
      <td>
        {{ device.percent_ullage | number:'1.1-2' }}%
      </td>
    </tr>
    <tr>
      <td>
        <strong>Sensor Type:</strong>
      </td>
      <td  style="text-transform: capitalize;">
        {{ device.device_type }}
      </td>
    </tr>
    <tr>
      <td>
        <strong>Gateway:</strong>
      </td>
      <td  style="text-transform: capitalize;">
        {{ device.gateway_name }}
      </td>
    </tr>
    <tr>
      <td>
        <strong>Address / Node:</strong>
      </td>
      <td  style="text-transform: capitalize;">
        {{ device.device_dia_name }}
      </td>
    </tr>
    <tr>
      <td>
        <strong>Bulk Density:</strong>
      </td>
      <td>
        {{ device.density }} {{ device.density_label }}
      </td>
    </tr>
    <tr>
      <td>
        <strong>Display Default:</strong>
      </td>
      <td>
        {{ device.default_display_label }}
      </td>
    </tr>
    <tr>
      <td>
        <strong>Dead Zone:</strong>
      </td>
      <td>
        {{ device.dead_zone || 0 }}%
      </td>
    </tr>
    @if (device.viewSignal()) {
      <tr>
        <td>
          <strong>Signal Strength:</strong>
        </td>
        <td>
          {{ device.signal_strength }} db
        </td>
      </tr>
    }
  </tbody>
</table>
