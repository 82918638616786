import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { Observable, Subject } from 'rxjs';

import * as $ from 'jquery';
// import { JwtHelper } from 'angular2-jwt';
import { JwtHelperService } from '@auth0/angular-jwt';

import { Permissions } from '../model/permissions';

export interface Credentials {
	username: string;
	password: string;
}

@Component({
	selector: 'login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
	model: any = {
		username: '',
		password: '',
		rememberme: false,
	};
	loading: boolean = false;
	error: string = '';
	logingPage: boolean = true;
	permission: Permissions = new Permissions();

	public credentials: Credentials;
	jwtHelper: JwtHelperService = new JwtHelperService();

	constructor(private auth: AuthService, private router: Router) {
		auth.error.subscribe((x) => (this.error = x));
	}
	onLoging() {
		this.loading = true;
		this.error = '';
		if (this.model.rememberme) {
			localStorage.setItem('uName', this.model.username);
		} else {
			localStorage.removeItem('uName');
		}
		this.auth.login(this.model);
		this.loading = !this.auth.loggedIn();

		if (this.auth.loggedIn()) {
			$('body').removeClass('bg-img');
		}
		this.auth.error.subscribe((x) => {
			this.error = x;
			this.loading = false;
		});
	}

	ngOnInit() {
		this.model.rememberme = localStorage.getItem('uName') ? true : false;

		this.model.username = localStorage.getItem('uName') || '';
		if (this.auth.loggedIn()) {
			$('body').removeClass('bg-img');
			let default_view = localStorage.getItem('default_view') || 'list';
			this.router.navigateByUrl(`/devices/${default_view}`);
		} else {
			$('body').addClass('bg-img');
		}
	}
}

/*
`
    <form #f="ngForm" (ngSubmit)="onLogin(f.value)" *ngIf="!auth.loggedIn() || auth.loggedIn()">
      <input type="text" placeholder="username" ngControl="username">
      <input type="password" placeholder="password" ngControl="password">
      <button type="submit">Submit</button>
    </form>
  `
    // let token = localStorage.getItem('id_token');
    // console.log(
    //               this.jwtHelper.decodeToken(token),
    //               this.jwtHelper.getTokenExpirationDate(token),
    //               this.jwtHelper.isTokenExpired(token)
    //           );
  }

  // onLogin(credentials:Credentials) {
  //   this.auth.login(credentials);
  // }
*/
