import { Group } from '../../model/group';

export class FetchGroups {
    static readonly type = '[GROUP] Fetch Post';
}


export class Checked {
    static readonly type = '[GROUP] Checked'

    constructor(public payload: number) {}
}

export class GroupFilter {
    static readonly type = '[GROUP] Groups Filter'

    constructor(public payload: {filterByList:string,searchString:string, exactMatch: boolean}) {}
}

export class GroupSort {
    static readonly type = '[GROUP] Group Sort'

    constructor(public payload: string) {}
}
