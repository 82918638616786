<div class="row">
  <div class="col-12">
    <h3>Edit Gateway</h3>
  </div>
  @if (message && message.length > 0) {
    <div class="col-12 alert text-center" [ngClass]="hasError? 'alert-danger' : 'alert-success' " >
      {{message}}
    </div>
  }
</div>
<div class="btn-group m-1">
  <button routerLink="/administration/codes" class="btn btn-primary" ><i class="fa fa-arrow-left"></i>Codes</button>
</div>

<form [formGroup]="codeForm" (ngSubmit)="codeForm.valid && submitCode()" autocomplete="false" >

  <div class="form-group row mb-1">
    <label for="type" class="col-2 col-form-label p-1"><span class="required">*</span>Type</label>
    <div class="col-10">
      <input type="text" class="form-control p-1" id="type" name="type" [formControl]="type" >
      <div [hidden]="type.valid || type.untouched" class="alert alert-danger p-1">
        Type is required
      </div>
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="code" class="col-2 col-form-label p-1"><span class="required">*</span>Code</label>
    <div class="col-10">
      <input type="text" class="form-control p-1" id="code" name="code" [formControl]="code" >
      <div [hidden]="code.valid || code.untouched" class="alert alert-danger p-1">
        Code is required
      </div>
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="decode" class="col-2 col-form-label p-1"><span class="required">*</span>Decode</label>
    <div class="col-10">
      <input type="text" class="form-control p-1" id="decode" name="decode" [formControl]="decode" >
      <div [hidden]="decode.valid || decode.untouched" class="alert alert-danger p-1">
        Decode is required
      </div>
    </div>
  </div>


  <div class="form-group row mb-1">
    <label for="sort_num" class="col-2 col-form-label p-1"><span class="required">*</span>Sort Number</label>
    <div class="col-10">
      <input type="text" class="form-control p-1" id="sort_num" name="sort_num" [formControl]="sort_num" >
      <div [hidden]="sort_num.valid || sort_num.untouched" class="alert alert-danger p-1">
        Sort number is required
      </div>
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="longitude" class="col-2 col-form-label p-1">&nbsp;</label>
    <div class="col-10">
      <button class="form-controlf p-1 btn btn-success" [disabled]="!codeForm.valid">Update Code</button>
      <!-- <button class="form-controlz p-1 btn btn-danger" data-toggle="modal" data-target="#delGateway" (click)="fakeDelete()">Delete</button> -->
      <button class="form-controlz p-1 btn btn-default" routerLink="/administration/codes" >Cancel</button>
    </div>
  </div>

</form>