// tslint:disable: indent
import * as lkeys from 'lowercase-keys';
interface userJSON {
	user_id: number;
	addr1: string;
	addr2: string;
	city: string;
	company: string;
	country: string;
	email: string;
	fax: string;
	hint: string;
	name: string;
	password: string;
	phone: string;
	rownum: string;
	state: string;
	status_cde: string;
	user_name: string;
	zip: string;
	user_type: string;
	user_type_desc: string;
	group_id: string;
	total_rows: number;
	country_id: number;
	state_id: number;
	two_factor_auth_type: string;
	two_factor_auth_code: string;
	two_factor_auth_id: string;
	default_view: string;
}
export class User {
	static fromJSON(jsonObj): User {
		let user = Object.create(User.prototype);
		jsonObj.IS_2FA_REQUIRED = jsonObj.IS_2FA_REQUIRED && !isNaN(jsonObj.IS_2FA_REQUIRED) ? jsonObj.IS_2FA_REQUIRED : 0;
		return Object.assign(user, lkeys(jsonObj));
	}

	constructor(
		public user_id: number,
		public addr1: string,
		public addr2: string,
		public city: string,
		public company: string,
		public country: string,
		public email: string,
		public fax: string,
		public hint: string,
		public name: string,
		public password: string,
		public phone: string,
		public state: string,
		public status_cde: string,
		public user_name: string,
		public zip: string,
		public user_type: string,
		public user_type_desc: string,
		public group_id: number,
		public total_rows: number,
		public country_id: number,
		public state_id: number,
		public two_factor_auth_type: string,
		public two_factor_auth_code: string,
		public two_factor_auth_id: string,
		public default_view: string,
		public is_2fa_required: number
	) {}
}
