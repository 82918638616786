import { Component, Directive, OnInit, Input, OnDestroy, ViewChild, ViewChildren, QueryList, ElementRef } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';
import * as lkeys from 'lowercase-keys';

import { DevicesService } from '../../services/devices.service';
import { TableViewSummaryComponent } from '../table-view-summary/table-view-summary.component';
import { Device } from '../../model/device';
import { DeviceListComponent } from '../device-list.component';
import { fromEvent } from 'rxjs';


@Component({
	selector: 'app-table-view',
	templateUrl: './table-view.component.html',
	styleUrls: ['./table-view.component.css'],
})
export class TableViewComponent implements OnInit, OnDestroy {
	@ViewChildren(TableViewSummaryComponent) tableViewSummaryComponents: QueryList<TableViewSummaryComponent>;

	@Input() devices: Observable<Device[]>;
	@Input() model?: any;
	parentdev: DeviceListComponent;
	open: any = [];
	show: string = 'show';
	device$: any;
	interval: any;
	statusTime$: any;
	time = new Date().getTime();

	@ViewChild('tref', { read: ElementRef }) tref: ElementRef;
	@ViewChild('tref2', { read: ElementRef }) tref2: ElementRef;

	trefPosition: boolean = false;
	trefPositionWidth = '100%';

	constructor(private devicesService: DevicesService) {}

	ngOnInit() {
		// this.getDeviceTimeStatus();
		this.getCurrentOffsetTop(this.tref, this.tref2);
		let eventSubscription = fromEvent(window, 'scroll').subscribe((e) => {
			this.onWindowScroll(e);
		});

		let eventResize$ = fromEvent(window, 'resize').subscribe((e) => {
			this.onWindowScroll(e);
		});
	}

	sortBy(sortCol) {
		this.model.orderBy = sortCol;

		if (this.model.orderDirection === 'ASC') {
			this.model.orderDirection = 'DESC';
		} else {
			this.model.orderDirection = 'ASC';
		}

		this.model.page = 1;
		this.devicesService.getFilteredDevices(this.model);
	}

	toggleCollapse(e, did): void {
		let index = this.open.indexOf(did.device_id);
		did.open = !did.open;

		if (!did.open) {
			this.open.splice(index, 1);
		} else {
			this.open.push(did.device_id);
		}
	}

	isOpen(did): boolean {
		let index = this.open.indexOf(did);
		let _open = false;
		if (index > -1) {
			_open = true;
		}
		return _open;
	}

	toggleCollapseAll(e) {
		if (this.show == 'show') {
			this.open.splice(0);

			this.devices.subscribe((x) => {
				x.map((a) => this.open.push(a.device_id));
			});

			this.show = '';
			e.target.innerHTML = 'Collapse All';
		} else {
			this.open.splice(0);
			this.show = 'show';
			e.target.innerHTML = 'Expand All';
		}
	}

	updateDevice(device: Device) {
		this.devices.subscribe((dd) => {
			dd.filter((x) => x.device_id === device.device_id).map((a) => {
				a = Object.assign(a, device);
			});
		});
	}

	onWindowScroll($event) {
		let t = this.getCurrentOffsetTop(this.tref, this.tref2);
	}

	getDeviceTimeStatus() {
		let t = true;
		this.interval = setInterval(() => {
			this.device$ = this.devices.subscribe((dd) => {
				let ids = [];
				/*const obj =*/ dd.map((d) => {
					ids.push(d.device_id);
					let index = this.open.indexOf(d.device_id);
					if (index > -1) {
						d.open = true;
					} else {
						d.open = false;
					}

					let temp = { open: d.open, device_id: d.device_id };
					// return ids;
				});
				if (ids.length > 0 && t == true) {
					t = false;
					this.statusTime$ = this.devicesService.getDeviceListStatusTime(ids.toString());
				}
			});
		}, 15000);
	}

	ngOnDestroy() {
		if (this.devicesService.interval) {
			this.devicesService.interval.unsubscribe();
		}
		this.devices = undefined;
		if (this.device$) {
			// this.devicesService.getFilteredDevices( {group_id: -1} );
			this.device$.unsubscribe();
		}
		if (this.interval) {
			clearInterval(this.interval);
		}
		if (this.statusTime$) {
			this.statusTime$.unsubscribe();
		}
	}

	getCurrentOffsetTop(element1: ElementRef, element2: ElementRef) {
		// return element.nativeElement.offsetTop;
		const rect = element1?.nativeElement.getBoundingClientRect();
		const rect2 = element2?.nativeElement.getBoundingClientRect();
		let r = rect?.top + window.pageYOffset - document.documentElement.clientTop;
		// console.log('rect...',r, rect.top,'rect 2', rect2.width )
		// return rect.top;
		this.trefPositionWidth = rect2?.width + 'px'; // '100% !important';//
		this.trefPosition = r - rect?.top > 0;
	}

	refreshAll(results) {
		this.tableViewSummaryComponents.map((summary) => {
			results
					.filter((result) => {
						result = lkeys(result);
						return summary.device.gateway_id === result.gateway_id && summary.device.type === 'smu344n' && summary.device.islocked !== 1;
					})
					.map((result) => {
					summary.measurebtn = 'Measuring...';
					result = lkeys(result);
					if (result.status !== 'success') {
						summary.measurebtn = 'Measure';
						summary.hasError = true;
						//TO DO: update with message from the server ...result.MESSAGE;
						summary.message = 'Unable to take measurement, please make sure the device is connected.';
					} else {
						summary.refreshData();
					}
				});
		});
	}
}
