import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import {Router, ActivatedRoute, Params}  from '@angular/router';

import { PagerComponent } from '../../pager/pager.component';

import { Device } from '../../model/device';
import { Permissions } from '../../model/permissions';


import { Store, Select } from '@ngxs/store';
import { DeviceState } from '../../store/states/devices.state';
import { FetchLatestDevices,FetchAdminDevices, FilterDeviceGroups, FilterDeviceLocations, DeviceChecked, AlarmFilter, DeviceSort, DeviceFilter } from '../../store/actions/devices.action';


@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.css']
})
export class DevicesComponent implements OnInit {
@ViewChild(PagerComponent) pager: PagerComponent;
  @Select(DeviceState.getAllDevices) devices: Observable<Device[]>
  @Select(DeviceState.getDevices) deviceFilters: Observable<Device[]>
  @Select(DeviceState.getSortDirection) sortDirection: Observable<string>
  @Select(DeviceState.getSortField) sortField: Observable<string>
  @Select(DeviceState.getSearchString) searchString: Observable<string>
	permissions:Permissions = new Permissions();
	// devices:Observable<Device[]>;
	shapes:any[] = [];

  	public rowItems:Device[] = [];

	codes = JSON.parse(localStorage.getItem("codes")) ;

	constructor( private router:Router, private store: Store) {
		for(let x of this.codes){
				if( x.TYPE.toLowerCase() === 'shapes'){
					this.shapes.push(x);
				}
			}
	}

	ngOnInit() {	this.searchString.subscribe(x=>console.log('search',x))	
		if( !this.permissions.hasSilosPermission() ){
			this.router.navigateByUrl('devices');
		}

    	this.store.dispatch(new FetchAdminDevices());
	}

	getShapeType(code){
		let decode = '';
		let shape = this.shapes.filter(x=>x.CODE == code);
		if(shape.length == 1){
			decode = shape[0].DECODE;
		}
		return decode;
	}

  setPagedItem(rows) {
    this.rowItems = rows;
  }

  sort(field){
    this.store.dispatch(new DeviceSort(field));
    this.pager.setPage(1)
  }


	filter(field,str, exactMatch){
		this.store.dispatch(new DeviceFilter({filterByList:field,searchString:str, exactMatch}));
	}

	removeSpace(str){
		if(!str) return '';
		return str.replace(/ /g,'');
	}

}
