import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormControl, ReactiveFormsModule, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { AuthService } from '../auth.service';
import { Permissions } from '../model/permissions';
declare var $: any;

@Component({
	selector: 'app-verify',
	templateUrl: './verify.component.html',
	styleUrls: ['./verify.component.scss'],
})
export class VerifyComponent implements OnInit {
	hasError = false;
	message = '';

	id = new UntypedFormControl('', [Validators.required]);
	code = new UntypedFormControl('', [Validators.required]);
	permission: Permissions = new Permissions();

	verifyForm: UntypedFormGroup = this.builder.group({
		id: this.id,
		code: this.code,
	});

	constructor(private auth: AuthService, private builder: UntypedFormBuilder, private router: Router) {}

	ngOnInit() {
		$('body').addClass('bg-img');
		let id = localStorage.getItem('verify');
		if (typeof id === 'undefined' || !id || id.length === 0) {
			this.hasError = true;
			this.message = 'No verification code found.';
		} else {
			this.id.patchValue(id);
		}
	}

	verify() {
		this.hasError = false;
		this.message = '';
		this.auth
			.verify(this.verifyForm.value)
			.pipe(map((res: any) => res.data))
			.subscribe(
				(data) => {
					const id_token = data.id_token;
					localStorage.setItem('id_token', id_token);
					this.auth.updateIsloggedIn();
					this.auth.setDefaultView();
					if (this.auth.loggedIn()) {
						localStorage.removeItem('verify');
						let default_view = localStorage.getItem('default_view') || 'list';
						this.router.navigateByUrl(`/devices/${default_view}`);
					}
				},
				(err) => {
					this.hasError = true;
					this.message = 'Please check your verication code and try again.';
					console.log('ERROR', err);
				}
			);
	}

	requestAgain() {
		this.hasError = false;
		this.message = '';
		this.auth
			.requestAgain(this.verifyForm.value)
			.pipe(map((res: any) => {
				console.log("requestAgain res", res);
				return res.data;
			}))
			.subscribe(
				(data) => {
					console.log("request again ", data);
					this.hasError = false;
					this.message = 'A new verification code has been sent.';
					localStorage.setItem('verify', data.verify);
					this.id.patchValue(localStorage.getItem('verify'));
				},
				(err) => {
					this.hasError = true;
					this.message = 'Unable to find your verification code, please login again.';
					console.log('ERROR', err);
				}
			);
	}
}
