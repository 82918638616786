interface strappingJSON{
	strapping:				number; 
	distance:				number; 
	device_id:				number;
}
export class Strapping {
	static fromJSON( jsonObj ): Strapping{
		let distance = Object.create( Strapping.prototype );
		return Object.assign( distance, jsonObj,{	
													strapping: 	jsonObj.strapping,
													distance: 	jsonObj.distance,
													device_id: 	jsonObj.device_id
												});
	}
	
	constructor(
		public strapping?: number,
		public distance?:number, 
		public device_id?:number){ 
    }
}
