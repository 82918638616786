<div class="row">
  <div class="col-12">
    <h3>Silos</h3>
  </div>
  <div class="col-12 mb-1">
    <div class="row">
      <app-admin-nav></app-admin-nav>
      @if (permissions.hasAddSilosPermission()) {
        <button routerLink="/administration/silo/0/edit/0" class="btn btn-primary py-0 mr-2">
          <i class="fa fa-plus"></i>Add Silo
        </button>
      }
      <input type="text" name="search" [value]="searchString | async" class="col-12 col-sm-6 col-md-3 form-control" placeholder="keyword" (keyup)="filter('company_name,location_name,device_name,gateway_name,device_dia_name',$event.target.value, false)">
    </div>
  </div>
  <div class="col-12">
    <table class="table table-striped tableList">
      <thead class="table-dark">
        <tr>
          <th (click)="sort('company_name')">
            <a [className]="(sortField | async) == 'company_name'? (sortDirection | async) : 'sort'">Company</a>
          </th>
          <th (click)="sort('location_name')">
            <a [className]="(sortField | async) == 'location_name'? (sortDirection | async) : 'sort'">Location</a>
          </th>
          <th (click)="sort('device_name')">
            <a [className]="(sortField | async) == 'device_name'? (sortDirection | async) : 'sort'">Silo Name</a>
          </th>
          <th>
            Gateway Name
          </th>
          <th>
            Sensor Address
          </th>
          <th>
            Status
          </th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        @for (device of rowItems; track device) {
          <tr >
            <td>{{ device.company_name }}</td>
            <td>{{ device.location_name }}</td>
            <td>{{ device.device_name }}</td>
            <td>{{ device.gateway_name }}</td>
            <td>{{ removeSpace(device.device_type) + ':' + device.sensor_address }}</td>
            <td>{{ device.status_cde.toLowerCase() == 'a'?'Active':'Inactive' }}</td>
            <td nowrap align="right">
              <div class="" role="group" aria-label="buttons">
                <button class="btn btn-info p-1" routerLink="/administration/silo/{{device.device_id}}/edit/{{device.device_id}}"><i class="fa pointer fa-pencil"></i> Edit</button>
              </div>
            </td>
          </tr>
        }
      </tbody>
    </table>


    <div class="row">
      <div class="col-12">
        <app-pager [allItems]="devices | async" (pagedItemsNew)="setPagedItem($event)"></app-pager>
      </div>
    </div>
  </div>
</div>
