import { Injectable } 							from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';


import { environment} from '../../environments/environment';

import { Channel } from '../model/channels';
import { UtilService } from './util.service';
@Injectable()
export class ChannelsService {

 	public hasError:Subject<boolean> 	= new Subject<boolean>();
	public message:Subject<string> 		= new Subject<string>();
	private channelUrl = environment.apiurl + '/location';

	private _channels: BehaviorSubject<Channel[]> = new BehaviorSubject<Channel[]>( [] );
	public channels: Observable<Channel[]> 		= this._channels.asObservable();
	
	public files: Subject<any[]> 	= new Subject<any[]>( );
	public report_id: Subject<any> 	= new Subject<any>( );

	constructor (private util:UtilService, private router: Router) {}

	public getChannels(cId?:number): void {
		this.util._getData( `${this.channelUrl}/getChannels` + cId, {channel_id: cId} )
			.pipe(map(ret=>ret.data))
			.subscribe( cJSON =>{
				const gateways = cJSON
									.map( jsonobj => Channel.fromJSON( jsonobj ));
				this._channels.next( gateways );
			})
	}

	public getReportCriteria(): Observable<any> {
		return this.util._requestData( `${this.channelUrl}/getReport` )
			.pipe(map(ret=>ret.data))
			// .map(ret=>{return ret;})
			
	}

	public generateReport(body): void {
		// let url = this.channelUrl + 'method=generateReport';
		let url = `${this.channelUrl}/generateReport`;
		this.util._postData( url, body )
			.pipe(map(ret=>ret.data))
			.subscribe( 
							res=>{

									this.hasError.next(false); 
									let msg = 'Reports were generated successfully.';
									this.files.next(res.files);
									this.message.next(msg);
									// console.log(msg);
								},
							(err)=>{
									console.log('Error generating report'); 
									this.hasError.next(true); 
									this.message.next(err.message)
								},
							()=>{
									console.log('reporting completed');
									// this.hasError.next(false); 
									// this.message.next('Reports were generated, please click the links below.');
								}
						)
	}

	public saveReport(body): void {
		// let url = this.channelUrl + 'method=saveReport';
		let url = `${this.channelUrl}/saveReport`;
		this.util._postData( url,body )
			.pipe(map(ret=>ret.data))
			.subscribe( 
							res=>{

									this.hasError.next(false); 
									let msg = 'Report was saved successfully.';
									this.files.next([]);
									this.message.next(msg);
									this.report_id.next(res.id);
								},
							(err)=>{
									console.log('Error saving report', err.error.message);
									this.hasError.next(true); 
									this.message.next(err.error.message);
									this.report_id.next('')
								},
							()=>{
									console.log('saving report completed');
									// this.hasError.next(false); 
									// this.message.next('Reports were generated, please click the links below.');
								}
						);
	}

	public deleteReport(body){
		let url = `${this.channelUrl}/deleteReport`;
		this.util._postData( url,body )
			.pipe(map(ret=>ret.data))
			.subscribe( 
							res=>{

									this.hasError.next(false); 
									let msg = 'Report was deleted successfully.';
									this.files.next([]);
									this.message.next(msg);
									this.report_id.next('');
								},
							(err)=>{
									console.log('Error deleting report'); 
									this.hasError.next(true); 
									this.message.next(err.message);
									this.report_id.next('')
								},
							()=>{
									console.log('deleting report completed');
									// this.hasError.next(false); 
									// this.message.next('Reports were generated, please click the links below.');
								}
						);
	}

}
