export const environment = {
  production: true,
  envName: 'production',
  // apiurl:'',
  apiurl:'/api',
  url :{
  			dev: 'http://monitortech.dev',
  			test: 'https://tst-monitortech.idigiapps.com',
  			prod: 'https://www.mysilotrackcloud.com'
  		}
};
