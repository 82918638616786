// Section 1
import { State, Action, StateContext, Selector, Select } from "@ngxs/store";
import { Gateway } from "../../model/gateway";
import {
  Checked,
  FetchGateways,
  GatewayFilter,
  GatewaySort,
} from "../actions/gateways.action";

import { GatewaysService } from "../../services/gateways.service";
import { SearchAndSortArray } from "../../model/searchAndSortArray";
import { Injectable } from "@angular/core";

export class GatewayStateModel {
  gateways: Gateway[];
  selectedGatewayIDs: number[];
  sortBy: string;
  direction: number;
  searchString: string;
  filterByList: string[];
  exactMatch: boolean;
}

@State<GatewayStateModel>({
  name: "gateways",
  defaults: {
    gateways: [],
    selectedGatewayIDs: [],
    sortBy: "",
    direction: 1,
    searchString: "",
    filterByList: ["name", "city", "state"],
    exactMatch: false,
  },
})
@Injectable()
export class GatewayState {
  constructor(private service: GatewaysService) {}

  @Selector()
  static getGateways(state: GatewayStateModel) {
    state.gateways.map((a) => {
      if (state.selectedGatewayIDs.indexOf(a.gateway_id) > -1) {
        a.selected = true;
      }
    });

    let obj = new SearchAndSortArray(
      state.gateways,
      state.filterByList,
      state.searchString,
      state.exactMatch,
      state.sortBy,
      state.direction
    );
    let gateways = obj.search();
    gateways = obj.sort();
    return gateways;
  }

  @Action(FetchGateways)
  getPosts({ getState, setState }: StateContext<GatewayStateModel>) {
    const state = getState();

    this.service.getAllGateways().subscribe((x) => {
      setState({ ...state, gateways: x });
    });
  }

  @Action(Checked)
  check(
    { getState, patchState }: StateContext<GatewayStateModel>,
    { payload }: Checked
  ) {
    let st = getState().selectedGatewayIDs;
    let idx = st.indexOf(payload);

    if (idx === -1) {
      st.push(payload);
    } else {
      st.splice(idx, 1);
    }

    patchState({ selectedGatewayIDs: st });
  }

  @Selector()
  static getSelectedGatewayIDs(state: GatewayStateModel) {
    return state.selectedGatewayIDs;
  }

  @Action(GatewaySort)
  GatewaySort(
    { getState, patchState }: StateContext<GatewayStateModel>,
    { payload }: GatewaySort
  ) {
    let state = getState();
    let sortBy = state.sortBy;
    let direction = state.direction;

    if (sortBy === payload) {
      direction = direction * -1;
    } else {
      direction = 1;
    }

    patchState({ sortBy: payload, direction });
  }

  @Selector()
  static getSortField(state: GatewayStateModel) {
    return state.sortBy;
  }

  @Selector()
  static getSortDirection(state: GatewayStateModel) {
    return state.direction === 1 ? "sort-asc" : "sort-desc";
  }

  @Action(GatewayFilter)
  GatewayFilter(
    { getState, patchState }: StateContext<GatewayStateModel>,
    { payload }: GatewayFilter
  ) {
    let state = getState();
    let filterByList = payload.filterByList.split(",");
    let exactMatch = payload.exactMatch;
    let searchString = payload.searchString;
    patchState({ filterByList, searchString, exactMatch });
  }
}
