<!-- <app-form-ex></app-form-ex> -->
<div class="row">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">{{ model.device_name }}</h5>
  </div>
  @if (message && message.length > 0) {
    <div class="col-12 alert text-center" [ngClass]="hasError ? 'alert-danger' : 'alert-success'">
      {{ message }}
    </div>
  }
</div>

<form class="adminForm" name="form" (ngSubmit)="f.form.valid && submitDevice(f)" #f="ngForm" novalidate>
  <div class="form-group row mb-1">
    <label for="device_name" class="col-2 col-form-label p-1"><span class="required">*</span>Silo Name</label>
    <div class="col-10">
      <input
        type="text"
        class="form-control p-1"
        name="device_name"
        required
        [(ngModel)]="model.device_name"
        #device_name="ngModel"
        maxlength="20"
        [disabled]="!permissions.hasAddSilosPermission()"
        />
        @if (!device_name.valid && device_name.touched) {
          <div class="help-block alert-danger">Name is required</div>
        }
      </div>
    </div>

    <div class="form-group row mb-1">
      <label for="density_uom" class="col-2 col-form-label p-1"><span class="required">*</span>Company</label>
      <div class="col-10">
        <select
          class="form-control ml-auto mr-1"
          name="group_id"
          [(ngModel)]="model.group_id"
          #group_id="ngModel"
          (ngModelChange)="getLocationByGroupID($event)"
          required
          placeholder="Select Company"
          [disabled]="!permissions.hasAddSilosPermission()"
          >
          <option value=""></option>
          @for (group of groups | async; track group) {
            <option [ngValue]="group.group_id" [selected]="model.group_id === group.group_id">
              {{ group.name }}
            </option>
          }
        </select>
        @if (!group_id.valid && group_id.touched) {
          <div class="help-block alert-danger">Company is required</div>
        }
      </div>
    </div>

    <div class="form-group row mb-1">
      <label for="density_uom" class="col-2 col-form-label p-1"><span class="required">*</span>Location</label>
      <div class="col-10">
        <select
          class="form-control ml-auto mr-1"
          name="location_id"
          [(ngModel)]="model.location_id"
          #location_id="ngModel"
          (ngModelChange)="getGatewayByLocationID($event)"
          required
          [disabled]="!permissions.hasAddSilosPermission()"
          >
          <option value=""></option>
          @for (location of locationsByGroupId; track location) {
            <option
              [ngValue]="location.location_id"
              [selected]="model.location_id === location.location_id"
              >
              {{ location.name }}
            </option>
          }
        </select>
        @if (!location_id.valid && location_id.touched) {
          <div class="help-block alert-danger">Location is required</div>
        }
      </div>
    </div>

    <div class="form-group row mb-1">
      <label for="density_uom" class="col-2 col-form-label p-1"><span class="required">*</span>Gateway</label>
      <div class="col-10">
        <select
          class="form-control ml-auto mr-1"
          name="gateway_id"
          required
          [(ngModel)]="model.gateway_id"
          #gateway_id="ngModel"
          [disabled]="!permissions.hasAddSilosPermission()"
          >
          <option value=""></option>
          @for (gateway of gatewaysByLocationId; track gateway) {
            <option
              [ngValue]="gateway.gateway_id"
              [selected]="model.gateway_id === gateway.gateway_id"
              >
              {{ gateway.name }}
            </option>
          }
        </select>
        <!-- <a routerLink="/administration/gateway/edit/{{model.gateway_id}}">{{model.gateway_name}} ({{model.company_name}}/{{ model.location_name }})</a> -->
        @if (!gateway_id.valid && gateway_id.touched) {
          <div class="help-block alert-danger">Gateway is required</div>
        }
      </div>
    </div>

    <div class="form-group row mb-1">
      <label for="device_dia_name" class="col-2 col-form-label p-1"><span class="required">*</span>Sensor Type</label>
      <div class="col-10">
        <select
          class="form-control ml-auto mr-1"
          name="sensor_type"
          required
          [(ngModel)]="model.sensor_type"
          #type="ngModel"
          [disabled]="!permissions.hasAddSilosPermission()"
          >
          <!-- <option [ngValue]="'smu344n'"  [selected]="model.type == 'smu344n' ">Silo Patrol</option>
          <option [ngValue]="'bm100'" [selected]="model.type == 'bm100'  ">Flexar</option> -->
          @for (s of sensorTypes; track s) {
            <option [ngValue]="s.CODE" [selected]="model.sensor_type == 's.CODE'">
              {{ s.DECODE }}
            </option>
          }
        </select>
        @if (!type.valid && type.touched) {
          <div class="help-block alert-danger">Sensor Type is required</div>
        }
      </div>
    </div>

    <div class="form-group row mb-1">
      <label for="device_dia_name" class="col-2 col-form-label p-1"><span class="required">*</span>Sensor Address</label>
      <div class="col-10">
        <select
          class="form-control ml-auto mr-1"
          name="sensor_address"
          [(ngModel)]="model.sensor_address"
          #sensor_address="ngModel"
          required
          [disabled]="!permissions.hasAddSilosPermission()"
          >
          @for (i of addr; track i) {
            <option [ngValue]="i" [selected]="model.sensor_address == i">{{ i }}</option>
          }
        </select>
        @if (!sensor_address.valid && sensor_address.touched) {
          <div class="help-block alert-danger">Sensor address is required</div>
        }
      </div>
    </div>

    <div class="form-group row mb-1">
      <label for="status_cde" class="col-2 col-form-label p-1"><span class="required">*</span>Status</label>
      <div class="col-10">
        <select
          class="form-control ml-auto mr-1"
          name="status_cde"
          required
          [(ngModel)]="model.status_cde"
          #status_cde="ngModel"
          [disabled]="!permissions.hasAddSilosPermission()"
          >
          <option [ngValue]="'A'" [selected]="model.status_cde === 'A' || model.status_cde === 'a'">Active</option>
          <option [ngValue]="'Z'" [selected]="model.status_cde === 'Z' || model.status_cde === 'z'">Inactive</option>
        </select>
        @if (!status_cde.valid && status_cde.touched) {
          <div class="help-block alert-danger">Status is required</div>
        }
      </div>
    </div>

    <div class="form-group row mb-1">
      <label for="material" class="col-2 col-form-label p-1">Material</label>
      <div class="col-10">
        <input type="text" class="form-control p-1" name="material" [(ngModel)]="model.material" #material="ngModel" maxlength="25" />
      </div>
    </div>

    <div class="form-group row mb-1">
      <label for="linear_uom" class="col-2 col-form-label p-1"><span class="required">*</span>English/Metric</label>
      <div class="col-10">
        <select
          class="form-control ml-auto mr-1"
          name="linear_type"
          required
          [(ngModel)]="model.linear_type"
          #linear_type="ngModel"
          (ngModelChange)="changeLinearUOM()"
          >
          <option value="F" [selected]="model.linear_type == 'F'">English</option>
          <option value="M" [selected]="model.linear_type == 'M'">Metric</option>
          <!-- <option *ngFor="let d of linear_uom_code" [ngValue]="d.CODE" [selected]="model.linear_type == d.CODE">{{d.label}}</option> -->
        </select>
        @if (!linear_uom.valid && linear_uom.touched) {
          <div class="help-block alert-danger">Measurement Type is required</div>
        }
      </div>
    </div>

    <div class="form-group row mb-1">
      <label for="density" class="col-2 col-form-label p-1">Bulk Density</label>
      <div class="col-10">
        <input type="number" class="form-control p-1" name="density" [(ngModel)]="model.density" #density="ngModel" />
      </div>
    </div>

    <div class="form-group row mb-1">
      <label for="density_uom" class="col-2 col-form-label p-1">Density Units</label>
      <div class="col-10">
        <select class="form-control ml-auto mr-1" name="density_uom" [(ngModel)]="model.density_uom" #density_uom="ngModel" [disabled]="true">
          @for (d of density_uom_code; track d) {
            <option [ngValue]="d.CODE" [selected]="model.density_uom === d.CODE">{{ d.DECODE }}</option>
          }
        </select>
      </div>
    </div>

    <div class="form-group row mb-1">
      <label for="linear_uom" class="col-2 col-form-label p-1"><span class="required">*</span>Linear Units</label>
      <div class="col-10">
        <select
          class="form-control ml-auto mr-1"
          name="linear_uom"
          required
          [(ngModel)]="model.linear_uom"
          #linear_uom="ngModel"
          [disabled]="true"
          >
          @for (d of linear_uom_code; track d) {
            <option [ngValue]="d.CODE" [selected]="model.linear_uom == d.CODE">{{ d.DECODE }}</option>
          }
        </select>
        @if (!linear_uom.valid && linear_uom.touched) {
          <div class="help-block alert-danger">Linear Unit is required</div>
        }
      </div>
    </div>

    <div class="form-group row mb-1">
      <label for="volume_uom" class="col-2 col-form-label p-1">Volume Units</label>
      <div class="col-10">
        <select class="form-control ml-auto mr-1" name="volume_uom" [(ngModel)]="model.volume_uom" #volume_uom="ngModel">
          @for (d of volume_uom_code; track d) {
            @if (d.label === model.linear_type) {
              <option [ngValue]="d.CODE" [selected]="model.volume_uom === d.CODE">{{ d.DECODE }}</option>
            }
          }
        </select>
      </div>
    </div>

    <div class="form-group row mb-1">
      <label for="weight_uom" class="col-2 col-form-label p-1">Weight Units</label>
      <div class="col-10">
        <select class="form-control ml-auto mr-1" name="weight_uom" [(ngModel)]="model.weight_uom" #weight_uom="ngModel">
          @for (d of weight_uom_code; track d) {
            @if (d.label === model.linear_type) {
              <option [ngValue]="d.CODE" [selected]="model.weight_uom === d.CODE">{{ d.DECODE }}</option>
            }
          }
        </select>
      </div>
    </div>

    <div class="form-group row mb-1">
      <label for="alarm_display" class="col-2 col-form-label p-1"><span class="required">*</span>Alarm Units</label>
      <div class="col-10">
        <select class="form-control ml-auto mr-1" name="alarm_display" [(ngModel)]="model.alarm_display" #alarm_display="ngModel" required="">
          @for (d of alarm_uom_code; track d) {
            <option [ngValue]="d.CODE" [selected]="model.alarm_display === d.CODE">{{ d.DECODE }}</option>
          }
        </select>
        @if (!default_display.valid && default_display.touched) {
          <div class="help-block alert-danger">Linear Unit is required</div>
        }
      </div>
    </div>

    <div class="form-group row mb-1">
      <label for="default_display" class="col-2 col-form-label p-1"><span class="required">*</span>Default Display</label>
      <div class="col-10">
        <select
          class="form-control ml-auto mr-1"
          name="default_display"
          required
          [(ngModel)]="model.default_display"
          #default_display="ngModel"
          >
          @for (d of defaultDisplay; track d) {
            <option [ngValue]="d.CODE" [selected]="model.default_display === d.CODE">{{ d.DECODE }}</option>
          }
        </select>
        @if (!default_display.valid && default_display.touched) {
          <div class="help-block alert-danger">Linear Unit is required</div>
        }
      </div>
    </div>

    <div class="form-group row mb-1">
      <label for="shape" class="col-2 col-form-label p-1">Shape</label>
      <div class="col-10">
        <select class="form-control ml-auto mr-1" name="shape" [(ngModel)]="model.shape" #shape="ngModel">
          @for (d of shapes; track d) {
            <option [ngValue]="d.CODE" [selected]="model.shape === d.CODE">{{ d.DECODE }}</option>
          }
        </select>
      </div>
    </div>

    @if (model.shape == 'E') {
      <div class="form-group row mb-1">
        <label for="strapping_unit" class="col-2 col-form-label p-1">Strapping Unit</label>
        <div class="col-10">
          <select
            class="form-control ml-auto mr-1"
            name="strapping_unit"
            [(ngModel)]="model.strapping_type"
            #strapping_type="ngModel"
            (ngModelChange)="getStrappingLabel($event)"
            >
            @for (d of strapping_unit; track d) {
              <option [ngValue]="d.CODE" [selected]="model.strapping_type === d.CODE">{{ d.DECODE }}</option>
            }
          </select>
        </div>
      </div>
    }

    <div class="form-group row mb-1">
      <label for="height" class="col-2 col-form-label p-1">Height</label>
      <div class="col-10">
        <input type="text" class="form-control p-1" name="height" [(ngModel)]="model.height" #height="ngModel" />
      </div>
    </div>

    @if (model.shape == 'E') {
      <div class="form-group row mb-1">
        <label for="strapping_unit" class="col-2 col-form-label p-1">Strapping</label>
        <div class="col-10">
          <table class="table">
            <thead>
              <tr>
                <th width="1%">&nbsp;</th>
                <th>Distance</th>
                <th>{{ strapping_label }}</th>
                <th width="1%">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              @for (s of strappingModel; track trackByIndex(_index, s); let _index = $index) {
                <tr>
                  <td style="width: 4px" nowrap>{{ _index + 1 }}:</td>
                  <td>
                    <input type="number" class="form-control p-1" name="distance_{{ _index }}" [(ngModel)]="strappingModel[_index].distance" />
                  </td>
                  <td>
                    <input type="number" class="form-control p-1" name="strapping_{{ _index }}" [(ngModel)]="strappingModel[_index].strapping" />
                  </td>
                  <td>
                    @if (_index > 1) {
                      <span
                        class="badge badge-danger"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Remove"
                        (click)="removeStrapping(_index)"
                        ><i class="fa fa-times"></i
                      ></span>
                    }
                  </td>
                </tr>
              }
              @if (strappingSize < 16) {
                <tr>
                  <td>
                    <button
                      class="form-control btn btn-primary p-1"
                      (click)="addStrapping($event)"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Add Strapping"
                      >
                      <i class="fa fa-plus"></i>
                    </button>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    }

    @if (model.shape == 'B' && model.shape !== 'E') {
      <div class="form-group row mb-1">
        <label for="diameter" class="col-2 col-form-label p-1">Diameter</label>
        <div class="col-10">
          <input type="text" class="form-control p-1" name="diameter" [(ngModel)]="model.diameter" #diameter="ngModel" />
        </div>
      </div>
    }

    @if ((model.shape == 'C' || model.shape == 'D') && model.shape !== 'E') {
      <div class="form-group row mb-1">
        <label for="length" class="col-2 col-form-label p-1">Length</label>
        <div class="col-10">
          <input type="text" class="form-control p-1" name="length" [(ngModel)]="model.length" #length="ngModel" />
        </div>
      </div>
    }

    @if ((model.shape == 'C' || model.shape == 'D') && model.shape !== 'E') {
      <div class="form-group row mb-1">
        <label for="width" class="col-2 col-form-label p-1">Width</label>
        <div class="col-10">
          <input type="text" class="form-control p-1" name="width" [(ngModel)]="model.width" #width="ngModel" />
        </div>
      </div>
    }

    @if (model.shape !== 'E') {
      <div class="form-group row mb-1">
        <label for="outlet_height" class="col-2 col-form-label p-1">Cone Height</label>
        <div class="col-10">
          <input type="text" class="form-control p-1" name="outlet_height" [(ngModel)]="model.outlet_height" #outlet_height="ngModel" />
        </div>
      </div>
    }

    @if (model.shape == 'B' && model.shape !== 'E') {
      <div class="form-group row mb-1">
        <label for="outlet_diameter" class="col-2 col-form-label p-1">Discharge Diameter</label>
        <div class="col-10">
          <input
            type="text"
            class="form-control p-1"
            name="outlet_diameter"
            id="outlet_diameter"
            [(ngModel)]="model.outlet_diameter"
            #outlet_diameter="ngModel"
            />
          </div>
        </div>
      }

      @if ((model.shape == 'C' || model.shape == 'D') && model.shape !== 'E') {
        <div class="form-group row mb-1">
          <label for="outlet_length" class="col-2 col-form-label p-1">Discharge Length</label>
          <div class="col-10">
            <input type="text" class="form-control p-1" name="outlet_length" [(ngModel)]="model.outlet_length" #outlet_length="ngModel" />
          </div>
        </div>
      }

      @if ((model.shape == 'C' || model.shape == 'D') && model.shape !== 'E') {
        <div class="form-group row mb-1">
          <label for="outlet_width" class="col-2 col-form-label p-1">Discharge Width</label>
          <div class="col-10">
            <input type="text" class="form-control p-1" name="outlet_width" [(ngModel)]="model.outlet_width" #outlet_width="ngModel" />
          </div>
        </div>
      }

      <div class="form-group row mb-1">
        <label for="dead_zone" class="col-2 col-form-label p-1">Dead Zone %</label>
        <div class="col-10">
          <input
            type="number"
            class="form-control p-1"
            name="dead_zone"
            [(ngModel)]="model.dead_zone"
            #dead_zone="ngModel"
            min="0"
            max="100"
            pattern="^[0-9][0-9]?$|^100$"
            />
            @if (!dead_zone.valid && dead_zone.touched) {
              <div class="help-block alert-danger">Dead Zone value should be between 0 and 100</div>
            }
          </div>
        </div>

        <div class="form-group row mb-1">
          <label for="longitude" class="col-2 col-form-label p-1">&nbsp;</label>
          <div class="col-10">
            <button class="btn btn-success p-1 mr-1" [disabled]="f.invalid || !canUpdateSilo">Update Silo</button>
            @if (permissions.hasAddSilosPermission()) {
              <button
                class="btn btn-danger p-1 mr-1"
                data-toggle="modal"
                data-target="#delDevice"
                (click)="fakeDelete($event)"
                >
                Delete
              </button>
            }
            <button class="btn btn-default p-1" routerLink="/administration/silos">Cancel</button>
          </div>
        </div>
      </form>
      <div class="modal fade" id="delDevice" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">{{ model.device_name }}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">Are you sure you want to delete {{ model.device_name }}?</div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
              <button type="button" class="btn btn-danger" (click)="deleteDevice(model.device_id)" data-dismiss="modal">Delete</button>
            </div>
          </div>
        </div>
      </div>
