<div style="margin-top: 10%">
  <div tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">

    <div class="modal-dialog modal-lg d-flex justify-content-center" role="document">

      @if (user_name.value.length == 0 || email.value.length == 0) {
        <div class="modal-content">
          <div class="modal-body">
            <div class="row">Sorry, we are unable to recover your password. Either reset your password again or contact administrator.</div>
            <div class="r">
              <button type="button" class="btn btn-secondary"  routerLink="/login">Cancel</button>
              <button type="button" class="btn btn-secondary"  routerLink="/reset">Reset</button>
            </div>
          </div>
        </div>
      }
      @if (user_name.value.length > 0 && email.value.length > 0) {
        <div class="modal-content">
          @if (message && message.length > 0) {
            <div class="modal-body alert text-center" [ngClass]="hasError? 'alert-danger' : 'alert-success' " >
              {{message}}
            </div>
          }
          <form [formGroup]="userPassword" (ngSubmit)="password.value === confirmpassword.value && savePassword(userPassword)" autocomplete="off">
            <div class="row">
              <h5 class="col-12" id="exampleModalLabel">Login Name: {{ user_name.value }}</h5>
              <h5 class="col-12" id="exampleModalLabel">Email: {{ email.value }}</h5>
            </div>
            <input type="hidden" class="form-control p-1" name="uuid" id="uuid" [formControl]="uuid" autocomplete="off" />
            <input type="hidden" class="form-control p-1" name="user_name" id="user_name" [formControl]="user_name" autocomplete="off" />
            <input type="hidden" class="form-control p-1" name="email" id="email" [formControl]="email" autocomplete="off" />
            <div class="modal-body">
              <div class="form-group row mb-1">
                <label for="password" class="col-2 col-form-label p-1"><span class="required">*</span>Password</label>
                <div class="col-10">
                  <input type="password" class="form-control p-1" name="password" id="password" [formControl]="password" autocomplete="off" />
                </div>
              </div>
              <div class="form-group row mb-1">
                <label for="confirmpassword" class="col-2 col-form-label p-1"><span class="required">*</span>Confirm Password</label>
                <div class="col-10">
                  <input type="password" class="form-control p-1" name="confirmpassword" id="confirmpassword" [formControl]="confirmpassword" autocomplete="off" />
                </div>
              </div>
              @if (password.valid && (password.value !== confirmpassword.value) && (password.touched && confirmpassword.touched)) {
                <div class="col-12 alert alert-danger p-1">
                  The Password provided do not match.
                </div>
              }
              @if (password.invalid && password.touched) {
                <div class="col-12 alert alert-danger p-1">
                  <p>Password must have</p>
                  <ul>
                    <li>At least 8 characters</li>
                    <li>At least one number</li>
                    <li>At least one capital letter</li>
                    <li>At least one small letter</li>
                    <li>At least one special character (*!&#64;#$%^&)</li>
                  </ul>
                </div>
              }
              @if (password.valid && user_name.value === password.value) {
                <div class="col-12 alert alert-danger p-1">
                  Password should be different from Login Name.
                </div>
              }
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary"  routerLink="/login">Cancel</button>
              <button type="submit" class="btn btn-danger" [disabled]="password.invalid || user_name.value == password.value || password.value !== confirmpassword.value">Update</button>
            </div>
          </form>
        </div>
      }
    </div>
  </div>
</div>