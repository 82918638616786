import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';


import { environment } from '../../environments/environment';
import { Location } from '../model/location';
import { UtilService } from './util.service';

@Injectable()
export class LocationsService {
	private locationUrl = environment.apiurl + '/location/getDistinctLocations';
	private userLocationUrl = environment.apiurl + '/location/getUserLocations';

	public hasError: Subject<boolean> = new Subject<boolean>();
	public message: Subject<string> = new Subject<string>();
	public location_id: Subject<number> = new Subject<number>();

	private _locations: BehaviorSubject<Location[]> = new BehaviorSubject<Location[]>([]);
	public locations: Observable<Location[]> = this._locations.asObservable();

	private _userLocations: Subject<Location[]> = new Subject<Location[]>();
	public userLocations: Observable<Location[]> = this._userLocations.asObservable();

	// constructor (private http: Http) {}
	constructor(private util: UtilService, private router: Router) {}

	public getLocations(lid?, page?: number, recordsPerPage?: number, gid?, status_cde: string = ''): void {
		if (!lid || lid.trim().length == 0) {
			lid = '';
		}

		if (isNaN(gid)) {
			gid = '';
		}


		const params: any = {}
		if(lid) params.location_id = lid;
		if(page) params.page = page;
		if(recordsPerPage) params.recordsPerPage = recordsPerPage;
		if(gid) params.gid = gid;
		if(status_cde) params.status_cde = gid;
		

		this.util
			._getData( this.locationUrl, params )
			.pipe(map((ret) => ret.data))
			.subscribe((locationJSON) => {
				const locations = locationJSON.map((locationjson) => Location.fromJSON(locationjson));
				this._locations.next(locations);
			});
	}

	public getUserLocations(user_id: number, group_id: number): void {
		let params = [];

		if (user_id) params.push('user_id=' + user_id);
		if (group_id) params.push('group_id=' + group_id);

		this.util
			._requestData(this.userLocationUrl + '?' + params.join('&'))
			.pipe(map((ret) => ret.data))
			.subscribe((locationJSON) => {
				const locations = locationJSON.map((locationjson) => Location.fromJSON(locationjson));
				this._userLocations.next(locations);
			});
	}

	public getLocationByGroupId(group_id?: any): Observable<Location[]> {

		return this.util
			._getData(environment.apiurl + '/location/getLocations', {group_id})
			.pipe(map((ret) => ret.data))
			.pipe(map((cJSON) => {
				const locations = cJSON.map((jsonobj) => Location.fromJSON(jsonobj));
				return locations;
			}));
	}

	public saveLocation(body): void {
		let url = environment.apiurl + '/location/saveLocation';
		this.util
			._postData(url, body)
			.pipe(map((ret) => ret.data))
			.subscribe(
				(res) => {
					this.location_id.next(res.location_id);
					this.router.navigateByUrl('/administration/location/edit/' + res.location_id);
				},
				(err) => {
					console.log('Error saving group', err);
					this.hasError.next(true);
					this.message.next(err.error.message);
				},
				() => {
					console.log('saving group completed');
					this.hasError.next(false);
					this.message.next('Location information is saved successfully.');
				}
			);
	}

	public deleteLocation(body): void {
		let url = environment.apiurl + '/location/deleteLocation';
		this.util
			._postData(url, body)
			.pipe(map((ret) => ret.data))
			.subscribe(
				(res) => {
					this.router.navigateByUrl('/administration/locations');
				},
				(err) => {
					console.log('Error saving group');
					this.hasError.next(true);
					this.message.next(err.message);
				},
				() => {
					console.log('saving group completed');
					this.hasError.next(false);
					this.message.next('Location has been deleted successfully.');
				}
			);
	}

	public getAllLocations(): Observable<Location[]> {
		return this.util
			._requestData(this.locationUrl)
			.pipe(map((ret) => ret.data))
			.pipe(map((cJSON) => {
				const loc = cJSON.map((jsonobj) => Location.fromJSON(jsonobj));
				return loc;
			}));
	}
}
