<div class="row">
  <div class="col-12">
    <h3>Send email notification</h3>
  </div>
  <div class="row m-1">
    <app-admin-nav></app-admin-nav>
    <div>&nbsp;</div>
  </div>
</div>
<div class="row mt-2">
  @if (message.length > 0 && !loading) {
    <div class="col-12">
      @if (success) {
        <p class="alert alert-success my-1" [innerHtml]="message"></p>
      }
      @if (!success) {
        <p class="alert alert-danger my-1">
          {{ message }}
        </p>
      }
    </div>
  }
</div>

<form class="form mt-2" #f="ngForm">
  <div class="form-group row mb-1">
    <div class="col-2">
      <label for="group_id" class="col-form-label p-1">Companies</label>
    </div>
    <div class="col-sm-12 col-md-6">
      <ng-select
        [items]="groups"
        bindLabel="name"
        bindValue="group_id"
        name="group_id"
        id="group_id"
        placeholder="Select a Company"
        [multiple]="true"
        [(ngModel)]="model.group_id"
        (change)="onGroupChange()"
        >
      </ng-select>
    </div>
  </div>

  <div class="form-group row mb-1">
    <div class="col-2">
      <label for="location_id" class="col-form-label">Locations</label>
    </div>
    <div class="col-sm-12 col-md-6">
      <ng-select
        [items]="locations"
        bindLabel="name"
        bindValue="location_id"
        name="location_id"
        placeholder="Select a Location"
        [multiple]="true"
        [(ngModel)]="model.location_id"
        >
      </ng-select>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-2">
      <label>Subject</label>
    </div>
    <div class="col-sm-12 col-md-6">
      <input
        type="text"
        class="form-control"
        id="name"
        placeholder="Subject"
        required
        [(ngModel)]="model.subject"
        name="subject"
        requried
        />
      </div>
    </div>

    <div class="form-group row mt-2">
      <div class="col-2">
        <label>Body</label>
      </div>
      <div class="col-sm-12 col-md-6">
        <ckeditor #editor [editor]="Editor" [config]="config" [(ngModel)]="model.body" placeholder="Body" name="body" required></ckeditor>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-2">&nbsp;</div>
      <div class="col mt-2">
        @if (loading) {
          <app-loading-spinner class="col-12"></app-loading-spinner>
        }
        @if (!loading) {
          <button type="button" class="btn btn-success" (click)="save()" [disabled]="f.invalid">Send</button>
        }
      </div>
    </div>
  </form>

