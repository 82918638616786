<!-- <style type="text/css">
@media (max-width: 767px){
.ngui-datetime-picker {
position: relative !important;
left: 0;
right: 0;
width: auto !important;
animation: slideUp 0.1s ease-in-out;
}
}
</style> -->
<div class="container">
  <div class="row">
    <div class="col p-1">
      From:
      <!--
      date-format="MM-DD-YYYY HH:mm"
      -->
      <input
        class="form-control p1 col-12"
        placement="bottom"
        [value]="model.startDate.displayDate"
        [ngbPopover]="startDateBlock"
      />

      <ng-template #startDateBlock>
        <ngb-datepicker
          id="startDate"
          #startDate
          name="datepicker"
          [firstDayOfWeek]="7"
          [ngModel]="model.startDate"
          (ngModelChange)="onDateChange($event, true)"
        ></ngb-datepicker>
        <div class="mt-auto">
          <ngb-timepicker
            #startTime
            name="timepicker"
            [ngModel]="model.startDate"
            (ngModelChange)="onTimeChange($event, true)"
          >
          </ngb-timepicker>
        </div>
      </ng-template>
    </div>

    <div class="col p-1">
      To:
      <!-- date-format="MM-DD-YYYY HH:mm" -->
      <input
        class="form-control p1 col-12"
        placement="bottom"
        [value]="model.endDate.displayDate"
        [ngbPopover]="endDateBlock"
      />

      <ng-template #endDateBlock>
        <ngb-datepicker
          id="endDate"
          #endDate
          name="datepicker"
          [firstDayOfWeek]="7"
          [ngModel]="model.endDate"
          (ngModelChange)="onDateChange($event, false)"
        ></ngb-datepicker>
        <div class="mt-auto">
          <ngb-timepicker
            #endTime
            name="timepicker"
            [ngModel]="model.endDate"
            (ngModelChange)="onTimeChange($event, false)"
          >
          </ngb-timepicker>
        </div>
      </ng-template>
    </div>
    <div class="col p-1">
      &nbsp;
      <button
        type="button"
        class="btn btn-primary form-control p-1"
        (click)="updateSearch(device.device_id)"
      >
        Update
      </button>
    </div>
  </div>
</div>
@if (loading) {
<app-loading-spinner
  style="position: absolute; top: 6rem; left: 12rem; z-index: 100"
></app-loading-spinner>
} @if (device.distance === 0 && !loading) {
<div>No Data Found. Please update the date range.</div>
}
<div id="newchart_{{ device.device_id }}{{ id }}" #chart></div>
