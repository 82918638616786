import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { PagerComponent } from '../../pager/pager.component';


import { LocationsService } from '../../services/locations.service';

import { Location } from '../../model/location';
import { Permissions } from '../../model/permissions';
import { Codes } from '../codes';


import { Store, Select } from '@ngxs/store';
import { GroupState } from '../../store/states/groups.state';
import { LocationState } from '../../store/states/locations.state';
import { LocationChecked, FetchLocations, LocationGroupIDs, LocationSort, LocationFilter } from '../../store/actions/locations.action';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.css']
})
export class LocationsComponent implements OnInit {
  public permissions = new Permissions();
	// public locations: Observable<Location[]>;
  public codes:Codes = new Codes();
  public rowItems:Location[] = [];

  @Select(LocationState.getLocations) locations: Observable<Location[]>
  @Select(LocationState.getSelectedLocationIDs) selectedLocationIDs: Observable<number[]>
  @Select(LocationState.getSortDirection) sortDirection: Observable<string>
  @Select(LocationState.getSortField) sortField: Observable<string>
  @ViewChild(PagerComponent) pager: PagerComponent;

	constructor(private locationsService: LocationsService, private router: Router, private store: Store) { 
	}
   
  ngOnInit() {
    if( !this.permissions.hasLocationsPermission() ){
      this.router.navigateByUrl('devices');
    }
    this.store.dispatch(new FetchLocations());
    this.sort('location_name')
  }


  getStatusCode(cde){
    return this.codes.deCode('status_cde',cde);    
  }

  setPagedItem(rows) {
    this.rowItems = rows;
  }

  sort(field){
    this.store.dispatch(new LocationSort(field));
    this.pager.setPage(1)
  }

  filter(field,str, exactMatch){
    field = 'group_name,name,city,state';
    this.store.dispatch(new LocationFilter({filterByList:field,searchString:str, exactMatch}));
  }
}
