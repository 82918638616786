<div class="container">
  <form [formGroup]="contactForm" (ngSubmit)="contactForm.valid && submitContact()" autocomplete="false">
    <input type="hidden" name="contact" value="yes" />
    <fieldset>
      <legend class="legend">Contact Us</legend>
    </fieldset>

    <div class="form-group row mb-1">
      @if (message && message.length > 0) {
        <div class="col-12 alert text-center" [ngClass]="hasError ? 'alert-danger' : 'alert-success'">
          {{ message }}
        </div>
      }
    </div>

    <div class="form-group row mb-1">
      <div class="col-12">
        For Installation and Operation manuals, <a href="http://www.monitortech.com/resource.html" target="_blank">click here</a>.<br />
        @if (!isTech) {
          <a href="https://www.monitortech.com/st-c/394C.pdf" target="blank">User Manual, Manager |</a>
        }
        @if (!isTech) {
          <a href="https://www.monitortech.com/st-c/394A.pdf" target="blank"> Installation Manual, Gateway | </a>
        }
        <a href="https://www.monitortech.com/st-c/394D.pdf" target="blank">User Manual, Technician</a>
      </div>
      <div class="col-12 mt-2">For phone support, call (630)365-9403 and ask for Tech Support.</div>
    </div>

    <div class="form-group row mb-1">
      <label for="name" class="col-2 col-form-label p-1"><span class="required">*</span>Name</label>
      <div class="col-10 col-sm-10 col-md-6">
        <input type="text" class="form-control p-1" name="name" id="name" placeholder="Name" [formControl]="name" />
        <div [hidden]="name.valid || name.untouched" class="alert alert-danger p-1">Contact name is required</div>
      </div>
    </div>

    <div class="form-group row mb-1">
      <label for="phone" class="col-2 col-form-label p-1">Phone</label>
      <div class="col-10 col-sm-10 col-md-6">
        <input text="tel" class="form-control p-1" name="phone" id="phone" placeholder="Phone" [formControl]="phone" />
      </div>
    </div>

    <div class="form-group row mb-1">
      <label for="email" class="col-2 col-form-label p-1"><span class="required">*</span>Contact Email</label>
      <div class="col-10 col-sm-10 col-md-6">
        <input type="email" class="form-control p-1" name="email" id="email" placeholder="user@example.com" [formControl]="email" />
        <div [hidden]="email.valid || email.untouched" class="alert alert-danger p-1">Email Address is required</div>
      </div>
    </div>

    <div class="form-group row mb-1">
      <label for="subject" class="col-2 col-form-label p-1"><span class="required">*</span>Email Subject</label>
      <div class="col-10 col-sm-10 col-md-6">
        <input type="text" class="form-control p-1" name="subject" id="subject" placeholder="Subject" [formControl]="subject" />
        <div [hidden]="subject.valid || subject.untouched" class="alert alert-danger p-1">Email subject is required</div>
      </div>
    </div>

    <div class="form-group row mb-1">
      <label for="comment" class="col-2 col-form-label p-1"><span class="required">*</span>Questions &amp; Comments</label>
      <div class="col-10 col-sm-10 col-md-6">
        <textarea name="comment" id="comment" placeholder=". . . . ." cols="60" rows="8" [formControl]="comment"></textarea>
        <div [hidden]="comment.valid || comment.untouched" class="alert alert-danger p-1">Please add your question or comment</div>
      </div>
    </div>
    <div class="from-group">
      <div class="controls">
        <input type="submit" name="btnSend" value="Send" class="btn btn-success" [disabled]="!contactForm.valid || isSalesRep" />
      </div>
    </div>
  </form>
</div>
