// Section 1
import { State, Action, StateContext, Selector, Select } from "@ngxs/store";
import { User } from "../../model/user";
import { FetchUsers, UserSort, UserFilter } from "../actions/users.action";

import { UsersService } from "../../services/users.service";

import { SearchAndSortArray } from "../../model/searchAndSortArray";
import { Injectable } from "@angular/core";

export class UserStateModel {
  users: User[];
  sortBy: string;
  direction: number;
  filterBy: string;
  searchString: string;
  filterByList: string[];
  exactMatch: boolean;
}

@State<UserStateModel>({
  name: "users",
  defaults: {
    users: [],
    sortBy: "name",
    direction: 1,
    filterBy: "",
    searchString: "",
    filterByList: ["name", "user_name", "user_type_desc", "company"],
    exactMatch: false,
  },
})
@Injectable()
export class UserState {
  constructor(private service: UsersService) {}

  @Selector()
  static getUsers(state: UserStateModel) {
    let obj = new SearchAndSortArray(
      state.users,
      state.filterByList,
      state.searchString,
      state.exactMatch,
      state.sortBy,
      state.direction
    );
    // let users =state.users;
    // let patt = new RegExp(state.searchString,'i');
    let users = obj.search();
    // users = users.filter(x=> patt.test( x[state.filterBy] ))
    users = obj.sort();

    return users;
  }

  @Action(FetchUsers)
  fetchUsers({ getState, setState }: StateContext<UserStateModel>) {
    const state = getState();
    let posts: User[] = [];
    // TO DO: create a function to set the admin property
    this.service.getUsers({ admin: 1 }).subscribe((x) => {
      setState({ ...state, users: x });
    });
  }

  @Action(UserSort)
  UserSort(
    { getState, patchState }: StateContext<UserStateModel>,
    { payload }: UserSort
  ) {
    let state = getState();
    let sortBy = state.sortBy;
    let direction = state.direction;

    if (sortBy === payload) {
      direction = direction * -1;
    } else {
      direction = 1;
    }

    patchState({ sortBy: payload, direction });
  }

  @Selector()
  static getSortField(state: UserStateModel) {
    return state.sortBy;
  }

  @Selector()
  static getSortDirection(state: UserStateModel) {
    return state.direction === 1 ? "sort-asc" : "sort-desc";
  }

  @Action(UserFilter)
  UserFilter(
    { getState, patchState }: StateContext<UserStateModel>,
    { payload }: UserFilter
  ) {
    let state = getState();
    let filterByList = payload.filterByList.split(",");
    let exactMatch = payload.exactMatch;
    let searchString = payload.searchString;
    patchState({ filterByList, searchString, exactMatch });
  }
}
