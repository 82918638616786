import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { UsersService } from '../../services/users.service';
import { Permissions } from '../../model/permissions';

@Component({
  selector: 'app-users-report',
  templateUrl: './users-report.component.html',
  styleUrls: ['./users-report.component.scss']
})
export class UsersReportComponent implements OnInit {
	permissions = new Permissions();
  /* logged in user type*/
	
  success = false;
  loading = false;
  message = '';
  path = 'https://s3.amazonaws.com/monitortech/';
  fileName = '';
  btn_label = 'Generate Users list';
// none
  constructor(private usersService: UsersService, private router:Router ) { }

  ngOnInit() {
    console.log('this.getUserType()', this.getUserType())
    if(this.getUserType() !== 'A' && this.getUserType() !== 'B'){
      this.router.navigateByUrl('devices');
    }
  }

  getUserType() {
		return this.permissions.userType;
	}

  generate(){
    this.success = false;
    this.loading = true;
    this.fileName = '';
    this.message = '';

    this.btn_label = 'Please wait...';

    this.usersService.generateUserReport().subscribe(x=>{
      this.success = true;
      this.loading = false;
      this.fileName = x.file;
      this.message = 'The users list has been generated successfully.'
      this.btn_label = 'Generate Users list';
    },
    (err)=>{
      this.success = false;
      this.loading = false;
      this.message = 'There was an error generating a report. Please contact administrator.';
      this.btn_label = 'Generate Users list';
      console.log(err)
    },()=>{
      console.log('DONE with user report')
    })
  }

}
