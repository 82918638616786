<div class="row">
  <div class="col-12">
    <h3>Alarm Log</h3>
  </div>
</div>

<form name="form" #f="ngForm" (ngSubmit)="updateLog()" novalidate>
  <div class="row">
    <a href="javascript:void(0)" class="d-block d-md-none p-1 mb-1 col-3" (click)="showFilter()">
      Filter <i class="fa" aria-hidden="true" [ngClass]="{'fa-search-plus': !filter, 'fa-search-minus':filter}"></i>
    </a>
    <div class="col-12 d-none d-md-block" [ngClass]="{'showFilter':filter}">
      <div class="row">
        <div class="col-md-3 col-sm-12 mb-1 pl-0">
          <select class="form-control " name="group_id" [(ngModel)]="model.group_id" #group_id="ngModel"
            [hidden]="!hasCompaniesPermission()" (change)="filterLocationByGroup()">
            <option value="" [selected]="model.group_id === '' ">Companies</option>
            @for (group of groups | async; track group) {
              <option [ngValue]="group.group_id"
              [selected]="group.group_id === model.group_id">{{group.name}}</option>
            }
          </select>
        </div>
        <div class="col-md-3 col-sm-12 mb-1 pl-0">
          <select class="form-control ml-auto" name="location_id" [(ngModel)]="model.location_id"
            #location_id="ngModel" (change)="filterDevicesByGroupLocation()">
            <option value="" [selected]="model.location_id == ''">Locations</option>
            @for (location of locations ; track location) {
              <option [ngValue]="location.location_id"
              [selected]="location.location_id === model.location_id">{{location.name}}</option>
            }
          </select>
        </div>
        <div class="col-md-3 col-sm-12 mb-1 pl-0">
          <select class="form-control ml-auto" name="device_id" [(ngModel)]="model.device_id"
            #device_id="ngModel" (change)="updateLog()">
            <option value="" [selected]="model.device_id == ''">Silos</option>
            @for (device of devices; track device) {
              <option [ngValue]="device.device_id"
              [selected]="device.device_id === model.device_id">{{device.device_name}}</option>
            }
          </select>
        </div>
        <div class="col-md-2 col-sm-12 mb-1 pl-0">
          <input type="submit" class="form-control btn btn-primary " name="Search" value="Search">
        </div>

      </div>
    </div>
  </div>
</form>


<div class="row">

  <table class="table table-striped tableListv table-responsive">
    <thead class="thead-dark">
      <tr>
        <th class="pointer" (click)="sortBy('gr.name')">
          Company
          @if (model.orderBy !== 'gr.name') {
            <i class="fa fa-sort" aria-hidden="true"></i>
          }
          @if (model.orderBy === 'gr.name' && model.orderDirection === 'DESC' ) {
            <i class="fa fa-sort-asc"
            aria-hidden="true"></i>
          }
          @if (model.orderBy === 'gr.name' && model.orderDirection === 'ASC' ) {
            <i class="fa fa-sort-desc"
            aria-hidden="true"></i>
          }
        </th>
        <th class="pointer" (click)="sortBy('l.name')">
          Location
          @if (model.orderBy !== 'l.name') {
            <i class="fa fa-sort" aria-hidden="true"></i>
          }
          @if (model.orderBy === 'l.name' && model.orderDirection === 'DESC' ) {
            <i class="fa fa-sort-asc"
            aria-hidden="true"></i>
          }
          @if (model.orderBy === 'l.name' && model.orderDirection === 'ASC' ) {
            <i class="fa fa-sort-desc"
            aria-hidden="true"></i>
          }
        </th>
        <th class="pointer" (click)="sortBy('g.name')">
          Gateway
          @if (model.orderBy !== 'g.name') {
            <i class="fa fa-sort" aria-hidden="true"></i>
          }
          @if (model.orderBy === 'g.name' && model.orderDirection === 'DESC' ) {
            <i class="fa fa-sort-asc"
            aria-hidden="true"></i>
          }
          @if (model.orderBy === 'g.name' && model.orderDirection === 'ASC' ) {
            <i class="fa fa-sort-desc"
            aria-hidden="true"></i>
          }
        </th>
        <th class="pointer" (click)="sortBy('d.name')">
          Silo
          @if (model.orderBy !== 'd.name') {
            <i class="fa fa-sort" aria-hidden="true"></i>
          }
          @if (model.orderBy === 'd.name' && model.orderDirection === 'DESC' ) {
            <i class="fa fa-sort-asc"
            aria-hidden="true"></i>
          }
          @if (model.orderBy === 'd.name' && model.orderDirection === 'ASC' ) {
            <i class="fa fa-sort-desc"
            aria-hidden="true"></i>
          }
        </th>
        <th class="pointer" (click)="sortBy('al.alarmtype')">
          Alarm Type
          @if (model.orderBy !== 'al.alarmtype') {
            <i class="fa fa-sort" aria-hidden="true"></i>
          }
          @if (model.orderBy === 'al.alarmtype' && model.orderDirection === 'DESC' ) {
            <i
            class="fa fa-sort-asc" aria-hidden="true"></i>
          }
          @if (model.orderBy === 'al.alarmtype' && model.orderDirection === 'ASC' ) {
            <i
            class="fa fa-sort-desc" aria-hidden="true"></i>
          }
        </th>
        <th class="pointer" (click)="sortBy('al.alarmvalue')">
          Alarm Value
          @if (model.orderBy !== 'al.alarmvalue') {
            <i class="fa fa-sort" aria-hidden="true"></i>
          }
          @if (model.orderBy === 'al.alarmvalue' && model.orderDirection === 'DESC' ) {
            <i
            class="fa fa-sort-asc" aria-hidden="true"></i>
          }
          @if (model.orderBy === 'al.alarmvalue' && model.orderDirection === 'ASC' ) {
            <i
            class="fa fa-sort-desc" aria-hidden="true"></i>
          }
        </th>
        <th class="pointer" (click)="sortBy('al.startdate')">
          Timestamp
          @if (model.orderBy !== 'al.startdate') {
            <i class="fa fa-sort" aria-hidden="true"></i>
          }
          @if (model.orderBy === 'al.startdate' && model.orderDirection === 'DESC' ) {
            <i
            class="fa fa-sort-asc" aria-hidden="true"></i>
          }
          @if (model.orderBy === 'al.startdate' && model.orderDirection === 'ASC' ) {
            <i
            class="fa fa-sort-desc" aria-hidden="true"></i>
          }
        </th>
        <th>UTC</th>
      </tr>
    </thead>
    <tbody>
      @for (alarm of alarms | async; track alarm) {
        <tr>
          <td>{{ alarm.companyname }}</td>
          <td>{{ alarm.locationname }}</td>
          <td>{{ alarm.gatewayname }}</td>
          <td>{{ alarm.devicename }}</td>
          <td class="text-capitalize">{{ alarm.alarmtype }}</td>
          <td>{{ alarm.alarmvalue | number : '1.2-2' }}</td>
          <td>{{ alarm.startdate | date:'yyyy-MM-dd HH:mm' : '' + alarm.timezone }}</td>
          <td>{{ alarm.startdate }}</td>
        </tr>
      }
    </tbody>
  </table>

  <div class="col-12">
    @if (loading == true) {
      <app-loading-spinner
      style="position: fixed;top:50%; left: 50%;z-index: 100;"></app-loading-spinner>
    }
    @if (totalPages === 0 && !loading && (model.group_id || model.location_id || model.device_id)) {
      <span>
        No Records found. Please update your search criteria.
      </span>
    }
    @if (!model.group_id && !model.location_id && !model.device_id) {
      <span>
        Please select filter in order to fetch alarm logs
      </span>
    }
  </div>

</div>

<div class="row">
  @if (totalPages > 1) {
    <nav aria-label="Page navigation col-10" class="mt-2">
      <ul class="pagination">
        <li class="page-item">
          Go to page&nbsp;
        </li>
        <li class="page-item">
          <a class="page-link" aria-label="Previous" (click)="goToPage( model.page - 1 )">
            <span aria-hidden="true">&laquo;</span>
            <span class="sr-only">Previous</span>
          </a>
        </li>
        <li class="page-item">
          <select class="form-control ml-auto mr-1" name="page" [(ngModel)]="model.page" #page="ngModel"
            (ngModelChange)="updatePage()">
            @for (d of arr(totalPages).fill(1); track d; let i = $index) {
              <option [ngValue]="i+1"
              [selected]="i+1 === model.page">{{i+1}}</option>
            }
          </select>
        </li>
        <li class="page-item">
          <a class="page-link" aria-label="Next" (click)="goToPage( model.page + 1 )">
            <span aria-hidden="true">&raquo;</span>
            <span class="sr-only">Next</span>
          </a>
          of {{totalPages}}
        </li>
      </ul>
    </nav>
  }

</div>