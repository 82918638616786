<div class="row">
  <div class="col-12">
    <h3>Companies</h3>
  </div>
  <div class="col-12">
    <div class="row m-1">
      <app-admin-nav></app-admin-nav>
      <button routerLink="/administration/group/edit/0" class="btn btn-primary py-0 mr-1"><i class="fa fa-plus"></i>Add
    Company</button>
    <input type="text" name="search" value="" class="col-12 col-sm-6 col-md-3 form-control" placeholder="keyword"
      (keyup)="filter('name,city,state',$event.target.value, false)">
    </div>
  </div>
  <div class="col-12">
    <table class="table table-striped tableList">
      <thead class="table-dark">
        <tr>
          <th (click)="sort('name')">
            <a [className]="(sortField | async) == 'name'? (sortDirection | async) : 'sort'">Company</a>
          </th>
          <th (click)="sort('city')">
            <a [className]="(sortField | async) == 'city'? (sortDirection | async) : 'sort'">city</a>
          </th>
          <th (click)="sort('state')">
            <a [className]="(sortField | async) == 'state'? (sortDirection | async) : 'sort'">State/Province</a>
          </th>
          <th>
            Status
          </th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        @for (group of rowItems; track group) {
          <tr>
            <td>{{ group.name }}</td>
            <td>{{ group.city }}</td>
            <td>{{ group.state }}</td>
            <td>{{ getStatusCode(group.status_cde) }}</td>
            <td nowrap>
              <div class="btn-group" role="group" aria-label="buttons">
                <button class="btn btn-info p-1 pointer" routerLink="/administration/group/edit/{{group.group_id}}"><i class="fa fa-pencil"></i>Edit</button>
                <!-- <button class="disabled btn btn-danger p-1"><i class="fa pointer fa-trash" alt="Delete" (click)="delete(group.group_id)"></i></button> -->
              </div>
            </td>
          </tr>
        }
      </tbody>
    </table>


    <div class="row">
      <div class="col-12">
        <app-pager [allItems]="groups | async" (pagedItemsNew)="setPagedItem($event)"></app-pager>
      </div>
    </div>

  </div>
</div>