<div class="btn-group d-flex flex-row justify-content-start m-1">
	<button routerLink="/administration/silos" class="btn btn-primary" ><i class="fa fa-arrow-left"></i>Back</button>
	<button routerLink="edit/{{device_id}}" type="button" class="btn btn-primary" routerLinkActive="active" >
	  Silo  
	</button>
	<button routerLink="rules/{{device_id}}" type="button" class="btn btn-primary" routerLinkActive="active" >
	  Alarms
	</button>
	<button routerLink="notifications/{{device_id}}" type="button" class="btn btn-primary" data-toggle="modal" routerLinkActive="active" >
	  Notifications
	</button>
</div>
<router-outlet></router-outlet>	
