<div class="row">
  <div class="col-12">
    <h3>Codes</h3>
  </div>
  <div class="row m-1">
    <app-admin-nav></app-admin-nav>
    <div>&nbsp;</div>
  </div>
  <div class="col-12">
    <table class="table table-striped tableList">
      <thead class="table-dark">
        <tr>
          <th>
            Type
          </th>
          <th>
            Code
          </th>
          <th>
            Decode
          </th>
          <th>
            Sort
          </th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        @for (code of codes | async; track code) {
          <tr >
            <td class="py-1">{{ code.type }}</td>
            <td class="py-1">{{ code.code }}</td>
            <td class="py-1">{{ code.decode }}</td>
            <td class="py-1">{{ code.sort_num }}</td>
            <td class="py-1" nowrap>
              <div class="btn-group" role="group" aria-label="buttons" [hidden]="!(userType == 'A' || userType == 'B')">
                <button class="btn btn-info p-1 pointer" routerLink="/administration/code/edit/{{code.id}}"><i class="fa  fa-pencil" ></i>Edit</button>
              </div>
            </td>
          </tr>
        }
      </tbody>
    </table>

    <div class="row">
      <div class="col-3">
        <select class="form-control ml-auto col-12 mt-2" name="recordsPerPage"  [(ngModel)]="model.recordsPerPage" #recordsPerPage="ngModel" (ngModelChange)="updateRecordPerPage()">
          <option [ngValue]="10" [selected]="10 === model.recordsPerPage">10</option>
          <option [ngValue]="20" [selected]="20 === model.recordsPerPage">20</option>
          <option [ngValue]="30" [selected]="30 === model.recordsPerPage">30</option>
          <option [ngValue]="40" [selected]="40 === model.recordsPerPage">40</option>
          <option [ngValue]="50" [selected]="50 === model.recordsPerPage">50</option>
        </select>
        Records Per Page
      </div>
      @if (model.totalPages > 1) {
        <nav aria-label="Page navigation col-9" class="mt-2">
          <ul class="pagination">
            @if (model.page > 1) {
              <li class="page-item">
                <a class="page-link"  aria-label="Previous" (click)="goToPage( model.page - 1 )">
                  <span aria-hidden="true">&laquo;</span>
                  <span class="sr-only">Previous</span>
                </a>
              </li>
            }
            @for (i of pageRange; track i) {
              <li class="page-item"  [class.active]=" i == model.page">
                <a class="page-link" (click)="goToPage( i )" >{{ i }}</a>
              </li>
            }
            @if (model.totalPages - model.page > 0) {
              <li class="page-item">
                <a class="page-link" aria-label="Next" (click)="goToPage( model.page + 1 )">
                  <span aria-hidden="true">&raquo;</span>
                  <span class="sr-only">Next</span>
                </a>
              </li>
            }
          </ul>
        </nav>
      }
    </div>
  </div>
</div>
