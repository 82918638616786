
<div class="card shadow h-100 m-1 card-pointer p-0 monitor" [ngClass]="[device.getAlertType()]"><!--  [class.alert-success]="device.getAlert() === 'Normal'" [class.alert-danger]="device.getAlert() !== 'Normal'"> -->
	<div class="card-header mb-0 p-1" data-toggle="tooltip" data-html="true" 
	title="{{ locationDeviceName }}"> 
		<span class="mb-0"><strong>{{ locationDeviceName | slice:0:36 }}{{ locationDeviceName.length > 36?'...':'' }} </strong></span>
	</div>
	<div class="card-block px-2 py-0 mb-0"> 
		<app-silo-img [device]="device"></app-silo-img>
	</div><!-- end card-block -->
</div><!-- end card -->
