import { Component, OnInit } from '@angular/core';
import { Observable, range } from 'rxjs';
import { Router } from '@angular/router';

import { CodesService } from '../../services/codes.service';
import { Code } from '../../model/code';
import { Permissions } from '../../model/permissions';

@Component({
  selector: 'app-codes',
  templateUrl: './codes.component.html',
  styleUrls: ['./codes.component.css']
})
export class CodesComponent implements OnInit {
	permissions:Permissions = new Permissions();
	userType = '';
	codes: Observable<Code[]>;
	displayPageCounter:Observable<number>;
	pageRange=[];

	model= {
			  id:''
			, type:''
			, code:''
			, decode:''
			, page: 1
			, recordsPerPage: 10
			, totalPages:0
	}
	private arr= Array;

	constructor(private codesService: CodesService, router: Router ) { 

		if(!this.permissions.hasCodesPermission()){
			router.navigateByUrl('/devices');
		}
		this.userType = this.permissions.userType.trim().toUpperCase();

	}

  ngOnInit() {  	
	this.getData();
  }

  goToPage(_page){
  	this.model.page = _page;
  	this.getData();
  }

  updateRecordPerPage(){
  	this.model.page = 1;
  	this.getData();
  }

  getData(){  	
  	this.codesService.getCodesForAdmin(this.model);
	this.codes = this.codesService.codes;
	this.codes.subscribe(x=>{
								x.map(a=>{
											this.model.totalPages = Math.ceil( a.total_rows / this.model.recordsPerPage );
										})
										
								let start = 1;
								let take = 6;
								
								if(this.model.totalPages - this.model.page < 2){
									start =  this.model.totalPages - 5;
								}else{
									start = this.model.page-3
								}

								if(start < 1){
									start = 1;
								}

								if(take > this.model.totalPages){
									take = this.model.totalPages;
								}

								this.displayPageCounter = range(start , take);
								
								this.pageRange.splice(0);
								this.displayPageCounter.subscribe(z=>this.pageRange.push(z));
							})
  }
}
