<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
<meta http-equiv="Pragma" content="no-cache" />
<meta http-equiv="Expires" content="0" />
<div class="row">
  <div class="col-12">
    <h3>My Profile</h3>
  </div>
  @if (message && message.length > 0) {
    <div class="col-12 alert text-center" [ngClass]="hasError ? 'alert-danger' : 'alert-success'">
      {{ message }}
    </div>
  }
</div>

<form [formGroup]="userFormss" (ngSubmit)="saveUser()" autocomplete="off">
  <input type="hidden" value="xxx" autocomplete="off" />
  <div class="form-group row mb-1">
    <label for="user_name" class="col-2 col-form-label p-1"><span class="required">*</span>Login Name</label>
    <div class="col-10">
      <input type="hidden" class="form-control p-1" name="hello" id="hello" value="i don't know" autocomplete="off" />
      <input type="text" class="form-control p-1" name="user_name" id="user_name" [formControl]="user_name" autocomplete="off" />
      <div [hidden]="user_name.valid || user_name.untouched" class="alert alert-danger p-1">
        <div [hidden]="!user_name.hasError('minlength')">Login Name can not be shorter than 3 characters.</div>
        <div [hidden]="!user_name.hasError('maxlength')">Login Name can not be logner than 20 characters.</div>
        <div [hidden]="!user_name.hasError('required')">Login Name is required.</div>
      </div>
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="password" class="col-2 col-form-label p-1">Password</label>
    <div class="col-10">
      <!-- <input type="password" class="form-control p-1" name="password" id="password" [formControl]="password" autocomplete="new_password" />	 -->
      <button
        class="form-controlz p-1 btn btn-danger"
        data-toggle="modal"
        data-target="#updatePassword"
        (click)="preventSubmit()"
        data-dismiss="modal"
        >
        Update Password
      </button>
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="name" class="col-2 col-form-label p-1">Name</label>
    <div class="col-10">
      <input type="text" class="form-control p-1" name="name" id="name" [formControl]="name" />
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="addr1" class="col-2 col-form-label p-1">Address</label>
    <div class="col-10">
      <input type="text" class="form-control p-1" name="addr1" id="addr1" [formControl]="addr1" />
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="addr2" class="col-2 col-form-label p-1">Address 2</label>
    <div class="col-10">
      <input type="text" class="form-control p-1" name="addr2" id="addr2" [formControl]="addr2" />
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="city" class="col-2 col-form-label p-1">City</label>
    <div class="col-10">
      <input type="text" class="form-control p-1" name="city" id="city" [formControl]="city" />
    </div>
  </div>

  <!-- <div class="form-group row mb-1">
  <label for="state" class="col-2 col-form-label p-1">State</label>
  <div class="col-10">
    <input type="text" class="form-control p-1" name="state" id="state" [formControl]="state" />
  </div>
</div> -->

<div class="form-group row mb-1">
  <label for="state_id" class="col-2 col-form-label p-1"><span class="required">*</span>State/Province</label>
  <div class="col-10">
    <ng-select
      #stateSelect
      [items]="states | async"
      bindLabel="state"
      bindValue="id"
      name="state_id"
      labelForId="state"
      placeholder="Select a state"
      [formControl]="state_id"
      >
    </ng-select>
    <div [hidden]="state_id.valid || state_id.untouched" class="alert alert-danger p-1">State/Province is required.</div>
  </div>
</div>

<div class="form-group row mb-1">
  <label for="zip" class="col-2 col-form-label p-1">Zip</label>
  <div class="col-10">
    <input type="text" class="form-control p-1" name="zip" id="zip" [formControl]="zip" />
  </div>
</div>

<!-- <div class="form-group row mb-1">
<label for="country" class="col-2 col-form-label p-1">Country</label>
<div class="col-10">
  <input type="text" class="form-control p-1" name="country" id="country" [formControl]="country" />
</div>
</div> -->
<div class="form-group row mb-1">
  <label for="country" class="col-2 col-form-label p-1"><span class="required">*</span>Country</label>
  <div class="col-10">
    <!-- <input class="form-control p-1" type="text" value="" id="country" name="country" [formControl]="country" /> -->
    <ng-select
      #countrySelect
      [items]="countries | async"
      bindLabel="country"
      bindValue="id"
      name="country_id"
      labelForId="country"
      placeholder="Select a country"
      [formControl]="country_id"
      >
    </ng-select>
    <div [hidden]="country_id.valid || country_id.untouched" class="alert alert-danger p-1">Country is required.</div>
  </div>
</div>

@if (showAddInfo) {
  <div class="form-group row mb-1">
    <label class="col-2 col-form-label p-1" for="Company">Company</label>
    <div class="col-10">
      <span class="badge badge-secondary mr-1 mt-1 py-2">{{ userFormss.value.company }}</span>
    </div>
  </div>
}

@if (showAddInfo) {
  <div class="form-group row mb-1">
    <label class="col-2 col-form-label p-1" for="location">Location</label>
    <div class="col-10">
      @for (l of userLocations$ | async; track l) {
        <span class="badge badge-secondary mr-1 mt-1 py-2">{{ l.name }}</span>
      }
    </div>
  </div>
}

@if (showAddInfo) {
  <div class="form-group row mb-1">
    <label class="col-2 col-form-label p-1" for="silo">Silo</label>
    <div class="col-10">
      @for (d of userDevices | async; track d) {
        <span class="badge badge-secondary mr-1 mt-1 py-2">{{ d.device_name }}</span>
      }
    </div>
  </div>
}

<div class="form-group row mb-1">
  <label for="phone" class="col-2 col-form-label p-1"><span class="required">*</span>Phone</label>
  <div class="col-10">
    <input text="tel" class="form-control p-1" name="phone" id="phone" [formControl]="phone" />
    <div [hidden]="phone.valid || phone.untouched" class="alert alert-danger p-1">phone number is required.</div>
  </div>
</div>

<div class="form-group row mb-1">
  <label for="email" class="col-2 col-form-label p-1"><span class="required">*</span>Email</label>
  <div class="col-10">
    <input type="email" class="form-control p-1" name="email" id="email" placeholder="user@example.com" [formControl]="email" />
    <div [hidden]="email.valid || email.untouched" class="alert alert-danger p-1">Valid email is required.</div>
  </div>
</div>

<!-- <div class="form-group row mb-1">
<label class="col-2 col-form-label p-1">Two Factor Authentication</label>
<div class="col-10">
  <input type="radio" class="p-1" value="" id="none" name="two_factor_auth_type" [formControl]="two_factor_auth_type" />
  <label for="sms" class="p-1 mr-4">Opt Out</label>
  <input type="radio" class="p-1" value="sms" id="sms" name="two_factor_auth_type" [formControl]="two_factor_auth_type" />
  <label for="sms" class="p-1 mr-4">Text Message</label>
  <input type="radio" class="p-1" value="email" id="_email" name="two_factor_auth_type" [formControl]="two_factor_auth_type" />
  <label for="_email" class="p-1">Email</label>
</div>
</div> -->

<div class="form-group row mb-1">
  <label class="col-2 col-form-label p-1">Default View</label>
  <div class="col-10">
    <input type="radio" class="p-1" value="list" id="list" name="default_view" [formControl]="default_view" />
    <label for="list" class="p-1 mr-4">List</label>
    <input type="radio" class="p-1" value="card" id="card" name="default_view" [formControl]="default_view" />
    <label for="card" class="p-1">Tile</label>
  </div>
</div>

<div class="form-group row mb-1">
  <label class="col-2 col-form-label p-1">&nbsp;</label>
  <div class="col">
    <!--  || disableBtn -->
    <button [disabled]="!userFormss.valid" class="form-controla p-1 btn btn-success">Update User</button>
    <a [routerLink]="'security'" class="btn btn-link">Set up two factor authentication</a>
  </div>
  <!-- <div class="col-2">
  <button class="btn btn-danger p-1" data-toggle="modal" data-target="#delDevice" >Delete</button>
</div> -->
@if (!userFormss.valid) {
  <div class="col-12 alert alert-danger">Note: Please fill out all the fields marked by *.</div>
}
</div>
</form>

<div class="modal fade" id="updatePassword" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      @if (message && message.length > 0) {
        <div
          class="modal-body alert text-center"
          [ngClass]="hasError ? 'alert-danger' : 'alert-success'"
          >
          {{ message }}
        </div>
      }
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{ user_name.value }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form
        [formGroup]="userPassword"
        (ngSubmit)="password.value === confirmpassword.value && savePassword(userPassword)"
        autocomplete="off"
        >
        <input type="hidden" class="form-control p-1" name="user_id" id="user_id" [formControl]="user_id" />
        <div class="modal-body">
          <div class="form-group row mb-1">
            <label for="password" class="col-2 col-form-label p-1"><span class="required">*</span>Password</label>
            <div class="col-10">
              <input
                type="password"
                class="form-control p-1"
                name="password"
                id="password"
                [formControl]="password"
                autocomplete="new_password"
                />
              </div>
            </div>
            <div class="form-group row mb-1">
              <label for="confirmpassword" class="col-2 col-form-label p-1"><span class="required">*</span>Confirm Password</label>
              <div class="col-10">
                <input
                  type="password"
                  class="form-control p-1"
                  name="confirmpassword"
                  id="confirmpassword"
                  [formControl]="confirmpassword"
                  autocomplete="new_password"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
              <button
                type="submit"
                class="btn btn-danger"
                [disabled]="password.invalid || password.value !== confirmpassword.value || userFormss.value.user_name === password.value"
                >
                Update
              </button>
            </div>
            <div class="modal-body">
              @if (password.valid && password.value !== confirmpassword.value && password.touched && confirmpassword.touched) {
                <div
                  class="col-12 alert alert-danger p-1"
                  >
                  The Password provided do not match.
                </div>
              }
              @if (password.invalid && password.touched) {
                <div class="col-12 alert alert-danger p-1">
                  <p>Password must have</p>
                  <ul>
                    <li>At least 8 characters</li>
                    <li>At least one number</li>
                    <li>At least one capital letter</li>
                    <li>At least one small letter</li>
                    <li>At least one special character (*!&#64;#$%^&)</li>
                  </ul>
                </div>
              }
              @if (password.valid && user_name.value === password.value) {
                <div class="col-12 alert alert-danger p-1">
                  Password should be different from Login Name.
                </div>
              }
            </div>
          </form>
        </div>
      </div>
    </div>
