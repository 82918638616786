import * as lkeys from 'lowercase-keys';
interface GatewayJson{
	gateway_id: 	number;
	location_id: 	number;
	name: 			string;
	location_name: 	string;
	lst_upd_id: 	string;
	lst_upd_dte: 	string;
	devid: 			string;
	idigi_device_id:string;
	mac: 			string;
	idigi_id: 		number;
	status_cde: 	string;
	type: 			string;
	group_id: 		number;
	group_name: 	string;
	total_rows: 	number;
	typeid: 		number;
	selected:		boolean;
}

export class Gateway implements GatewayJson {

	static fromJSON( jsonObj ): Gateway{
		let gateway = Object.create( Gateway.prototype );

		let obj = Object.assign( gateway, lkeys(jsonObj),{selected: false});
		return obj;
	}

	constructor(
			  	public gateway_id: 			number
				, public location_id: 		number
				, public name: 				string
				, public location_name:		string
				, public lst_upd_id: 		string
				, public lst_upd_dte: 		string
				, public devid: 			string
				, public idigi_device_id:	string
				, public mac: 				string
				, public idigi_id: 			number
				, public status_cde: 		string
				, public type: 				string
				, public group_id:			number
				, public group_name:		string
				, public typeid:			number
				, public total_rows:		number
				, public selected:			boolean
			){ 		
    }
   
}
