<!-- [class.hideAdminNav]="adminNav" -->
<!-- <div class="clearfix"></div>
<div class="row">
	<div class="col-4 list-group adminNav" [@hideAdminNav]='state' >
		<button (click)="toggleNav()" style="text-align: right;" class="btn btn-outline-default p-1"><i class="fa fa-arrow-left"></i></button>
		<a (click)="toggleNav()" [hidden]="!companiesPermission" 	routerLink="groups" 	class="list-group-item list-group-item-action inverted">Companies</a>
		<a (click)="toggleNav()" [hidden]="!usersPermission" 		routerLink="users" 		class="list-group-item list-group-item-action inverted">Users</a>
		<a (click)="toggleNav()" [hidden]="!locationsPermission" 	routerLink="locations" 	class="list-group-item list-group-item-action inverted">Locations</a>
		<a (click)="toggleNav()" [hidden]="!gatewaysPermission" 	routerLink="gateways" 	class="list-group-item list-group-item-action inverted">Gateways</a>
		<a (click)="toggleNav()" [hidden]="!silosPermission" 		routerLink="silos" 		class="list-group-item list-group-item-action inverted">Silos</a>
		<a (click)="toggleNav()" [hidden]="!codesPermission" 		routerLink="codes" 		class="list-group-item list-group-item-action inverted">Codes</a>
	</div>
	<div class="container" (click)="closeNav()">
		<button (click)="toggleNav()" class="btn btn-primary admin-btn" *ngIf="adminPermission"><i class="fa fa-list-ul"></i></button>
		<router-outlet></router-outlet>	
	</div>
</div> -->
<div class="container">
	<router-outlet></router-outlet>	
</div>