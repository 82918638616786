import { Component, OnInit, ElementRef, EventEmitter } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { NgOption } from '@ng-select/ng-select';
import { DatePipe } from '@angular/common';

import { LocationsService } from '../../services/locations.service';
import { GroupsService } from '../../services/groups.service';
import { DevicesService } from '../../services/devices.service';
import { ChannelsService } from '../../services/channels.service';
import { UsersService } from '../../services/users.service';

import { Permissions } from '../../model/permissions';

import { environment } from '../../../environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-channels',
  templateUrl: './channels.component.html',
  styleUrls: ['./channels.component.css'],
  providers: [DatePipe],
})
export class ChannelsComponent implements OnInit {
  public users: NgOption[] = [];
  public hasError: boolean;
  public message: string;
  public permissions = new Permissions();
  public locations: NgOption[] = [];
  public devices: NgOption[] = [];
  public reports: NgOption[] = [];
  public groups: NgOption[] = [];
  public files: any = [];
  // public linkUrl: string = environment.apiurl + "/tmp/";
  public linkUrl: string = 'https://s3.amazonaws.com/monitortech/';
  public materials: NgOption[] = [];
  public date_range: NgOption[] = [
    { name: 'Last 24 hrs', value: 24 * 60 * 60 * 1000 },
    { name: 'Last 7 days', value: 7 * 24 * 60 * 60 * 1000 },
    { name: 'Last 30 days', value: 30 * 24 * 60 * 60 * 1000 },
    { name: 'Last 60 days', value: 60 * 24 * 60 * 60 * 1000 },
    { name: 'Last 90 days', value: 90 * 24 * 60 * 60 * 1000 },
  ];
  customRange = true;
  loading = false;

  typeahead = new EventEmitter<string>();

  selectedLocations: any = [];
  filteredDevices: any = [];
  selectedDevices: any = [];
  devices$: any;
  isEmpty: boolean = false;
  columns: NgOption[] = [
    { name: 'Company', value: 'company' },
    { name: 'Location', value: 'location' },
    { name: 'Content', value: 'content' },
    { name: 'Silo Name', value: 'name' },
    { name: 'Time/Date Stamp', value: 'date' },
    { name: 'Distance', value: 'distance' },
    { name: 'Distance Unit Of Measurement', value: 'distance_uom' },
    { name: 'Level', value: 'level' },
    { name: 'Level Unit Of Measurement', value: 'level_uom' },
    { name: 'Volume', value: 'Volume' },
    { name: 'Volume ullage', value: 'Volume_ullage' },
    { name: 'Max Volume', value: 'max_Volume' },
    { name: 'Volume Unit Of Measurement', value: 'Volume_uom' },
    { name: 'Weight', value: 'Weight' },
    { name: 'Weight ullage', value: 'Weight_ullage' },
    { name: 'Max Weight', value: 'max_Weight' },
    { name: 'Weight Unit Of Measurement', value: 'Weight_uom' },
    { name: 'Percent', value: 'percent' },
    { name: 'Percent ullage', value: 'Percent_ullage' },
  ];

  model: any = {
    date_from: '',
    date_to: '',
    group_id: '',
    location_id: [],
    device_id: [],
    contents: '',
    report_type: 'vessel',
    report_column: '',
    column_header: [],
    date_range: '',
    reports: [],
    report_name: '',
    report_id: '',
    schedule_report: true,
    report_frequency: '',
    report_day: '',
    report_time: '',
    timezone: '',
    user_id: [],
  };

  reportCriteria: Observable<any>;

  // const locale:string = 'en';
  public hours = [];
  public tz;
  public allowEditReport = false;
  validDateRange = true;
  today = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  threeMonthsAfterfromDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  minDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  ninetyDays = 90 * 24 * 60 * 60 * 1000;

  constructor(
    private locationsService: LocationsService,
    private devicesService: DevicesService,
    private channelsService: ChannelsService,
    private groupsService: GroupsService,
    private usersService: UsersService,
    private datePipe: DatePipe
  ) {

    for (let hour = 0; hour < 24; hour++) {
      this.hours.push(moment({ hour }).format('HH:mm'));
      let x = new Date();
      this.tz = moment(x).format('Z');
      this.tz = parseInt(this.tz.replace(/\D-/g, ''));
    }

    this.allowEditReport = ['A', 'B', 'D'].filter((type) => type === this.permissions.userType).length > 0;
    // this.today = new Date().getFullYear() + '-0' +  new Date().getMonth() + '-' +  new Date().getDate() ;
  }

  ngOnInit() {
    this.changeReportSelection(null);

    this.groupsService.getGroups();
    this.groupsService.groups.subscribe((x) => {
      this.groups = x;
      // Manager, Technician
      if(this.permissions.hasManagerOrTechnicianRole() && this.groups.length) {
        this.model.group_id = this.groups[0].group_id;
        this.getLocationByGroupID();
      }
      
    });

    this.usersService.getUsers({ status_cde: 'A' }).subscribe((x) => (this.users = x));

    this.reportCriteria = this.channelsService.getReportCriteria();
    
    this.reportCriteria.subscribe((rep) => {
      this.reports = rep;
      if(rep.length) {
        this.changeReportSelection(this.reports[0]);
        this.selectedReport(this.reports[0]);
      } 
    });
    // this.diff = new Date().getTime() - 0; // new Date(this.model.date_to).getTime() || 0;
  }

  getLocationByGroupID() {
    this.model.location_id = [];
    this.model.contents = [];
    this.model.device_id = [];

    this.locationsService.getLocationByGroupId(this.model.group_id).subscribe((x) => {
      this.locations = x;
      if(this.permissions.hasManagerOrTechnicianRole() && this.locations.length) {
        console.log("Location Id", this.locations[0].location_id);
        this.model.location_id = [this.locations[0].location_id];

        this.getDevicesByLocation();
      }
    });

    this.devicesService.getDevicesAdminByLocationId(null, this.model.group_id).subscribe((x) => {
      this.devices = x;
    });
  }

  getDevicesByLocation() {
    this.model.contents = [];
    this.model.device_id = [];
    this.model.queryStringDevice = '';
    this.devicesService.getDevicesAdminByLocationId(this.model.location_id).subscribe((x) => {
      this.devices = x;
      let _materials = [];
      x.filter((d) => {
        return _materials.indexOf({ MATERIAL: d.material }) == -1;
      }).map((m) => {
        if (_materials.findIndex((a) => a.MATERIAL === m.material) === -1) {
          _materials.push({ MATERIAL: m.material });
          this.materials = _materials;
        }
      });
    });
  }

  updateReportSelection(type) {
    switch (type) {
      case 'vessel':
        break;
      case 'location':
        this.model.device_id = [];
        this.model.date_from = '';
        this.model.date_to = '';
        this.model.date_range = '';
        break;
      case 'company':
        this.model.device_id = [];
        this.model.location_id = [];
        this.model.date_from = '';
        this.model.date_to = '';
        this.model.date_range = '';
        break;
        
      case 'single_location':
        this.model.device_id = [];
        this.model.location_id = [];
        this.model.date_from = '';
        this.model.date_to = '';
        this.model.date_range = '';
        
      case 'single_company':
        this.model.device_id = [];
        this.model.location_id = [];
        this.model.date_from = '';
        this.model.date_to = '';
        this.model.date_range = '';

      default:
        // code...
        break;
    }
  }

  deviceByContent() {
    this.devicesService.getDevicesAdminByLocationId(this.model.location_id, this.model.group_id, this.model.contents).subscribe((x) => {
      this.devices = x;
    });
  }

  showCustomRange(val) {
    this.customRange = val ? false : true;

    if (this.customRange === false) {
      this.model.date_from = '';
      this.model.date_to = '';
    }
  }

  changeReportSelection(rep) {
    this.model.report_id = '';
    this.model.report_name = '';
    if (!rep) {
      this.model = {
        date_from: '',
        date_to: '',
        group_id: '',
        location_id: [],
        device_id: [],
        contents: '',
        report_type: 'vessel',
        report_column: '',
        column_header: [],
        date_range: '',
        reports: [],
        report_name: '',
        report_id: '',
        schedule_report: true,
        report_frequency: '',
        report_day: '',
        report_time: '',
        timezone: this.tz,
        user_id: [],
      };
      this.showCustomRange(null);
      return;
    }

    this.model.report_id = rep.id;
    let _savedReport = JSON.parse(rep.criteria);
    this.model.report = rep.criteria;
    this.model.report_type = _savedReport.report_type;
    this.updateReportSelection(this.model.report_type);

    this.model.column_header = _savedReport.column_header;
    this.model.group_id = _savedReport.group_id;
    this.model.user_id = _savedReport.user_id;

    this.locationsService.getLocationByGroupId(this.model.group_id).subscribe((l) => {
      this.locations = l;
      this.model.location_id = _savedReport.location_id;
      this.devicesService.getDevicesAdminByLocationId(this.model.location_id).subscribe((d) => {
        this.devices = d;
        this.model.device_id = _savedReport.device_id;
        this.getDevicesByLocation();
        this.model.contents = _savedReport.contents;
        this.model.device_id = _savedReport.device_id;
      });
    });

    this.model.report_name = rep.name;
    (this.model.report_frequency = rep.report_frequency),
      (this.model.report_day = rep.report_day),
      (this.model.report_time = rep.report_time),
      (this.model.date_from = _savedReport.date_from);
    this.model.date_to = _savedReport.date_to;
    this.model.date_range = !isNaN(parseInt(_savedReport.date_range)) ? _savedReport.date_range : null;
    this.showCustomRange(this.model.date_range);
  }

  isReportNew() {
    return this.model.report ? true : false;
  }

  generateReport(ff) {
    this.loading = true;
    let locations = [];
    let devices = [];
    this.files = [];
    this.message = '';
    this.hasError = false;

    let d = new Date();
    let df = d.getTimezoneOffset() * 1000;

    // When we allow user to select only one location then convert the data type to array
    if(!Array.isArray(this.model.location_id) && this.model.location_id) {
      this.model.location_id = [this.model.location_id];
    }
    
    this.model.locations = this.model.location_id.toString();
    this.model.devices = this.model.device_id.toString();
    this.model.materials = this.model.contents.toString();
    this.model.columnList = this.model.column_header.toString();

    let from = new Date(this.model.date_from);
    let f = from.getTime();
    this.model.fromDateTime = f;

    let end = new Date(this.model.date_to);
    let e = end.getTime() + 24 * 60 * 60 * 1000;
    this.model.endDateTime = e;

    console.log('this.model', this.model);
    this.channelsService.generateReport(this.model);
    this.channelsService.hasError.subscribe((x) => {
      this.hasError = x;
      this.loading = false;
    });
    this.channelsService.message.subscribe((x) => (this.message = x));
    this.channelsService.files.subscribe((x) => {
      this.files = x;
    });
  }

  saveReport() {
    this.loading = true;
    this.message = '';
    this.hasError = false;

    let criteria = Object.assign({}, this.model);
    delete criteria.report;
    delete criteria.reports;

    this.channelsService.saveReport(criteria);
    let sub$ = this.channelsService.hasError.subscribe((x) => {
      this.hasError = x;
      this.loading = false;
      if (this.hasError === false) {
        this.reportCriteria.subscribe((rep) => {
          this.reports = rep;
          rep
            .filter((x) => x.id === this.model.report_id)
            .map((r) => {
              this.selectedReport(r);
              // this.changeReportSelection(r);
            });
        });

        this.channelsService.report_id.subscribe((id) => {
          this.model.report_id = id;
        });

        // this.generateReport(this.model);
      }
      sub$.unsubscribe();
    });
    this.channelsService.message.subscribe((x) => (this.message = x));
    return false;
  }

  deleteReport(rep) {
    let body = { id: rep.id };
    this.channelsService.deleteReport(body);
    let sub$ = this.channelsService.hasError.subscribe((x) => {
      this.hasError = x;
      this.loading = false;
      this.changeReportSelection(null);

      this.reportCriteria = this.channelsService.getReportCriteria();
      this.reportCriteria.subscribe((rep) => {
        this.reports = rep;
      });
      sub$.unsubscribe();
    });
    return false;
  }

  prepareNewCriteria() {
    this.changeReportSelection(null);
    this.selectedReport(null);
  }

  selectedReport(rep) {
    this.reports.forEach((x) => {
      x.active = false;
      if (rep && rep.id == x.id) {
        x.active = true;
        this.changeReportSelection(x);
      }
    });
  }

  clearDayHour() {
    if (this.model.report_frequency.trim().toLowerCase() === 'weekly') {
      this.model.report_time = '00:00';
      this.model.report_day = '1';
    } else if (this.model.report_frequency.trim().toLowerCase() === 'daily') {
      this.model.report_time = '00:00';
      this.model.report_day = '';
    } else {
      this.model.report_time = '';
      this.model.report_day = '';
    }
  }
  resetSchedule() {
    this.model.report_frequency = '';
    this.clearDayHour();
  }

  btnText() {
    if (this.model.schedule_report === true && ['E', 'F'].filter((type) => this.permissions.userType === type).length === 0) {
      return 'Save and Schedule Report';
    } else {
      return 'Save Report';
    }
  }

  hrPM_AM(hr) {
    let hhr = moment(hr, 'hh:mm');
    return hhr.format('hh:mm A');
  }

  validateDateRange() {
    const { date_from, date_to } = this.model;
    let dayDiff = 0;
    let to = new Date();
    let from = new Date();
    if(date_to) to = new Date(date_to);
    if(date_from) from = new Date(date_from);
    const today = new Date().getTime();

    const threeMon =  today > from.getTime() + this.ninetyDays ? from.getTime() + this.ninetyDays : today;
    this.threeMonthsAfterfromDate = this.datePipe.transform(new Date(threeMon), 'yyyy-MM-dd');

    dayDiff = (to.getTime() - from.getTime()) / (24 * 60 * 60 * 1000);
    this.validDateRange = dayDiff >= 0 && dayDiff < 91;
    if (!this.validDateRange) this.model.date_to = '';
  }
}
