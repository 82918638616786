import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {Router, ActivatedRoute, Params}  from '@angular/router';

@Component({
  selector: 'app-device-edit-nav',
  templateUrl: './device-edit-nav.component.html',
  styleUrls: ['./device-edit-nav.component.css']
})
export class DeviceEditNavComponent implements OnInit {
	device_id:number;
	constructor( private activatedRoute: ActivatedRoute ) { }

	ngOnInit() {
		this.activatedRoute.params.subscribe((params: Params) => {
		    this.device_id = params['id'];
		});
	}

	}
