import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormControl, ReactiveFormsModule, FormArray } from '@angular/forms';
import { Observable } from 'rxjs';

import { GroupsService } from '../../services/groups.service';
import { CountriesService } from '../../services/countries.service';

import { Permissions } from '../../model/permissions';

import { StatesService } from '../../services/states.service';
import { State } from '../../model/state';
import { Country } from '../../model/country';

import { NgSelectModule, NgOption } from '@ng-select/ng-select';

@Component({
	selector: 'app-group-edit',
	templateUrl: './group-edit.component.html',
	styleUrls: ['./group-edit.component.css'],
})
export class GroupEditComponent implements OnInit {
	permissions: Permissions = new Permissions();
	public hasError: boolean;
	public message: string;
	public states: Observable<State[]>;
	public countries: Observable<Country[]>;

	group_id = new UntypedFormControl('');
	name = new UntypedFormControl('', [Validators.required, Validators.maxLength(35)]);
	city = new UntypedFormControl('', [Validators.required]);
	state_id = new UntypedFormControl('', [Validators.required]);
	// phone			= new FormControl('', [ Validators.required, Validators.pattern( /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/ ) ]);
	phone = new UntypedFormControl('', [Validators.required, Validators.maxLength(25)]);
	status_cde = new UntypedFormControl('A');
	addr1 = new UntypedFormControl('', [Validators.required]);
	addr2 = new UntypedFormControl('');
	zip = new UntypedFormControl('', [Validators.required]);
	country_id = new UntypedFormControl('', [Validators.required]);
	customer_number = new UntypedFormControl('', [Validators.required]);
	bill_to_key = new UntypedFormControl('', [Validators.required]);
	is_2fa_required = new UntypedFormControl(0);

	groupForm: UntypedFormGroup = this.builder.group({
		group_id: this.group_id,
		name: this.name,
		city: this.city,
		state_id: this.state_id,
		phone: this.phone,
		status_cde: this.status_cde,
		addr1: this.addr1,
		addr2: this.addr2,
		zip: this.zip,
		country_id: this.country_id,
		customer_number: this.customer_number,
		bill_to_key: this.bill_to_key,
		is_2fa_required: this.is_2fa_required,
	});

	constructor(
		private activatedRoute: ActivatedRoute,
		private groupsService: GroupsService,
		private statesService: StatesService,
		private countriesService: CountriesService,
		private builder: UntypedFormBuilder,
		private router: Router
	) {}

	ngOnInit() {
		if (!this.permissions.hasCompaniesPermission()) {
			this.router.navigateByUrl('devices');
		}
		let groupId;

		this.states = this.statesService.getStates();
		this.countries = this.countriesService.getCountries();

		this.activatedRoute.params.subscribe((params: Params) => {
			groupId = params['id'];
			this.groupsService.getGroups(groupId);
		});

		this.groupsService.groups.subscribe((grs) =>
			grs
				.filter((gr) => gr.group_id == groupId)
				.map((g) => {
					this.group_id = new UntypedFormControl(g.group_id);
					this.name = new UntypedFormControl(g.name, [Validators.required, Validators.maxLength(35)]);
					this.city = new UntypedFormControl(g.city, [Validators.required]);
					this.state_id = new UntypedFormControl(g.state_id, [Validators.required]);
					this.phone = new UntypedFormControl(g.phone, [Validators.required, Validators.maxLength(25)]);
					this.status_cde = new UntypedFormControl(g.status_cde.trim().toUpperCase());
					this.addr1 = new UntypedFormControl(g.addr1, [Validators.required]);
					this.addr2 = new UntypedFormControl(g.addr2);
					this.zip = new UntypedFormControl(g.zip, [Validators.required]);
					this.country_id = new UntypedFormControl(g.country_id, [Validators.required]);
					this.customer_number = new UntypedFormControl(g.customer_number, [Validators.required]);
					this.bill_to_key = new UntypedFormControl(g.bill_to_key, [Validators.required]);
					this.is_2fa_required = new UntypedFormControl(g.is_2fa_required);

					this.groupForm = this.builder.group({
						group_id: this.group_id,
						name: this.name,
						city: this.city,
						state_id: this.state_id,
						phone: this.phone,
						status_cde: this.status_cde,
						addr1: this.addr1,
						addr2: this.addr2,
						zip: this.zip,
						country_id: this.country_id,
						customer_number: this.customer_number,
						bill_to_key: this.bill_to_key,
						is_2fa_required: this.is_2fa_required,
					});
				})
		);
	}

	submitGroup() {
		this.hasError = false;
		this.message = '';
		this.groupsService.setGroup(this.groupForm.value);
		this.groupsService.hasError.subscribe((x) => (this.hasError = x));
		this.groupsService.message.subscribe((x) => (this.message = x));
		this.groupsService.group_id.subscribe((x) => {
			this.groupForm.controls['group_id'].setValue(x);
		});
	}

	fakeDelete(e?: any) {
		return false;
	}

	deleteGroup(id) {
		let body = { group_id: id };
		this.groupsService.deleteGroup(body);
		return false;
	}
}
