import { BrowserModule } from "@angular/platform-browser";
import { NgModule, ApplicationRef } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app.routes";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { ChartModule } from "angular-highcharts";
// import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";

export declare let require: any;

import { AuthService } from "./auth.service";
import { AuthGuardService } from "./auth-guard.service";
import { TwoFAGaurdService } from "./services/two-fa-gaurd.service";
import { DevicesService } from "./services/devices.service";
import { LocationsService } from "./services/locations.service";
import { GroupsService } from "./services/groups.service";
import { GatewaysService } from "./services/gateways.service";
import { CodesService } from "./services/codes.service";
import { RulesService } from "./services/rules.service";
import { UtilService } from "./services/util.service";
import { StrappingsService } from "./services/strappings.service";
import { UsersService } from "./services/users.service";
import { ChannelsService } from "./services/channels.service";
import { AlarmlogService } from "./services/alarmlog.service";
import { StatesService } from "./services/states.service";
import { CountriesService } from "./services/countries.service";

import { AppComponent } from "./app.component";
import { HeaderNavComponent } from "./header-nav/header-nav.component";
import { DeviceListComponent } from "./device-list/device-list.component";

import { DeviceComponent } from "./device/device.component";
import { HighchartExampleComponent } from "./highchart-example/highchart-example.component";
import { LoginComponent } from "./login/login.component";
import { SiloSummaryComponent } from "./silo-summary/silo-summary.component";
import { SiloImgComponent } from "./silo-img/silo-img.component";
import { AlarmsComponent } from "./alarms/alarms.component";
import { SiloDetailComponent } from "./silo-detail/silo-detail.component";
import { TableViewComponent } from "./device-list/table-view/table-view.component";
import { TableViewSummaryComponent } from "./device-list/table-view-summary/table-view-summary.component";

// import { NguiDatetimePickerModule } from '@ngui/datetime-picker';
import { LoadingSpinnerComponent } from "./loading-spinner/loading-spinner.component";
import { GroupsComponent } from "./admin/groups/groups.component";
import { LocationsComponent } from "./admin/locations/locations.component";
import { AdminComponent } from "./admin/admin.component";
import { LocationEditComponent } from "./admin/location-edit/location-edit.component";
import { GroupEditComponent } from "./admin/group-edit/group-edit.component";
import { GatewaysComponent } from "./admin/gateways/gateways.component";
import { GatewayEditComponent } from "./admin/gateway-edit/gateway-edit.component";
import { DevicesComponent } from "./admin/devices/devices.component";
import { DeviceEditComponent } from "./admin/device-edit/device-edit.component";
import { RulesEditComponent } from "./admin/rules-edit/rules-edit.component";
import { FormExComponent } from "./admin/form-ex/form-ex.component";
import { UsersComponent } from "./admin/users/users.component";
import { UserEditComponent } from "./admin/user-edit/user-edit.component";

import { Pipe, PipeTransform } from "@angular/core";
import { FilterPipe } from "./pipe";
import { PasswordRecoveryComponent } from "./password-recovery/password-recovery.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { UserProfileComponent } from "./admin/user-profile/user-profile.component";
import { CustomerServiceComponent } from "./customer-service/customer-service.component";
import { DeviceEditNavComponent } from "./admin/device-edit-nav/device-edit-nav.component";
import { RulesNotificationComponent } from "./admin/rules-notification/rules-notification.component";
import { ReportsComponent } from "./reports/reports.component";
import { ChannelsComponent } from "./reports/channels/channels.component";
import { AlarmlogComponent } from "./reports/alarmlog/alarmlog.component";
import { CodesComponent } from "./admin/codes/codes.component";
import { CodeEditComponent } from "./admin/code-edit/code-edit.component";
import { AdminNavComponent } from "./admin/admin-nav/admin-nav.component";
import { ClickOutside } from "./click-outside.directive";

import { NgSelectModule, NgOption } from "@ng-select/ng-select";

import { NgxsModule } from "@ngxs/store";
import { NgxsLoggerPluginModule } from "@ngxs/logger-plugin";

import { GroupState } from "./store/states/groups.state";
import { LocationState } from "./store/states/locations.state";
import { DeviceState } from "./store/states/devices.state";
import { UserState } from "./store/states/users.state";
import { GatewayState } from "./store/states/gateways.state";
import { PagerService } from "./services/pager.service";
import { PagerComponent } from "./pager/pager.component";
import { HelpComponent } from "./help/help.component";
import { CookieService } from "ngx-cookie-service";
import { UsersReportComponent } from "./reports/users-report/users-report.component";
import { EmailNotificationComponent } from "./admin/email-notification/email-notification.component";
import { VerifyComponent } from "./verify/verify.component";
import { UserProfileSecurityComponent } from "./admin/user-profile-security/user-profile-security.component";

import {
  NgbDatepickerModule,
  NgbPopoverModule,
  NgbTimepickerModule,
} from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  declarations: [
    AppComponent,
    HeaderNavComponent,
    DeviceListComponent,
    DeviceComponent,
    HighchartExampleComponent,
    LoginComponent,
    SiloSummaryComponent,
    // SiloFilterComponent,
    SiloImgComponent,
    AlarmsComponent,
    SiloDetailComponent,
    TableViewComponent,
    TableViewSummaryComponent,
    LoadingSpinnerComponent,
    GroupsComponent,
    LocationsComponent,
    AdminComponent,
    LocationEditComponent,
    GroupEditComponent,
    GatewaysComponent,
    GatewayEditComponent,
    DevicesComponent,
    DeviceEditComponent,
    RulesEditComponent,
    FormExComponent,
    UsersComponent,
    UserEditComponent,
    FilterPipe,
    PasswordRecoveryComponent,
    ResetPasswordComponent,
    UserProfileComponent,
    CustomerServiceComponent,
    DeviceEditNavComponent,
    RulesNotificationComponent,
    ReportsComponent,
    ChannelsComponent,
    AlarmlogComponent,
    CodesComponent,
    CodeEditComponent,
    AdminNavComponent,
    ClickOutside,
    PagerComponent,
    HelpComponent,
    UsersReportComponent,
    EmailNotificationComponent,
    VerifyComponent,
    UserProfileSecurityComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    CommonModule,
    // NguiDatetimePickerModule,
    NgSelectModule,
    ChartModule,
    BrowserAnimationsModule,
    NgxsModule.forRoot([
      GroupState,
      LocationState,
      DeviceState,
      UserState,
      GatewayState,
    ]),
    NgxsLoggerPluginModule.forRoot(),
    // NgxsReduxDevtoolsPluginModule.forRoot()
    CKEditorModule,
    NgbPopoverModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
  ],
  providers: [
    UtilService,
    AuthService,
    AuthGuardService,
    DevicesService,
    LocationsService,
    GroupsService,
    GatewaysService,
    CodesService,
    RulesService,
    StrappingsService,
    UsersService,
    ChannelsService,
    AlarmlogService,
    PagerService,
    StatesService,
    CountriesService,
    CookieService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  // return [stock, more];
}

export function highchartsFactory() {
  const hc = require("highcharts/highstock");
  const dd = require("highcharts/modules/exporting");
  dd(hc);
  return hc;
}
