export class Codes {
	public codes:any;

	constructor() {
		try{
			this.codes = JSON.parse(localStorage.getItem("codes")) ;
		}catch(e){
			this.codes = [];
		}
	}

	public deCode(type, cde){
	  	let code:any = null;
	  	this.codes.filter(x=> x.TYPE.toLowerCase() === type && x.CODE.toLowerCase() === cde.toLowerCase()).map(x=>code = x);	  	
	  	return code && code.DECODE ? code.DECODE : cde;	  	
	  }
}
