<div class="px-1" [ngClass]="displayClass">
  <fieldset class="form-group">
    <legend>Alarm HH</legend>
    <div
      class="col-12 alert mt-0 mb-1 p-1"
      [class.alert-info]="device.getAlert() == 'Very High'"
      [class.alert-warning]="device.getAlert() !== 'Very High'"
      >
      <p class="mb-1">Set-Point: {{ device.hh_raw | number: '1.1-2' }} {{ getLabel(device.hh_threshold) }}</p>
      <p class="mb-1">Note: {{ device.msg_hh }}</p>
    </div>
  </fieldset>
</div>
<div class="px-1" [ngClass]="displayClass">
  <fieldset class="form-group">
    <legend>Alarm H</legend>
    <div
      class="col-12 alert mt-0 mb-1 p-1"
      [class.alert-info]="device.getAlert() == 'High'"
      [class.alert-warning]="device.getAlert() !== 'High'"
      >
      <p class="mb-1">Set-Point: {{ device.h_raw | number: '1.1-2' }} {{ getLabel(device.h_threshold) }}</p>
      <p class="mb-1">Note: {{ device.msg_h }}</p>
    </div>
  </fieldset>
</div>
<div class="px-1" [ngClass]="displayClass">
  <fieldset class="form-group">
    <legend>Alarm L</legend>
    <div
      class="col-12 alert mt-0 mb-1 p-1"
      [class.alert-info]="device.getAlert() == 'Low'"
      [class.alert-warning]="device.getAlert() !== 'Low'"
      >
      <p class="mb-1">Set-Point: {{ device.l_raw | number: '1.1-2' }} {{ getLabel(device.l_threshold) }}</p>
      <p class="mb-1">Note: {{ device.msg_l }}</p>
    </div>
  </fieldset>
</div>
<div class="px-1" [ngClass]="displayClass">
  <fieldset class="form-group">
    <legend>Alarm LL</legend>
    <div
      class="col-12 alert mt-0 mb-1 p-1"
      [class.alert-info]="device.getAlert() == 'Very Low'"
      [class.alert-warning]="device.getAlert() !== 'Very Low'"
      >
      <p class="mb-1">Set-Point: {{ device.ll_raw | number: '1.1-2' }} {{ getLabel(device.ll_threshold) }}</p>
      <p class="mb-1">Note: {{ device.msg_ll }}</p>
    </div>
  </fieldset>
</div>

@if (device.device_type_code.toLowerCase() !== 'bm100') {
  <div class="px-1" [ngClass]="displayClass">
    <fieldset class="form-group">
      <legend>System Alarm Status</legend>
      <div class="col-12 alert mt-0 mb-1 p-1" [ngClass]="device.getStatus() !== 'A-OK' ? 'alert-info' : 'alert-warning'">
        <p class="mb-1">Status: {{ device.getStatus() }}&nbsp;</p>
        <p class="mb-1">Delay (Minutes): {{ device.status_delay }}</p>
      </div>
    </fieldset>
  </div>
}
