<button class="navbar-toggler navbar-light" type="button" (click)="hiddenNav = !hiddenNav">
  <!-- <span class="navbar-toggler-icon"></span> -->
  <i class="fa fa-bars" aria-hidden="true"></i>
</button>

<div class="row" style="position: relative" [hidden]="hiddenNav">
  <!-- [@hideAdminNav]='state'  -->
  <div class="list-group px-1 menu">
    @if (companiesPermission) {
      <a
        href="javascript:void(0)"
        (click)="hiddenNav = true"
        routerLink="/administration/groups"
        class="list-group-item list-group-item-action inverted"
        >Companies</a
        >
      }
      @if (locationsPermission) {
        <a
          href="javascript:void(0)"
          (click)="hiddenNav = true"
          routerLink="/administration/locations"
          class="list-group-item list-group-item-action inverted"
          >Locations</a
          >
        }
        @if (usersPermission) {
          <a
            href="javascript:void(0)"
            (click)="hiddenNav = true"
            routerLink="/administration/users"
            class="list-group-item list-group-item-action inverted"
            >Users</a
            >
          }
          @if (gatewaysPermission) {
            <a
              href="javascript:void(0)"
              (click)="hiddenNav = true"
              routerLink="/administration/gateways"
              class="list-group-item list-group-item-action inverted"
              >Gateways</a
              >
            }
            @if (silosPermission) {
              <a
                href="javascript:void(0)"
                (click)="hiddenNav = true"
                routerLink="/administration/silos"
                class="list-group-item list-group-item-action inverted"
                >Silos</a
                >
              }
              @if (codesPermission) {
                <a
                  href="javascript:void(0)"
                  (click)="hiddenNav = true"
                  routerLink="/administration/codes"
                  class="list-group-item list-group-item-action inverted"
                  >Codes</a
                  >
                }
                @if (companiesPermission) {
                  <a
                    href="javascript:void(0)"
                    (click)="hiddenNav = true"
                    routerLink="/administration/notification"
                    class="list-group-item list-group-item-action inverted"
                    >Notification</a
                    >
                  }
                </div>
              </div>
