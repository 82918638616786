import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormsModule, FormGroup, FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';

import { map, } from 'rxjs/operators';
import { of, } from 'rxjs';


import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgOption } from '@ng-select/ng-select';

import { UsersService } from '../../services/users.service';
import { LocationsService } from '../../services/locations.service';

import { Location } from '../../model/location';
import { Group } from '../../model/group';
import { Permissions } from '../../model/permissions';

import { Store, Select } from '@ngxs/store';
import { GroupState } from '../../store/states/groups.state';
import { LocationState } from '../../store/states/locations.state';
import { LocationChecked, FetchLocations, LocationGroupIDs, LocationSort, LocationFilter } from '../../store/actions/locations.action';
import { FetchGroups, GroupFilter, GroupSort } from '../../store/actions/groups.action';
@Component({
	selector: 'app-email-notification',
	templateUrl: './email-notification.component.html',
	styleUrls: ['./email-notification.component.scss'],
})
export class EmailNotificationComponent implements OnInit {
	permissions = new Permissions();
	@ViewChild('editor') editorComponent: CKEditorComponent;
	public Editor = ClassicEditor;

	config = {
		toolbar: [
			'undo',
			'redo',
			'bold',
			'italic',
			'blockQuote',
			'heading',
			'link',
			'numberedList',
			'bulletedList',
			//'insertTable',
			// 'tableColumn',
			// 'tableRow',
			// 'alignment',
			// 'code',
			// 'src',
		],
		heading: {
			options: [
				{ model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
				{ model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
				{ model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
			],
		},
		alignment: {
			options: ['left', 'right'],
		},
	};
	model = { subject: '', body: '', location_id: [], group_id: [] };

	success = false;
	message = '';
	loading = false;

	@Select(LocationState.getLocations) locations$: Observable<Location[]>;
	@Select(GroupState.getGroups) groups$: Observable<Group[]>;

	groups: NgOption[] = [];
	locations: NgOption[] = [];
	group$: Observable<number[]>;

	constructor(
		private userService: UsersService,
		private locationsService: LocationsService,
		private router: Router,
		private store: Store
	) {}

	ngOnInit() {
		if (this.getUserType() !== 'A' && this.getUserType() !== 'B') {
			this.router.navigateByUrl('devices');
		}

		this.store.dispatch(new FetchGroups());
		this.store.dispatch(new FetchLocations());
		this.store.dispatch(new LocationSort('location_name'));

		this.groups$.subscribe((g) => (this.groups = g));
		this.locations$.subscribe((l) => {
			this.locations = l;
		});
	}

	getUserType() {
		return this.permissions.userType;
	}

	onGroupChange() {
		this.group$ = of(this.model.group_id);
		this.store.dispatch(new LocationGroupIDs(this.group$));
		this.store.dispatch(new FetchLocations());
		this.locations$.subscribe((l) => {
			this.locations = l;
			this.model.location_id = l.filter((ll) => this.model.location_id.includes(ll.location_id)).map((lll) => lll.location_id);
		});
	}

	save() {
		this.success = false;
		this.message = '';
		this.loading = true;

		const result = this.userService.queueNotification(this.model);
		result
			.pipe(map((ret) => ret.data))
			.subscribe(
				(x) => {
					console.log(x);
					this.message = `The following email will be sent. ${x.body}`;
					this.success = true;
					this.loading = false;
					this.model.subject = '';
					this.model.body = '';
					this.model.group_id = [];
					this.model.location_id = [];
				},
				(err) => {
					this.message = 'There was an error sending email. Please contact administrators';
					this.success = false;
					this.loading = false;
				},
				() => {
					console.log('done');
				}
			);
	}
}
