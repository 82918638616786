import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';

import { Permissions } from '../model/permissions';

@Component({
	selector: 'app-header-nav',
	templateUrl: './header-nav.component.html',
	styleUrls: ['./header-nav.component.css'],
})
export class HeaderNavComponent implements OnInit {
	private hasAdminPermission = false;
	private loggedIn: boolean = false;
	permission: Permissions = new Permissions();
	view = 'list';
	isMobile: boolean = window.innerWidth <= 767;

	constructor(private auth: AuthService, private router: Router) {
		this.auth.hasAdminPermision.subscribe((x) => (this.hasAdminPermission = x));
		// this.auth.isLoggedIn.subscribe(x=>this.loggedIn=x);
	}

	logout() {
		this.auth.logout();
		this.router.navigateByUrl('/login');
	}

	ngOnInit() {
		this.auth.hasAdminPermision.subscribe((x) => (this.hasAdminPermission = x));
	}

	hideNav() {
		// if( !this.authService.loggedIn() ){
		//     this.router.navigateByUrl('/unauthorized');
		//     return this.loggedIn
		// }
		// console.log(this.router.url)
		// return this.loggedIn = this.auth.loggedIn();
		return !this.auth.loggedIn();
		/* 
		let link = this.router.url;
		let pattern = /^\/login/i;
		let result = pattern.test(link);
		if (result === true) {
			return true;
		}
		pattern = /^\/recover/i;
		result = pattern.test(link);
		if (result === true) {
			return true;
		}
		pattern = /^\/reset/i;
		result = pattern.test(link);
		if (result === true) {
			return true;
		}
		return false; */
	}

	showAdminLink() {
		let per = new Permissions();
		return per.hasAdminPermission() && !this.isMobile;
	}

	getView() {
		return localStorage.getItem('default_view') || 'list';
	}
}
