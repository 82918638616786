import * as lkeys from 'lowercase-keys';
import { Permissions } from './permissions';


export class Device {
	// public latitude: number;
	// public longitude: number;
	public distanceSeries: any = [];
	public levelSeries: any = [];
	public weightSeries: any = [];
	public volumeSeries: any = [];
	public percentSeries: any = [];
	public uWeightSeries: any = [];
	public uVolumeSeries: any = [];
	public uPercentSeries: any = [];
	public signalStrength: any = [];

	public loading: boolean = false;

	static fromJSON(jsonObj): Device {
		let device = Object.create(Device.prototype);
		Object.keys(jsonObj).forEach((key) => {
			if (jsonObj[key] == null) {
				jsonObj[key] = '';
			}
			
			// Convert isLocked from boolean to number;
			if(key === 'isLocked') {
				jsonObj[key] = jsonObj[key] === true ? 1 : 0;
			}
		
		});
		return Object.assign(device, lkeys(jsonObj), { density: jsonObj.bulk_density, selected: false });
	}

	constructor(
		public device_id?: number,
		public status_cde?: string,
		public gateway_id?: number,
		public outlet_diameter?: number,
		public outlet_width?: number,
		public outlet_half_width?: number,
		public device_name?: string,
		public height?: number,
		public idigi_device_id?: string,
		public shape?: string,
		public device_dia_name?: string,
		public gateway_name?: string,
		public density?: number,
		public width?: number,
		public outlet_length?: number,
		public outlet_half_length?: number,
		public location_id?: number,
		public location_name?: string,
		public company_name?: string,
		public diameter?: number,
		public material?: string,
		public outlet_height?: number,
		public linear_uom?: string,
		public volume_uom?: string,
		public weight_uom?: string,
		public bulk_uom?: string,
		public density_uom?: string,

		public linear_label?: string,
		public volume_label?: string,
		public weight_label?: string,
		public density_label?: string,
		public shape_label?: string,

		public length?: number,
		public timestamp?: number,
		public timezone?: number,
		public distance?: number,
		public weight?: number,
		public volume?: number,
		public max_volume?: number,
		public percent?: number,
		public level?: number,
		public distance_alarm?: string,
		public plumb_bob_status?: string,
		public smu_status?: string,
		public smu_value?: number,
		public smu_timestamp?: number,
		public device_type?: string,
		public device_type_code?: string,
		public ll_threshold?: number,
		public l_threshold?: number,
		public h_threshold?: number,
		public hh_threshold?: number,
		public ll_raw?: number,
		public l_raw?: number,
		public h_raw?: number,
		public hh_raw?: number,
		public msg_ll?: string,
		public msg_l?: string,
		public msg_h?: string,
		public msg_hh?: string,
		public status_delay?: number,
		public onlineStatus?: string,
		public distanceAlarmStartTime?: number,
		public lastAlarm_begin_date?: string,
		public lastAlarm_end_date?: string,
		public lastAlarm_alarm_type?: string,
		public lastAlarm_alarm_uom?: string,
		public lastAlarm_alarm_value?: number,
		public default_display?: string,
		public default_display_label?: string,
		public alarm_display?: string,
		public total_record?: number,
		public strapping_type?: string,
		public type?: string,
		public sensor_type?: string,
		public sensor_address?: string,
		public isonline?: number,
		public group_id?: number,
		public strapping?: any,
		public isPopulated: boolean = false,
		public open: boolean = false,
		public total_rows?: number,
		public dead_zone?: number,
		public comm_error?: boolean | string,
		public comm_timestamp?: number,
		public weight_ullage?: number,
		public volume_ullage?: number,
		public percent_ullage?: number,
		public signal_strength?: number,
		public selected?: boolean,
		public islocked?: number
	) {
		this.distanceSeries = [];
		this.levelSeries = [];
		this.weightSeries = [];
		this.volumeSeries = [];
		this.percentSeries = [];

		this.uWeightSeries = [];
		this.uVolumeSeries = [];
		this.uPercentSeries = [];
		this.loading = false;
		this.open = false;
	}

	public getMultiplier(): number {
		if (
			(this.linear_uom && this.linear_uom.toUpperCase().trim() === 'FEET') ||
			this.linear_uom.toUpperCase().trim() === 'F' ||
			this.linear_uom.toUpperCase().trim() === 'FT'
		) {
			return 3.28084;
		} else {
			return 1;
		}
	}

	public getVolumeMultiplier(): number {
		/*if(this.linear_uom.toUpperCase().trim() === 'FEET'  || this.linear_uom.toUpperCase().trim() === 'F'|| this.linear_uom.toUpperCase().trim() === 'FT'){
			return 35.315;
		}else{
			return 1;
		}*/
		let uom = this.volume_uom.toUpperCase().trim();
		if (uom == 'CUBIC FEET' || uom == 'A') {
			return 35.3147;
		} else if (uom == 'US BUSHELS' || uom == 'B') {
			return 28.3776;
		} else if (uom == 'US GALLONS' || uom == 'C') {
			return 264.172;
		} else if (uom == 'CUBIC METERS' || uom == 'D') {
			return 1;
		} else if (uom == 'BRITISH BUSHELS' || uom == 'E') {
			return 27.4962;
		} else if (uom == 'LITRES' || uom == 'F') {
			return 1000;
		} else if (uom == 'IMPERIAL GALLONS' || uom == 'G') {
			return 219.969;
		} else {
			return 1;
		}
	}

	public getWeightMultiplier(): number {
		/*if(this.linear_uom.toUpperCase().trim() === 'FEET'  || this.linear_uom.toUpperCase().trim() === 'F'|| this.linear_uom.toUpperCase().trim() === 'FT'){
			return 35.315;
		}else{
			return 1;
		}*/
		let uom = this.weight_uom.toUpperCase().trim();
		if (uom == 'POUNDS' || uom == 'A') {
			return 2.20462;
		} else if (uom == 'SHORT TONS' || uom == 'B') {
			return 0.00110231;
		} else if (uom == 'KILOGRAMS' || uom == 'C') {
			return 1;
		} else if (uom == 'METRIC TONS' || uom == 'D') {
			return 0.000984207;
		} else {
			return 1;
		}
	}

	public getDensityMultiplier() {
		let densityMultiplier = 1;

		if (
			(this.linear_uom && this.linear_uom.toUpperCase().trim() === 'FEET') ||
			this.linear_uom.toUpperCase().trim() === 'F' ||
			this.linear_uom.toUpperCase().trim() === 'FT'
		) {
			densityMultiplier = 16.0185;
		}
		return densityMultiplier;
	}

	public isInAlarm(): Boolean {
		if (this.distance_alarm.length > 0 && this.distance_alarm.trim().toLowerCase() === 'normal') {
			// && this.plumb_bob_status.trim().toLowerCase() === 'valid'
			return false;
		}
		return true;
	}

	public setDistance(dist: number): void {
		this.distance = dist;
	}

	public getHeight(): number {
		// return parseFloat( ( this.height * this.getMultiplier() ).toFixed(2) );
		return this.height;
	}

	public getDistance(): number {
		let d = this.getHeight();
		if (!isNaN(this.distance)) {
			d = this.distance;
		}
		// return parseFloat( ( d * this.getMultiplier() ).toFixed(2) );
		return parseFloat(d.toFixed(2));
	}

	public getLevel(): number {
		if (this.shape && this.strapping_type && this.shape.trim().toLowerCase() === 'strapping' && this.strapping_type.trim().toLowerCase() === 'a') {
			return this.getStrappingValue();
		}

		return parseFloat(this.level.toFixed(2));
	}

	public getVolume(): number {
		console.log('this.shape', {shape: this.shape, vol: this.volume, strapping_type: this.strapping_type})
		if (this.shape && this.strapping_type && this.shape.trim().toLowerCase() === 'strapping' && this.strapping_type.trim().toLowerCase() === 'b') {
			return this.getStrappingValue();
		}

		// return parseFloat( ( ((this.getPercent() * this.max_volume * this.getVolumeMultiplier() ) / 100) ).toFixed(2) );
		// let val = ((this.height - this.distance) /this.height) * this.max_volume * this.getVolumeMultiplier();
		// val = val - this.getDeadZoneVolume();
		return this.round2DecimalPt(this.volume);
	}

	public getDensity() {
		return this.density / this.getDensityMultiplier();
	}

	public getWeight(): number {
		if (this.shape && this.strapping_type && this.shape.trim().toLowerCase() === 'strapping' && this.strapping_type.trim().toLowerCase() === 'c') {
			return this.getStrappingValue();
		}

		// let wt = ( ((this.height - this.distance) /this.height) * this.max_volume  ) *  this.getDensityMultiplier() * this.getWeightMultiplier() ;
		return this.round2DecimalPt(this.weight);
	}

	public getPercent(): number {
		if (this.shape && this.shape.trim().toLowerCase() === 'strapping' && this.strapping_type.trim().toLowerCase() === 'd') {
			return this.getStrappingValue();
		}

		// let percent = parseFloat( ( ( ( this.getLevel() * 100 ) / this.getHeight() )  ).toFixed(2) );
		// if(isNaN(percent)){
		// 	percent = 0;
		// }

		return this.percent;
	}

	round2DecimalPt(val) {
		if (isNaN(val)) {
			return val;
		}
		return Math.round(val * 100) / 100;
	}

	public getStatus(): any {
		let msg = '';
		/*
		 * || (this.onlineStatus && this.onlineStatus == 'offline')
		 */
		if (!this.isonline) {
			return 'Offline';
		}

		if (this.islocked === 1) {
			return 'Suspended';
		}

		if (this.comm_error === 'YES') {
			return 'COM Error';
		}

		if (this.device_type_code && this.device_type_code.toLowerCase() === 'smu344n') {
			let binary16 = this.decimalToBinary(Number(this.smu_value));

			if (binary16[4] == 1) {
				msg = 'Hardware error';
			} else if (binary16[6] == 1) {
				msg = 'Cycle limit error';
			} else if (binary16[7] == 1) {
				msg = 'Motion error';
			} else if (binary16[8] == 1) {
				msg = 'Return error';
			} else if (binary16[9] == 1) {
				msg = 'Index error';
			} else if (binary16[12] == 1 || binary16[13] == 1) {
				msg = 'Lockout alert';
			} else if (binary16[15] == 1) {
				msg = 'A-OK';
			}
		} else if ( this.device_type_code && (this.device_type_code.toLowerCase() === 'rr_26' || this.device_type_code.trim().toLowerCase() == 'rr_80')) {
			let binary16 = this.decimalToBinary(this.smu_value);

			msg = 'A-OK';
			if (binary16[15] == 1) {
				msg = 'E11 Hardware Error';
			} else if (binary16[14] == 1) {
				msg = 'E12 Hardware Error';
			} else if (binary16[12] == 1) {
				msg = 'E14 Echo Low';
			} else if (binary16[11] == 1) {
				msg = 'E15 Hardware Error';
			} else if (binary16[9] == 1) {
				msg = 'E16 Hardware Error';
			} else if (binary16[8] == 1) {
				msg = 'E17 Hardware Error';
			}
		}
		return msg;
	}

	public decimalToBinary(decimal: number) {
		let x = Number(decimal).toString(2).split(''); //convert int to binary
		let binary16 = [];

		x.forEach((x) => binary16.push(parseInt(x)));
		binary16.reverse();
		while (binary16.length < 16) binary16.push(0);
		return binary16.reverse();
	}
	/*convert to meter*/
	public convertFromDistance(d) {
		return d / this.getMultiplier();
	}
	/*returns distance*/
	// public convertFromLevel(level) {
	// 	const actual_height = this.height + this.outlet_height;
		
	// 	if (actual_height - level < 0) {
	// 		return actual_height / this.getMultiplier();
	// 	}
	// 	return actual_height / this.getMultiplier() - level / this.getMultiplier();
	// }
	
	public convertFromLevel(level) {
		if (this.getHeight() - level < 0) {
			return this.height / this.getMultiplier();
		}
		return this.height / this.getMultiplier() - level / this.getMultiplier();
	}

	/*returns distance*/
	public convertFromVolume(vol) {
		// let volumePercent = ( vol * 100 / this.getVolumeMultiplier() ) / this.max_volume;
		// return this.convertFromPercent( volumePercent );
		let mx_v = this.max_volume / this.getVolumeMultiplier();
		let v = vol / this.getVolumeMultiplier();
		let p = (v * 100) / mx_v;
		let percent = this.convertFromPercent(p);
		// console.log((v * 100)/ mx_v);
		return percent;
	}

	/*returns distance*/
	public convertFromWeight(wt) {
		let d = this.density * this.getDensityMultiplier();
		let w = wt / this.getWeightMultiplier();
		let mx_v = this.max_volume / this.getVolumeMultiplier();
		let mx_wt = mx_v * d;
		let p = (w * 100) / mx_wt;
		let percent = this.convertFromPercent(p);
		/*console.log('density', this.density , this.getDensityMultiplier() , d);
    	console.log('weigth', wt , this.getWeightMultiplier() , w);
    	console.log('max vol', mx_v , this.getVolumeMultiplier() , w);
    	console.log('max wt', mx_wt );
    	console.log( 'perc',p ); 
    	console.log('temp',percent);*/
		return percent;
	}

	/*returns distance*/
	public convertFromPercent(perc) {
		let level = (this.height * perc) / 100; /* this is level*/
		let distance = this.height - level;
		return distance / this.getMultiplier();
	} /*returns distance*/

	public getVolumeFromPercent(perc) {
		return (this.max_volume * perc) / 100;
	}

	public getAlert(): string {
		let status: string = 'No Alarm';

		switch (this.distance_alarm.trim().toUpperCase()) {
			case 'LL':
				status = 'Low Low';
				break;
			case 'L':
				status = 'Low';
				break;
			case 'H':
				status = 'High';
				break;
			case 'HH':
				status = 'High High';
				break;

			default:
				status = 'No Alarm';
				break;
		}
		return status;
	}

	getAlertType() {
		if ((this.device_type_code && this.device_type_code.toLowerCase() === 'bm100') && this.getAlert() === 'No Alarm') {
			return 'alert alert-success';
		} else if (this.getAlert() === 'No Alarm' && this.getStatus() === 'A-OK') {
			return 'alert alert-success';
		} else if (this.getStatus() === 'Offline') {
			return 'alert alert-danger';
		} else if (this.getAlert() === 'Undefined' && this.getStatus() !== 'A-OK') {
			return '';
		} else {
			return 'alert alert-danger';
		}
	}

	public getMeasurement(): string {
		let disp = this.default_display.trim().toUpperCase();
		let mea = '';
		switch (disp) {
			case 'A':
				mea = this.round2DecimalPt(this.distance) + ' ' + this.linear_label;
				break;
			case 'B':
				mea = this.round2DecimalPt(this.level) + ' ' + this.linear_label;
				break;
			case 'C':
				mea = this.round2DecimalPt(this.volume) + ' ' + this.volume_label;
				break;
			case 'D':
				mea = this.round2DecimalPt(this.weight) + ' ' + this.weight_label;
				break;
			case 'E':
				mea = this.round2DecimalPt(this.percent) + ' %';
				break;
			case 'F':
				mea = this.round2DecimalPt(this.volume_ullage) + ' ' + this.volume_label;
				break;
			case 'G':
				mea = this.round2DecimalPt(this.weight_ullage) + ' ' + this.weight_label;
				break;
			case 'H':
				mea = this.round2DecimalPt(this.percent_ullage) + ' %';
				break;
			default:
				mea = this.round2DecimalPt(this.level) + ' ' + this.linear_label;
				break;
		}

		return mea;
	}

	public getStrappingValue(): number {
		let s_low = 0;
		let s_high = 0;
		let d_low = 0;
		let d_high = 0;
		let s = 0;
		let d = this.getDistance();
		for (let i = 1; i <= this.strapping.length; i++) {
			if (this.strapping[i].DISTANCE * this.getMultiplier() > d) {
				s_high = this.strapping[i].STRAPPING;
				s_low = this.strapping[i - 1].STRAPPING;
				d_high = this.strapping[i].DISTANCE * this.getMultiplier();
				d_low = this.strapping[i - 1].DISTANCE * this.getMultiplier();
				break;
			}
		}

		if (d == d_high) {
			s = s_high;
		} else if (d == d_low) {
			s = s_low;
		} else {
			s = (s_high + s_low) / 2;
		}
		// console.log(d);
		return s;
	}

	getOutlet_diameter() {
		return this.outlet_diameter ? parseFloat((this.outlet_diameter * this.getMultiplier()).toFixed(2)) : this.outlet_diameter;
	}
	getOutlet_width() {
		return this.outlet_width ? parseFloat((this.outlet_width * this.getMultiplier()).toFixed(2)) : this.outlet_width;
	}
	getOutlet_half_width() {
		return this.outlet_half_width ? parseFloat((this.outlet_half_width * this.getMultiplier()).toFixed(2)) : this.outlet_half_width;
	}
	getWidth() {
		return this.width ? parseFloat((this.width * this.getMultiplier()).toFixed(2)) : this.width;
	}
	getOutlet_length() {
		return this.outlet_length ? parseFloat((this.outlet_length * this.getMultiplier()).toFixed(2)) : this.outlet_length;
	}
	getOutlet_half_length() {
		return this.outlet_half_length ? parseFloat((this.outlet_half_length * this.getMultiplier()).toFixed(2)) : this.outlet_half_length;
	}
	getDiameter() {
		return this.diameter ? parseFloat((this.diameter * this.getMultiplier()).toFixed(2)) : this.diameter;
	}
	getOutlet_height() {
		return this.outlet_height ? parseFloat((this.outlet_height * this.getMultiplier()).toFixed(2)) : this.outlet_height;
	}
	getLength() {
		return this.length ? parseFloat((this.length * this.getMultiplier()).toFixed(2)) : this.length;
	}

	getLatestDeviceTime() {
		let time = [this.timestamp, this.smu_timestamp, this.comm_timestamp];
		let largest = Math.max.apply(Math, time);

		return largest;
	}

	viewSignal() {
		let p = new Permissions();
		return (
			p.viewSignalStrength() && this.device_type_code && 
			( this.device_type_code.trim().toLowerCase() == 'rr_26' || this.device_type_code.trim().toLowerCase() == 'rr_80' )
		);
	}
}
