import { Component, OnInit, Input } from '@angular/core';

import { Device } from '../model/device';

@Component({
  selector: 'app-silo-detail',
  templateUrl: './silo-detail.component.html',
  styleUrls: ['./silo-detail.component.css']
})
export class SiloDetailComponent implements OnInit {
	@Input() device: Device;
	public max_weight:number = 0;
	public ullageContainer: Device;

	constructor() {}

	ngOnInit() {
		this.ullageContainer = Object.create( this.device );
		this.ullageContainer.setDistance(0);
	}

}
