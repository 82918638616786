import { Injectable } 							from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';


import { environment} from '../../environments/environment';

import { Strapping } from '../model/strapping';
import { UtilService } from './util.service';

@Injectable()
export class StrappingsService {
	 private strappingUrl = environment.apiurl + '/location/getStrapping';

	private _strappings:Subject<Strapping[]> 		= new Subject<Strapping[]>();
	public strappings: Observable<Strapping[]> 		= this._strappings.asObservable();

	constructor (private util:UtilService, private router: Router) {}

	public getStrapping(device_id): void {
		this.util._getData( this.strappingUrl, {device_id})
			.pipe(map(ret=>ret.data))
			.subscribe( gJSON =>{
				const strap = gJSON.map( jsonobj => Strapping.fromJSON( jsonobj ));
				this._strappings.next( strap );
			})
	}

	public saveStrapping(body,device_id){
		let url = environment.apiurl + '/location/saveStrapping' ;
		this.util._postData( url,body )
			.pipe(map(ret=>ret.data))
			.subscribe( 
							()=>{},//this.router.navigateByUrl('/administration/silo/edit/' + device_id),
							()=>{console.log('Error saving Rules')},
							()=>console.log('saving completed')
						)
	}

}
