interface distJSON{
	device_id:  number;
	timeStamp:	number;
	distance: 	number;
}

export class Distance {

	static fromJSON( jsonObj ): Distance{
		let distance = Object.create( Distance.prototype );
		// return Object.assign( distance, jsonObj,{	
		// 											device_id: 	jsonObj.DEVICE_ID,
		// 											timeStamp: 	jsonObj.TIMESTAMP,
		// 											distance: 	jsonObj.DATA
		// 										});
		return Object.assign( distance,jsonObj, {	
			device_id: 	jsonObj.device_id,
			timeStamp: 	Number(jsonObj.timestamp),
			distance: 	jsonObj.distance
		});
	}
	
	constructor(
		public device_id?: number,
		public timeStamp?:number, 
		public distance?:number){ 
    }

}














  /*  public getLevel( height:number ):number{
    	return parseFloat( ( height - this.distance ).toFixed(2) );
    }
    
    public getVolume( max_volume:number, height:number ):number{
    	return parseFloat( ( (this.getPercent(height) * this.max_volume ) / 100 ).toFixed(2) );
    }

    public getPercent(height):number{    	
    	return parseFloat( ( ( this.getLevel( height ) * 100 ) / height ).toFixed(2) );
    }*/
