<div class="container" style="height: 100vh">
  <div
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    class="row align-items-center justify-content-center h-100 mx-auto"
    >
    <div class="col-12 col-md-6 col-xl-4" role="document">
      <div class="modal-content">
        @if (message && message.length > 0) {
          <div class="col-12 alert text-center" [ngClass]="hasError ? 'alert-danger' : 'alert-success'">
            {{ message }}
          </div>
        }

        <form [formGroup]="verifyForm" (ngSubmit)="verifyForm.valid && verify()" autocomplete="off" validate="yes">
          @if (id.valid) {
            <div class="modal-body">
              <div class="form-group row mb-1 mx-auto">
                <h2 class="text-center col-12">
                  <label for="code" class="col-form-label">Verify Your Identity</label>
                </h2>
                <input type="code" class="form-control col-12" name="code" id="code" [formControl]="code" autocomplete="off" />
                <div [hidden]="code.valid || code.untouched" class="alert alert-danger p-1">Verification code is required</div>
              </div>
            </div>
          }
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" routerLink="/login">Cancel</button>
            <button type="submit" class="btn btn-primary" [disabled]="verifyForm.invalid">Verify</button>
            <button type="button" class="btn btn-primary" [disabled]="id.invalid" (click)="requestAgain()">Request Again</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
