import { Pipe, PipeTransform } from '@angular/core';

import { Location } from './model/location';
import { Device } from './model/device';

@Pipe({
    name: 'FilterPipe',
})
export class FilterPipe implements PipeTransform {
    transform(value: any, input: string) {
        if (input && input.length > 0) {
            input = input.toLowerCase();
            
            return value.filter(function (el: any) {
            	if(el instanceof Location){
                    return el.name.toLowerCase().indexOf(input) > -1;
                }else if(el instanceof Device){
                    return el.device_name.toLowerCase().indexOf(input) > -1;
                }else{
                    return el.name.toLowerCase().indexOf(input) > -1;
                }                    
            })
        }
        return value;
    }
}