
<nav aria-label="Page navigation col-9" >

  @if (pager.pages && pager.pages.length) {
    <ul class="pagination">
      <li class="page-item">
        <div class="dropdown">
          <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{pageSize}} Records per page
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" (click)="updatePageSize(5)">5</a>
            <a class="dropdown-item" (click)="updatePageSize(10)">10</a>
            <a class="dropdown-item" (click)="updatePageSize(25)">25</a>
            <a class="dropdown-item" (click)="updatePageSize(50)">50</a>
          </div>
        </div>
      </li>
      <li class="page-item d-none d-sm-none d-md-block"  [ngClass]="{disabled:pager.currentPage === 1}">
        <a class="page-link" (click)="setPage(1)">First</a>
      </li>
      <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
        <a class="page-link" (click)="setPage(pager.currentPage - 1)">Previous</a>
      </li>
      @for (page of pager.pages; track page) {
        <li class="page-item d-none d-sm-none d-md-block" [ngClass]="{active:pager.currentPage === page}">
          <a class="page-link" (click)="setPage(page)">{{page}}</a>
        </li>
      }
      <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
        <a class="page-link" (click)="setPage(pager.currentPage + 1)">Next</a>
      </li>
      <li class="page-item d-none d-sm-none d-md-block"  [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
        <a class="page-link" (click)="setPage(pager.totalPages)">Last</a>
      </li>
    </ul>
  }
</nav>
@if (pager.totalPages === 0) {
  <div class="alert alert-warking">
    No Record found
  </div>
}