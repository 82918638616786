import * as lkeys from 'lowercase-keys';
interface GroupJson {
	group_id: number;
	name: string;
	city: string;
	state: string;
	phone: string;
	email: string;
	status_cde: string;
	addr1: string;
	addr2: string;
	zip: string;
	country: string;
	fax: string;
	alias: string;
	customer_number: string;
	bill_to_key: string;
	total_rows: number;
	selected?: boolean;
	state_id?: number;
	country_id?: number;
	is_2fa_required?: number;
}

export class Group implements GroupJson {
	static fromJSON(jsonObj): Group {
		let group = Object.create(Group.prototype);

		let obj = Object.assign(group, lkeys(jsonObj), { selected: false });
		return obj;
	}

	constructor(
		public group_id: number,
		public name: string,
		public city: string,
		public state: string,
		public phone: string,
		public email: string,
		public status_cde: string,
		public addr1: string,
		public addr2: string,
		public zip: string,
		public country: string,
		public fax: string,
		public alias: string,
		public customer_number: string,
		public bill_to_key: string,
		public total_rows: number,
		public selected?: boolean,
		public state_id?: number,
		public country_id?: number,
		public is_2fa_required?: number
	) {}
}
