<div class="col-12">
  @if (loading) {
    <app-loading-spinner style="position: absolute; top: 25%; left: 25%"></app-loading-spinner>
  }
  @if (message && message.length > 0) {
    <div class="col-12 alert text-center" [ngClass]="hasError ? 'alert-danger' : 'alert-success'">
      {{ message }}
      @for (file of files; track file) {
        <div class="col-12">
          <a href="{{ linkUrl }}{{ file }}" target="_blank">{{ file }}</a>
        </div>
      }
    </div>
  }

  <form class="adminForm" name="form" (ngSubmit)="generateReport(f)" #f="ngForm" novalidate>
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <div class="row mb-2">
          <div class="col-12">
            <input
              type="radio"
              name="report_type"
              id="vessel_report_type"
              value="vessel"
              [(ngModel)]="model.report_type"
              #report_type="ngModel"
              (click)="updateReportSelection('vessel')"
              />
              <label for="vessel_report_type">One or more silo report by date range.</label>
            </div>
            <div class="col-12">
              <input
                type="radio"
                name="report_type"
                id="location_report_type"
                value="location"
                [(ngModel)]="model.report_type"
                #report_type="ngModel"
                (click)="updateReportSelection('location')"
                />
                <label for="location_report_type">All Silos within one or more locations (showing last measurement)</label>
              </div>
              <div class="col-12">
                <input
                  type="radio"
                  name="report_type"
                  id="company_report_type"
                  value="company"
                  [(ngModel)]="model.report_type"
                  #report_type="ngModel"
                  (click)="updateReportSelection('company')"
                  />
                  <label for="company_report_type">All Silos within a single Company (showing last measurement)</label>
                </div>
                <div class="col-12">
                  <input
                    type="radio"
                    name="report_type"
                    id="single_location_report_type"
                    value="single_location"
                    [(ngModel)]="model.report_type"
                    #report_type="ngModel"
                    (click)="updateReportSelection('single_location')"
                    />
                    <label for="single_location_report_type">All Silos within a single location with From/To Date Range.</label>
                  </div>
                  <div class="col-12">
                    <input
                      type="radio"
                      name="report_type"
                      id="single_company_report_type"
                      value="single_company"
                      [(ngModel)]="model.report_type"
                      #report_type="ngModel"
                      (click)="updateReportSelection('single_company')"
                      />
                      <label for="single_company_report_type">All Silos within a Company with From/To Date Range.</label>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-sm-12 mb-1">
                  <div class="col-12">
                    <strong>Saved Report</strong>
                  </div>
                  <div class="col-12 pr-0 scroll">
                    <ul class="list-group">
                      @for (report of reports; track report) {
                        <li
                          class="p-1 list-group-item d-flex justify-content-between"
                          (click)="selectedReport(report)"
                          [class.active]="report.active === true"
                          >
                          <span>{{ report.name }}</span>
                          <span class="badge badge-danger" (click)="deleteReport(report)">Delete</span>
                        </li>
                      }
                    </ul>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-md-6 col-sm-12 mb-1">
                  <label for="location_id" class="col-12 col-form-label pr-1">Column Headers</label>
                  <div class="col-12">
                    <ng-select
                      [items]="columns"
                      bindLabel="name"
                      bindValue="value"
                      name="value"
                      placeholder="Select Column Header"
                      [multiple]="true"
                      [(ngModel)]="model.column_header"
                    ></ng-select>
                  </div>
                </div>

                <div class="form-group col-md-6 col-sm-12 mb-1">
                  <label for="location_id" class="col-12 col-form-label pr-1">Company</label>
                  <div class="col-12">
                    <ng-select
                      [items]="groups"
                      bindLabel="name"
                      bindValue="group_id"
                      name="group_id"
                      placeholder="Select Company"
                      (change)="getLocationByGroupID()"
                      [(ngModel)]="model.group_id"
                    ></ng-select>
                  </div>
                </div>

                @if (model.report_type !== 'company' && model.report_type != 'single_company') {
                  <div class="form-group col-md-6 col-sm-12 mb-1">
                    <label for="location_id" class="col-12 col-form-label pr-1">Location</label>
                    <div class="col-12">
                      <ng-select
                        [items]="locations"
                        bindLabel="name"
                        bindValue="location_id"
                        name="location_id"
                        placeholder="Select Location"
                        (change)="getDevicesByLocation()"
                        [multiple]="model.report_type !== 'single_location'"
                        [(ngModel)]="model.location_id"
                      ></ng-select>
                    </div>
                  </div>
                }

                @if (model.report_type === 'vessel') {
                  <div class="form-group col-md-6 col-sm-12 mb-1">
                    <label for="location_id" class="col-12 col-form-label">Contents</label>
                    <div class="col-12">
                      <ng-select
                        [items]="materials"
                        bindLabel="MATERIAL"
                        bindValue="MATERIAL"
                        name="material"
                        placeholder="Select Content"
                        (change)="deviceByContent()"
                        [multiple]="true"
                        [(ngModel)]="model.contents"
                      ></ng-select>
                    </div>
                  </div>
                }

                @if (model.report_type === 'vessel') {
                  <div class="form-group col-md-6 col-sm-12 mb-1">
                    <label for="device_id" class="col-12 col-form-label pr-1">Silos</label>
                    <div class="col-12">
                      <ng-select
                        [items]="devices"
                        bindLabel="device_name"
                        bindValue="device_id"
                        name="device_id"
                        placeholder="Select Silo"
                        [multiple]="model.report_type == 'vessel'"
                        [(ngModel)]="model.device_id"
                      ></ng-select>
                    </div>
                  </div>
                }

                @if (!(model.report_type == 'company' || model.report_type == 'location')) {
                  <div class="form-group col-md-6 col-sm-12 mb-1">
                    <label for="date_range" class="col-12 col-form-label pr-1">Date Range </label>
                    <div class="col-12">
                      <ng-select
                        [items]="date_range"
                        bindLabel="name"
                        bindValue="value"
                        name="date_range"
                        placeholder="Date Range"
                        [multiple]="false"
                        [(ngModel)]="model.date_range"
                        (change)="showCustomRange($event)"
                      ></ng-select>
                    </div>
                    @if (customRange) {
                      <div
                        class="form-group col-12 mb-1"
                        [hidden]="model.report_type == 'location' || model.report_type == 'company'"
                        >
                        <div class="row">
                          <label for="channel_id" class="col-12 col-form-label pr-1">Custom Date Range</label>
                          <div class="col pt-1 pl-3">
                            From:
                            <input
                              type="date"
                              value="Date from"
                              max="{{ today }}"
                              class="form-control p1 col-12"
                              name="date_from"
                              id="date_from"
                              [(ngModel)]="model.date_from"
                              #date_from="ngModel"
                              (change)="validateDateRange()"
                              max="{{ today }}"
                              />
                            </div>
                            <div class="col p-1">
                              To:
                              <input
                                type="date"
                                value="Date to"
                                class="form-control p1 col-12"
                                name="date_to"
                                id="date_to"
                                [(ngModel)]="model.date_to"
                                #date_to="ngModel"
                                (change)="validateDateRange()"
                                min="{{ model.date_from }}"
                                max="{{ threeMonthsAfterfromDate }}"
                                [disabled]="!model.date_from"
                                />
                              </div>
                            </div>
                            <div class="row">
                              @if (!validDateRange ) {
                                <div class="alert alert-danger p-1">
                                  Please make sure you select a date range that is less than 90 days.
                                </div>
                              }
                              @if (date_from.touched || date_to.touched) {
                                <div class="col p-1 font-italics txt-sm">
                                  Note: If day, month or year part of the day is missing, it will be assumed as an empty field.
                                </div>
                              }
                            </div>
                          </div>
                        }
                      </div>
                    }

                    <div class="form-group col-md-6 col-sm-12 mb-1">
                      <label for="device_id" class="col-12 col-form-label">Report Name</label>
                      <div class="col-12">
                        <input
                          type="text"
                          id="report_name"
                          name="report_name"
                          value=""
                          placeholder="New Report Name"
                          [(ngModel)]="model.report_name"
                          #report_name="ngModel"
                          class="form-control p1 col-12"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      @if (allowEditReport) {
                        <div class="form-group row mb-1">
                          <label for="schedule_report" class="col-form-label col-6"
                            >Schedule this report &nbsp;
                            <input
                              type="checkbox"
                              name="schedule_report"
                              id="schedule_report"
                              [(ngModel)]="model.schedule_report"
                              #schedule_report="ngModel"
                              (click)="resetSchedule()"
                              />
                            </label>
                          </div>
                        }

                        @if (model.schedule_report === true && allowEditReport) {
                          <div class="form-group row mb-1">
                            <div class="col-6">
                              <label class="p-1">Recurring</label>
                              <select
                                name="report_frequency"
                                class="form-control"
                                [(ngModel)]="model.report_frequency"
                                #report_frequency="ngModel"
                                (change)="clearDayHour()"
                                >
                                <option value=""></option>
                                <option value="Daily">Daily</option>
                                <option value="Weekly">Weekly</option>
                              </select>
                            </div>
                            <div class="col-6">
                              <label class="p-1" for="report_day">Day</label>
                              <select
                                name="report_day"
                                id="report_day"
                                class="form-control p-1"
                                [(ngModel)]="model.report_day"
                                #report_day="ngModel"
                                [disabled]="model.report_frequency.trim().toLowerCase() !== 'weekly'"
                                >
                                <option value="1">Sunday</option>
                                <option value="2">Monday</option>
                                <option value="3">Tuesday</option>
                                <option value="4">Wednesday</option>
                                <option value="5">Thursday</option>
                                <option value="6">Friday</option>
                                <option value="7">Saturday</option>
                              </select>
                            </div>
                            <div class="col-6">
                              <label class="p-1" for="report_hour">Hour</label>
                              <div class="input-group">
                                <span class="input-group-addon">&#64;</span>
                                <select
                                  name="report_time"
                                  class="form-control py-auto px-1"
                                  [(ngModel)]="model.report_time"
                                  #report_time="ngModel"
                                  [disabled]="model.report_frequency.trim() === ''"
                                  >
                                  @for (hour of hours; track hour) {
                                    <option [ngValue]="hour">{{ hrPM_AM(hour) }}</option>
                                  }
                                </select>
                              </div>
                            </div>
                            <div class="col-6">
                              <label class="p-1" for="timezone">Time Zone</label>
                              <select
                                class="form-control ml-auto mr-1"
                                name="timezone"
                                [(ngModel)]="model.timezone"
                                #timezone="ngModel"
                                [disabled]="model.report_frequency.trim() === ''"
                                >
                                <option [ngValue]="-3">Atlantic (GMT - 3)</option>
                                <option [ngValue]="-4">Eastern (GMT - 4)</option>
                                <option [ngValue]="-5">Central (GMT - 5)</option>
                                <option [ngValue]="-6">Mountain (GMT - 6)</option>
                                <option [ngValue]="-7">Pacific (GMT - 7)</option>
                                <option [ngValue]="-8">Alaska (GMT - 8)</option>
                                <option [ngValue]="-9">Hawaii (GMT - 9)</option>
                                <option [ngValue]="9">Japan (GMT + 9)</option>
                              </select>
                            </div>
                            <div class="col-12">
                              <label for="user_id" class="col-12 col-form-label px-1">Subscribers</label>
                              <ng-select
                                [items]="users"
                                bindLabel="name"
                                bindValue="user_id"
                                name="user_id"
                                placeholder="Select User"
                                [multiple]="true"
                                [(ngModel)]="model.user_id"
                              ></ng-select>
                            </div>
                          </div>
                        }

                        <div class="form-group row mb-1">
                          &nbsp;
                          <div class="row">
                            @if (model.report_id != '') {
                              <button type="submit" class="btn btn-primary mr-1 mb-1" (click)="prepareNewCriteria()">
                                Clear
                              </button>
                            }
                            <button
                              type="button"
                              class="btn btn-primary mr-1 mb-1"
                              (click)="saveReport()"
                              [disabled]="model.report_name.trim() == '' || !validDateRange || !model.column_header.length || (!model.device_id.length && model.report_type === 'vessel')"
                              >
                              {{ btnText() }}
                            </button>
                            <button type="submit" class="btn btn-primary mr-1 mb-1" [disabled]="!validDateRange || !model.column_header.length || (!model.device_id.length && model.report_type === 'vessel')">Generate Report</button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
