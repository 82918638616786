import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormControl, ReactiveFormsModule, FormArray } from '@angular/forms';
import { RulesService } from '../services/rules.service';
import { Permissions } from '../model/permissions';

@Component({
	selector: 'app-customer-service',
	templateUrl: './customer-service.component.html',
	styleUrls: ['./customer-service.component.css'],
})
export class CustomerServiceComponent implements OnInit {
	public hasError: boolean;
	public message: string;
	public permissions: Permissions = new Permissions();
	public isTech = false;
	public isSalesRep = false;

	name = new UntypedFormControl('', [Validators.required]);
	phone = new UntypedFormControl('');
	email = new UntypedFormControl('', [Validators.required]);
	subject = new UntypedFormControl('', [Validators.required]);
	comment = new UntypedFormControl('', [Validators.required]);

	contactForm: UntypedFormGroup = this.builder.group({
		name: this.name,
		phone: this.phone,
		email: this.email,
		subject: this.subject,
		comment: this.comment,
	});
	constructor(private builder: UntypedFormBuilder, private router: Router, private rulesService: RulesService) {}

	ngOnInit() {
		this.isTech = this.permissions.userType == 'F';
		this.isSalesRep = this.permissions.userType == 'E';
	}
	submitContact() {
		this.hasError = false;
		this.message = '';
		console.log(this.contactForm.value);
		this.rulesService.sendCustomerService(this.contactForm.value);
		this.rulesService.hasError.subscribe((x) => (this.hasError = x));
		this.rulesService.message.subscribe((x) => (this.message = x));
	}
}
