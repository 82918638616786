<div class="container" id="notification" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">{{ device_name }} - Notification</h5>
  </div>
  <div class="row">
    @if (message && message.length > 0) {
      <div class="col-12 alert text-center" [ngClass]="hasError ? 'alert-danger' : 'alert-success'">
        {{ message }}
      </div>
    }
  </div>
  <div class="modal-body">
    <form>
      <table>
        <tr>
          <td colspan="100%">
            <strong>E-mail Notification</strong>
          </td>
        </tr>
        <tr>
          <td width="20%">Contact Name</td>
          <td width="20%">Email</td>
          <td width="12%" style="text-align: center">LL</td>
          <td width="12%" style="text-align: center">L&nbsp;</td>
          <td width="12%" style="text-align: center">H&nbsp;</td>
          <td width="12%" style="text-align: center">HH</td>
          <td width="12%" style="text-align: center">Status</td>
        </tr>
        @for (n of [1, 2, 3, 4, 5, 6]; track n; let i = $index) {
          <tr>
            <td>
              <input
                type="text"
                class="form-control p-1 ml-1 mt-1"
                name="email_{{ n }}_name"
                [(ngModel)]="notificationModel['email_' + n + '_name']"
                #email_{{n}}_name="ngModel"
                />
              </td>
              <td>
                <input
                  type="email"
                  class="form-control p-1 ml-1 mt-1"
                  name="email_{{ n }}"
                  [(ngModel)]="notificationModel['email_' + n]"
                  #email_[n]="ngModel"
                  />
                </td>
                @for (t of ['ll', 'l', 'h', 'hh', 'status']; track t) {
                  <td>
                    <input
                      type="checkbox"
                      class="form-control"
                      name="email_{{ n }}_{{ t }}"
                      (click)="updateNotification($event, t, n, 'email')"
                      [(ngModel)]="notificationModel['email_' + n + '_name_' + t]"
                      #email_[n]_name_[t]="ngModel"
                      />
                    </td>
                  }
                  <td>
                    <button
                      type="button"
                      class="btn btn-secondary p-1"
                      data-dismiss="modal"
                      [disabled]="notificationModel['email_' + n].length == 0 || !canUpdate"
                      (click)="testNotification(n, 'email')"
                      >
                      Test Email
                    </button>
                  </td>
                </tr>
              }
              <tr>
                <td colspan="100%">
                  <strong>SMS Notification</strong>
                </td>
              </tr>
              <tr>
                <td width="20%">Contact Name</td>
                <td width="20%">Phone</td>
                <td width="12%" style="text-align: center">LL</td>
                <td width="12%" style="text-align: center">L&nbsp;</td>
                <td width="12%" style="text-align: center">H&nbsp;</td>
                <td width="12%" style="text-align: center">HH</td>
                <td width="12%" style="text-align: center">Status</td>
                <td width="12%" style="text-align: center"></td>
              </tr>
              @for (n of [1, 2, 3, 4, 5, 6]; track n; let i = $index) {
                <tr>
                  <td class="ml-1 mt-1">
                    <input
                      type="text"
                      class="form-control p-1 ml-1 mt-1"
                      name="sms_{{ n }}_name"
                      [(ngModel)]="notificationModel['sms_' + n + '_name']"
                      #sms_{{n}}_name="ngModel"
                      />
                    </td>
                    <td class="ml-1 mt-1">
                      <input
                        type="text"
                        class="form-control p-1 ml-1 mt-1"
                        name="sms_{{ n }}"
                        [(ngModel)]="notificationModel['sms_' + n]"
                        #sms_[n]="ngModel"
                        />
                      </td>
                      @for (t of ['ll', 'l', 'h', 'hh', 'status']; track t) {
                        <td>
                          <input
                            type="checkbox"
                            class="form-control"
                            name="sms_{{ n }}_{{ t }}"
                            (click)="updateNotification($event, t, n, 'sms')"
                            [(ngModel)]="notificationModel['sms_' + n + '_name_' + t]"
                            #sms_[n]_name_[t]="ngModel"
                            />
                          </td>
                        }
                        <td>
                          <button
                            type="button"
                            class="btn btn-secondary p-1"
                            data-dismiss="modal"
                            [disabled]="notificationModel['sms_' + n].length == 0 || !canUpdate"
                            (click)="testNotification(n, 'sms')"
                            >
                            Test SMS
                          </button>
                        </td>
                      </tr>
                    }
                    <tr>
                      <td colspan="100%">
                        <button
                          type="button"
                          class="btn btn-success p-1 mr-1"
                          (click)="saveNotification()"
                          data-dismiss="modal"
                          [disabled]="!canUpdate"
                          >
                          Save changes
                        </button>
                        <button routerLink="/administration/silos" type="button" class="btn btn-secondary p-1" data-dismiss="modal">Cancel</button>
                      </td>
                    </tr>
                  </table>
                </form>
              </div>
            </div>
