import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { Observable, Subject, } from 'rxjs';
import { Router } from '@angular/router'
import {FormGroup, Validators, UntypedFormBuilder, FormControl, ReactiveFormsModule, FormArray } from '@angular/forms';

import { LocationsService } from '../../services/locations.service';
import { Location } from '../../model/location';

import { DevicesService } from '../../services/devices.service';
import { Device } from '../../model/device';

import { AlarmlogService } from '../../services/alarmlog.service';
import { AlarmLog } from '../../model/alarmLog';

import { GatewaysService } from '../../services/gateways.service';
import { Gateway } from '../../model/gateway';


import { GroupsService } from '../../services/groups.service';
import { Group } from '../../model/group';

import { Permissions } from '../../model/permissions';

import { environment} from '../../../environments/environment';

import { takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-alarmlog',
  templateUrl: './alarmlog.component.html',
  styleUrls: ['./alarmlog.component.css']
})
export class AlarmlogComponent implements OnInit, OnDestroy {

  	public hasError:boolean;
	public message:string;
	public permissions = new Permissions();
	public locations: Location[];
	public devices: Device[];
	public alarms: Observable<AlarmLog[]>;
	public gateways: Observable<Gateway[]>;
	public groups: Observable<Group[]>;
	public loading: boolean = false;
	public totalPages:number;
  	public arr = Array;
  	loc$;
  	device$;
  	log$;
  	componentDestroyed$: Subject<boolean> = new Subject();

	model: any = {
					group_id: '',
					location_id: '',
					device_id: '',
					page: 1,
					recordsPerPage: 20,
					orderBy: 'al.startdate',
					orderDirection: 'DESC'
				};
	

	constructor(private locationsService: LocationsService, 
				private devicesService:DevicesService, 
				private gatewaysService:GatewaysService, 
				private groupsService:GroupsService, 
				private alarmlogService: AlarmlogService,
				private router: Router, 
				private elRef:ElementRef,
				private builder: UntypedFormBuilder) { 
		this.locationsService.getLocations();
		this.loc$ = locationsService.locations.subscribe( x=>this.locations = x);
		this.devicesService.getDevicesAdmin();

		this.device$ = this.devicesService.devicesAdmin.subscribe(x=> this.devices = x)

		this.gatewaysService.getGateways();
		this.gateways = this.gatewaysService.gateways;
		this.groupsService.getGroups();
		this.groups	= groupsService.groups;
		this.alarms = this.alarmlogService.alarms;
		this.totalNumberOfPages();
		this.alarmlogService.loading.subscribe(x=>this.loading=x);		
		this.alarms.subscribe((res) => {
			console.log("ALARMS ==> ", res);
		})
	}

	ngOnInit() {
		// this.updateLog();
	}

	ngOnDestroy(){
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
        if(this.loc$){
        	this.loc$.unsubscribe();
        }
        if(this.device$){
        	this.device$.unsubscribe();
        }
        if(this.log$){
        	this.log$.unsubscribe();
        }
	}

	filterLocationByGroup(){
		this.model.location_id = '';
		let loc:Location[] = [];
		this.loc$ = this.locationsService.locations.subscribe(
						x=>{//this.locations.splice(0);
							x.filter(a=>{ return a.group_id == this.model.group_id})
							.map(b=>loc.push(b))
							this.locations = loc;
							this.filterDevicesByGroupLocation();
						}
					);
	}
	filterDevicesByGroupLocation(){		
		this.model.device_id = '';
		let devices:Device[] = [];
		this.device$ = this.devicesService.devicesAdmin.subscribe(
						x=>{
							x.filter(a=>{ 
								if(this.model.location_id !== ''){							
									return a.location_id == this.model.location_id;
								}else if(this.model.group_id !== ''){
									return a.group_id == this.model.group_id;						
								}else{
									return true;
								}
							})
							.map(b=>devices.push(b))
							this.devices = devices;
							this.updateLog();
						}
					);
	}

	sortBy(sortCol){
	    this.model.orderBy = sortCol;
	    
	    if( this.model.orderDirection === 'ASC'){
	      this.model.orderDirection = 'DESC';
	    }else{
	      this.model.orderDirection = 'ASC';
	    }

	    this.updateLog();

	}

	totalNumberOfPages():void{
		this.totalPages = 0;
		this.alarms
		.pipe(takeUntil(this.componentDestroyed$))
		.subscribe(x=>{
						if(x.length === 0){
							this.totalPages = 0;
						}
						x.map(t=>{
									this.totalPages = Math.ceil( t.total_record  / this.model.recordsPerPage );
								})
					}
				);
	}

	updateLog(){		
		this.loading = true;
		this.alarmlogService.getAlarms(this.model.group_id, this.model.location_id,this.model.device_id, 1,this.model.orderBy,this.model.orderDirection);
		this.totalNumberOfPages();
		this.alarmlogService.loading.subscribe(x=>this.loading=x);	
		this.filter = false;
	}

	updatePage(){
		this.alarmlogService.getAlarms(this.model.group_id, this.model.location_id,this.model.device_id, this.model.page,this.model.orderBy,this.model.orderDirection);
		this.totalNumberOfPages();
		this.alarmlogService.loading.subscribe(x=>this.loading=x);	
	}

	goToPage(pageNum:number){
		this.model.page = pageNum;
		this.updatePage();
	}

	hasCompaniesPermission():boolean{
		return this.permissions.hasCompaniesPermission();
	}
filter:boolean = false;
	showFilter(){
		this.filter = !this.filter;
		return false;
	}
}
