interface AlarmLogJson{
	total_record:	number;
	startdate:		number;
	gatewayname:	number;
	record_dte:		string;
	devicename:		string;
	alarmvalue:		number;
	row:			number;
	locationname:	string;
	alarmtype:		string;
	channelname:	string;
	companyname:	string;
	timezone:		number;
}

export class AlarmLog {

	static fromJSON( jsonObj ): AlarmLog{
		let log = Object.create( AlarmLog.prototype );

		let obj = Object.assign( log, jsonObj,{	
													total_record: 	jsonObj.total_record
													,startdate: 	jsonObj.startDate
													,gatewayname: 	jsonObj.gatewayName
													,record_dte: 	jsonObj.record_dte
													,devicename: 	jsonObj.deviceName
													,alarmvalue: 	jsonObj.alarmValue
													,row: 			jsonObj.rowNum
													,locationname: 	jsonObj.locationName
													,alarmtype: 	jsonObj.alarmType
													,companyname: 	jsonObj.companyName
													,channelname: 	jsonObj.channelName
													,timezone: 		jsonObj.timezone
												});
		return obj;
	}

	constructor(
			  	public total_record:	number,
				public startdate:		number,
				public gatewayname:		number,
				public record_dte:		string,
				public devicename:		string,
				public alarmvalue:		number,
				public row:				number,
				public locationname:	string,
				public alarmtype:		string,
				public channelname:		string,
				public companyname: 	string,
				public timezone:	 	number
			){ 	
    }
   
}
