<div class="row">

  <div class="col-12 mt-2">
    <button class="btn btn-success" (click)="generate()" [disabled]="loading">
      {{btn_label}}
    </button>
  </div>
  @if (message.length > 0 && !loading) {
    <div class="col-12">
      @if (success) {
        <p class="alert alert-success my-1">
          {{message}}<br>
          Please click <a href="{{path}}{{fileName}}">here {{fileName}}</a> and download the file
        </p>
      }
      @if (!success) {
        <p class="alert alert-danger my-1">
          {{message}}
        </p>
      }
    </div>
  }
  @if (loading) {
    <app-loading-spinner class="col-12"></app-loading-spinner>
  }
</div>
