<div tabindex="-1" role="dialog" aria-hidden="true" class="row justify-content-center login-frame">
  <!-- <div class="modal-dialog modal-lg d-flex justify-content-center" role="document"> -->
  <div class="col-9 col-sm-6 col-md-5 col-lg-5 col-xl-3" role="document">
    <div class="modal-content">
      <!-- <div class="login d-flex flex-row justify-content-center modal-header" >
      <img src="../assets/images/logo-horizontal.jpg">
    </div> -->
    <div class="modal-body d-flex flex-row justify-content-center pb-0">
      <h2>Login</h2>
    </div>
    <div class="modal-body login d-flex flex-row justify-content-center pt-0">
      <form name="form" (ngSubmit)="f.form.valid && onLoging()" #f="ngForm" novalidate>
        <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
          <label for="username">Login Name</label>
          <input type="text" class="form-control" name="username" [(ngModel)]="model.username" #username="ngModel"
            required />
            @if (f.submitted && !username.valid) {
              <div class="help-block">Username is required</div>
            }
          </div>
          <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
            <label for="password">Password</label>
            <input type="password" class="form-control" name="password" [(ngModel)]="model.password"
              #password="ngModel" required />
              @if (f.submitted && !password.valid) {
                <div class="help-block">Password is required</div>
              }
            </div>
            <div class="d-flex flex-row">

              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="rememberme" name="rememberme" [(ngModel)]="model.rememberme"
                  #rememberme="ngModel" />
                  <label class="custom-control-label" for="rememberme">Remember me</label>
                </div>
                <!-- <label class="col-form-label">Remember Me</label> -->
              </div>
              <div class="d-flex flex-row justify-content-center">
                <button [disabled]="loading" class="btn btn-primary mb-1 col-xs-12">Login</button>
              </div>
              <div class="d-flex flex-row justify-content-center">
                <a class="mb-1 col-xs-12" routerLink="/reset">Forgot Password</a>
              </div>
              <div class="d-flex flex-row justify-content-center">
                <a class="mb-1 col-xs-12" target="_blank" href="https://www.monitortech.com/legal/stcloud-terms.shtml">Terms of Service</a>
              </div>
              @if (error.length > 0) {
                <div class="alert alert-danger">{{error}}</div>
              }
              @if (loading) {
                <app-loading-spinner></app-loading-spinner>
              }
            </form>
          </div>
          <!-- <div class="modal-footer p-1 justify-content-center">
          <a href="https://www.monitortech.com/st-c/terms-stcservice.pdf" target="blank">Terms of service</a> |
          <a href="https://www.monitortech.com/privacy.shtml" target="blank">Privacy policy</a>
        </div> -->
      </div>
    </div>
  </div>