interface GatewayTypeJson{
	id          : number;
	type        : string;
	description : string;
}

export class GatewayType {

	static fromJSON( jsonObj ): GatewayType{
		let gatewayType = Object.create( GatewayType.prototype );

		let obj = Object.assign( gatewayType, {	
													id            : jsonObj.ID
													, type        : jsonObj.TYPE
													, description : jsonObj.DESCRIPTION
												});
		return obj;
	}

	constructor(
				public id            : number
				, public type        : string
				, public description : string
			){ 		
    }
   
}
