import { OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';


import { environment } from '../environments/environment';

// import { AuthHttp, AuthConfig, tokenNotExpired, JwtHelper } from 'angular2-jwt';
import { JwtHelperService } from '@auth0/angular-jwt';

import { Permissions } from './model/permissions';
import { UsersService } from './services/users.service';
import { DevicesService } from './services/devices.service';

@Injectable()
export class AuthService implements OnDestroy {
	private _isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public isLoggedIn: Observable<boolean> = this._isLoggedIn.asObservable();
	public error: Subject<string> = new Subject<string>();
	public hasAdminPermision: Subject<boolean> = new Subject<boolean>();
	res;

	jwtHelper: JwtHelperService = new JwtHelperService();

	userType: string = '';

	constructor(private http: HttpClient, private router: Router) {}

	login(credentials) {
		this.error.next('');
		let token = localStorage.getItem('id_token');

		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');
		if (token) headers = headers.set('Authorization', token);

		this.res = this.http
			.post(environment.apiurl + '/auth/login', JSON.stringify(credentials), { headers: headers })
			// .pipe(map((res: any) => res.json()))
			.pipe(map((res: any) => res))
			.subscribe(
				(res) => {
					let data:any = res.data;
					console.log("data", data);
					if (data.verify) {
						localStorage.setItem('verify', data.verify);
						this.router.navigate(['/verify']);
						return;
					} else if (data.set2fa) {
						localStorage.setItem('set2fa', data.SET2FA);
						this.router.navigate(['/twofactor']);
						return;
					}
					this.error.next('');
					let id_token = data.id_token || '';
					console.log("id token", id_token);
					localStorage.setItem('id_token', id_token);
					this.updateIsloggedIn();
					this.setDefaultView();
					if (this.loggedIn()) {
						let default_view = localStorage.getItem('default_view') || 'list';
						let permissions = new Permissions();
						this.hasAdminPermision.next(permissions.hasAdminPermission());
						this.router.navigateByUrl(`/devices/${default_view}`);
					}
				},
				(error) => {
					this.hasAdminPermision.next(false);
					console.log('error', error);
					localStorage.removeItem('id_token');
					// this.error = "Please check your Login Name and password, and try again";
					this.error.next('Please check your Login Name and password, and try again');
					this.router.navigateByUrl('/login');
				},
				() => {
					this.hasAdminPermision.next(false);
					this.error.next('');
					console.log('done');
				}
			);
		return this.res;
	}
	
	updateIsloggedIn () {
		this._isLoggedIn.next(this.loggedIn());
	}

	loggedIn() {
		try {
			return !this.jwtHelper.isTokenExpired(localStorage.getItem('id_token'));
		} catch (e) {
			localStorage.removeItem('id_token');
			console.log('Error unable to read token', e.message);
			return false;
		}
	}

	logout(): any {
		sessionStorage.clear();
		return localStorage.removeItem('id_token');
	}

	verify(credentials) {
		this.error.next('');
		let token = localStorage.getItem('id_token');

		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');
		if (token) headers = headers.set('Authorization', token);

		console.log("credentials", credentials);
		return this.http.post(environment.apiurl + '/auth/verify', JSON.stringify(credentials), {
			headers: headers,
		});
	}

	requestAgain(credentials) {
		this.error.next('');
		let token = localStorage.getItem('id_token');

		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');
		if (token) headers = headers.set('Authorization', token);

		return this.http.post(environment.apiurl + '/auth/requestAgain', JSON.stringify(credentials), {
			headers: headers,
		});
	}

	ngOnDestroy() {
		this.res.unsubscribe();
	}

	setDefaultView() {
		const token = localStorage.getItem('id_token');
		const decode = this.jwtHelper.decodeToken(token);
		console.log("DECODE", decode);
		const default_view = decode.view ? decode.view.toLowerCase() : 'list';
		localStorage.setItem('default_view', default_view);
	}
}
