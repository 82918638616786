import { Component, OnInit } from '@angular/core';

import { UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormControl, ReactiveFormsModule, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { UsersService } from '../../services/users.service';
import { Permissions } from '../../model/permissions';

import * as $ from 'jquery';
@Component({
	selector: 'app-user-profile-security',
	templateUrl: './user-profile-security.component.html',
	styleUrls: ['./user-profile-security.component.scss'],
})
export class UserProfileSecurityComponent implements OnInit {
	permissions: Permissions = new Permissions();
	hasError = false;
	message = '';
	btnLabel = 'Save';
	verified = false;
	isTwoFARequired = true;

	user_id = new UntypedFormControl(0);
	two_factor_auth_type = new UntypedFormControl('');
	two_factor_auth_code = new UntypedFormControl('');
	two_factor_auth_id = new UntypedFormControl('');
	temp_two_factor_auth_id = new UntypedFormControl('');
	set_two_factor = new UntypedFormControl('false');
	auth: UntypedFormGroup = this.builder.group({
		user_id: this.user_id,
		two_factor_auth_type: this.two_factor_auth_type,
		two_factor_auth_code: this.two_factor_auth_code,
		two_factor_auth_id: this.two_factor_auth_id,
		set_two_factor: this.set_two_factor,
		temp_two_factor_auth_id: this.temp_two_factor_auth_id,
	});

	constructor(private usersService: UsersService, private router: Router, private builder: UntypedFormBuilder) {}

	ngOnInit() {
		this.user_id.setValue(this.permissions.user_id);
		let id = localStorage.getItem('set2fa') || null;
		if (this.permissions.user_id) {
			this.usersService.getUserObs(+this.permissions.user_id).subscribe((x) => {
				console.log(x[0]);
				this.two_factor_auth_type.setValue(x[0].two_factor_auth_type || '');
				this.isTwoFARequired = x[0].is_2fa_required;
			});
		} else {
			this.isTwoFARequired = true;
			this.user_id.setValue('');
			this.two_factor_auth_type.setValue('sms');
			this.set_two_factor.setValue(true);
			this.two_factor_auth_id.setValue('');
			this.temp_two_factor_auth_id.setValue(id);
			console.log(this.auth.value);
			$('body').addClass('bg-img');
		}
		this.two_factor_auth_type.valueChanges.subscribe((x) => {
			this.btnLabel = 'Save';
			if (x === '') {
				this.btnLabel = 'Save';
			} else if (this.two_factor_auth_id.value !== '') {
				this.btnLabel = 'Re-Send Code';
			} else if (x.length > 0) {
				this.btnLabel = 'Send Code';
			}
		});
	}

	SaveAuthMethod() {
		this.hasError = false;
		this.message = '';
		this.usersService.getCode(this.two_factor_auth_type.value, this.temp_two_factor_auth_id.value).subscribe(
			(x) => {
				let id = x.id ? x.id : x.temp_id ? x.temp_id : '';
				this.two_factor_auth_id.setValue(id);
				this.temp_two_factor_auth_id.setValue(x.temp_id || null);
				if (x.id || x.temp_id) {
					this.btnLabel = 'Re-Send Code';
				} else {
					this.message = 'You have been Opted out from two factor authentication';
				}
			},
			(err) => {
				console.log(err);
				this.hasError = true;
				this.message = err.message;
			},
			() => {
				localStorage.removeItem('set2fa');
			}
		);
	}

	verify() {
		this.hasError = false;
		this.message = '';
		this.verified = false;
		this.usersService.saveAuthType(this.auth.value).subscribe(
			(x) => {
				console.log(x);
				this.message = x.message;
				this.verified = true;
				// this.two_factor_auth_type.setValue(x.two_factor_auth_type || '');
				// this.two_factor_auth_id.setValue('');
			},
			(err) => {
				this.hasError = true;
				this.message = err.error.message;
			}
		);
	}
}
