<div class="container">

  <nav class="row navbar navbar-toggleable-xl navbar-light bg-faded">

    <div class="" id="navbarNavAltMarkup">
      <div class="nav nav-pills">
        <a class="nav-item nav-link py-1" routerLink="channels"  routerLinkActive="active">Measurements <span class="sr-only">(current)</span></a>
        <a class="nav-item nav-link py-1" routerLink="alarmlog"  routerLinkActive="active">Alarm Log</a>
        @if (isAdmin) {
          <a class="nav-item nav-link py-1" routerLink="userslist"  routerLinkActive="active">Users</a>
        }
      </div>
    </div>
  </nav>

  <router-outlet></router-outlet>
</div>


