<div style="margin-top: 10%;">
  <div tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" class="row justify-content-center">
    <!-- <div class="modal-dialog modal-lg d-flex justify-content-center" role="document"> -->
    <div class="col-12 col-md-6 col-lg-5 col-xl-4" role="document">
      <div class="modal-content">

        <!-- <div class="modal-header">
        <img src="../assets/images/logo-horizontal.jpg">
      </div> -->

      @if (message && message.length > 0) {
        <div class="col-12 alert text-center" [ngClass]="hasError? 'alert-danger' : 'alert-success' " >
          {{message}}
        </div>
      }

      <form [formGroup]="reset" (ngSubmit)="reset.valid && resetPassword()" autocomplete="off" validate="yes">
        <div class="modal-body">
          <div class="form-group row mb-1">
            <label for="email" class="col-10 col-form-label p-1"><span class="required">*</span>Email</label>
            <div class="col-10">
              <input type="email" class="form-control p-1" name="email" id="email" [formControl]="email" autocomplete="off" />
              <div [hidden]="email.valid || email.untouched" class="alert alert-danger p-1">
                Email is required
              </div>
            </div>
          </div>
          <div class="form-group row mb-1">
            <label for="user_name" class="col-10 col-form-label p-1"><span class="required">*</span>Login Name</label>
            <div class="col-10">
              <input type="text" class="form-control p-1" name="user_name" id="user_name" [formControl]="user_name" autocomplete="off" />
              <div [hidden]="user_name.valid || user_name.untouched" class="alert alert-danger p-1">
                Login Name is required
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary"  routerLink="/login">Cancel</button>
          <button type="submit" class="btn btn-danger" [disabled]="reset.invalid">Reset</button>
        </div>
      </form>
    </div>
  </div>
</div>
</div>