import { Component, OnInit, Input } from '@angular/core';

import { Device } from '../model/device';

@Component({
  selector: 'app-silo-img',
  templateUrl: './silo-img.component.html',
  styleUrls: ['./silo-img.component.css']
})
export class SiloImgComponent implements OnInit {
	@Input() device: Device;
	@Input() showStatus?: boolean;
	private ullageContainer: Device;
	private ullageMeasurement:string = '';
	constructor() { }

	ngOnInit() {}

}
