import { Component, OnInit } from '@angular/core';
import {UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormControl, ReactiveFormsModule, FormArray } from '@angular/forms';
import { UsersService } from '../services/users.service';

declare var jQuery:any;

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
	public hasError:boolean;
	public message:string;

	email 			= new UntypedFormControl('',[Validators.required]); 
	user_name	 	= new UntypedFormControl('',[Validators.required]); 
	reset: UntypedFormGroup = this.builder.group({
													email: 				this.email,													
													user_name: 			this.user_name
												});
	constructor(private builder: UntypedFormBuilder, private uService: UsersService) { }

	ngOnInit() {		
       jQuery('body').addClass('bg-img');
	}
	resetPassword(){
		this.message = '';
		this.uService.requestResetPassword(this.reset.value);
		this.uService.hasError.subscribe(x=>this.hasError=x);
		this.uService.message.subscribe(x=>this.message=x);
	}
}
