import { Component, OnInit, ElementRef } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

import { Permissions } from '../../model/permissions';

@Component({
  selector: 'app-admin-nav',
  templateUrl: './admin-nav.component.html',
  styleUrls: ['./admin-nav.component.css'],
  animations: [
    trigger('hideAdminNav', [
      state('active', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('inactive', style({
        transform: 'translate3d(-100%, 0, 0)'
      })),
      transition('active => inactive', animate('400ms ease-in-out')),
      transition('inactive => active', animate('400ms ease-in-out'))
    ]),
  ],
  host: {
    '(document:click)': 'onClick($event)',
  }
})
export class AdminNavComponent implements OnInit {	
	public hiddenNav:boolean = true;

	permissions:Permissions = new Permissions();

	companiesPermission 	= true;
	usersPermission 		= true;
	locationsPermission 	= true;
	gatewaysPermission 		= true;
	silosPermission 		= true;
	codesPermission 		= true;
	adminPermission			= true;
	userType				= '';
	private state:string = 'inactive';
	constructor(private elementRef: ElementRef) { }

	ngOnInit() {
		this.companiesPermission 	= this.permissions.hasCompaniesPermission();
		this.usersPermission 		= this.permissions.hasUsersPermission();
		this.locationsPermission 	= this.permissions.hasLocationsPermission();
		this.gatewaysPermission 	= this.permissions.hasGatewaysPermission();
		this.silosPermission 		= this.permissions.hasSilosPermission();
		this.codesPermission 		= this.permissions.hasCodesPermission();	
		this.adminPermission 		= this.permissions.hasAdminPermission();
	}

	onClick(event) {
		this.state = (this.state === 'inactive' ? 'active' : 'inactive');
	   if (!this.elementRef.nativeElement.contains(event.target)){ // or some similar check
	   	     this.hiddenNav = true;
	   	}
	}

}
