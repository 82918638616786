<form [formGroup]="auth">
  <div class="container">
    <div class="mb-1 d-flex justify-content-center align-items-center mx-auto" style="height: 70vh">
      <div class="col-sm-12 col-md-8 col-lg-6">
        @if (!verified) {
          <div class="card" [ngClass]="{ 'border-success': !hasError && message.length > 0, 'border-danger': hasError }">
            <div class="card-header font-weight-bold">Two Factor Authentication</div>
            <div class="card-body">
              @if (two_factor_auth_id.value.trim() == '') {
                <div class="form-group">
                  <div class="col-12 px-0">Select one of the following and code will be sent to you to verify your identity</div>
                  @if (!isTwoFARequired) {
                    <span>
                      <input type="radio" class="p-1" value="" id="none" name="two_factor_auth_type" [formControl]="two_factor_auth_type" />
                      <label for="none" class="p-1 mr-4">Opt Out</label>
                    </span>
                  }
                  <input type="radio" class="p-1" value="sms" id="sms" name="two_factor_auth_type" [formControl]="two_factor_auth_type" />
                  <label for="sms" class="p-1 mr-4">Text Message</label>
                  <input type="radio" class="p-1" value="email" id="_email" name="two_factor_auth_type" [formControl]="two_factor_auth_type" />
                  <label for="_email" class="p-1">Email</label>
                </div>
              }
              @if (two_factor_auth_id.value !== '') {
                <div class="form-group">
                  <label for="two_factor_auth_code" class="col-form-label mr-4">Code</label>
                  <input
                    type="text"
                    class="p-1"
                    value=""
                    placeholder="000000"
                    id="two_factor_auth_code"
                    name="two_factor_auth_code"
                    [formControl]="two_factor_auth_code"
                    />
                  </div>
                }
                @if (message && message.length > 0) {
                  <div class="col" [ngClass]="hasError ? 'text-danger' : 'text-success'">
                    {{ message }}
                    @if (!permissions.user_id) {
                      <div><a [routerLink]="'/login'">Please login again</a></div>
                    }
                    @if (permissions.user_id) {
                      <div><a [routerLink]="'..'">Please go back to profile</a></div>
                    }
                  </div>
                }
              </div>
              <!-- <div class="card-footer d-flex justify-content-center py-1" *ngIf="two_factor_auth_id.value.length == 0">
              <button class="btn btn-success" (click)="SaveAuthMethod()">{{ btnLabel }}</button>
            </div> -->
            <div class="card-footer d-flex justify-content-center py-1">
              @if (two_factor_auth_id.value.trim() !== '') {
                <button
                  class="btn btn-success"
                  [disabled]="two_factor_auth_code.value.trim() == ''"
                  (click)="verify()"
                  >
                  Verify
                </button>
              }
              <button class="btn btn-success ml-2" (click)="SaveAuthMethod()">{{ btnLabel }}</button>
            </div>
          </div>
        }

        @if (verified) {
          <div class="card" [ngClass]="{ 'border-success': !hasError && message.length > 0, 'border-danger': hasError }">
            <div class="card-header font-weight-bold">Two Factor Authentication</div>
            <div class="card-body">
              {{ message }}.<br />
              Return to @if (permissions.user_id) {
              <a [routerLink]="'..'">Profile</a>
            }
            @if (!permissions.user_id) {
              <a [routerLink]="'/login'">Login</a>
            }
          </div>
        </div>
      }
    </div>
  </div>
</div>
</form>
