<div class="row">
  <div class="col-12">
    <h3>Users</h3>
  </div>
  @if (message && message.length > 0) {
    <div class="col-12 alert text-center" [ngClass]="hasError ? 'alert-danger' : 'alert-success'">
      {{ message }}
    </div>
  }
  <div class="col-12 pb-1">
    <div class="row">
      <app-admin-nav></app-admin-nav>
      @if (permissions.userType !== 'E') {
        <button routerLink="/administration/user/edit/0" class="btn btn-primary py-0">
          <i class="fa fa-plus"></i>Add User
        </button>
      }
      <ng-select
        [items]="user_statuses"
        bindLabel="name"
        bindValue="value"
        name="status_cde"
        placeholder="Select user type"
        (change)="filter('status_cde', model.status_cde, true)"
        [(ngModel)]="model.status_cde"
        class="col-12 col-sm-6 col-md-3"
      ></ng-select>
      <input
        type="text"
        name="search"
        value=""
        class="col-12 col-sm-6 col-md-3 form-control"
        placeholder="keyword"
        (keyup)="filter('name,user_name,user_type_desc,company', $event.target.value, false)"
        />
      </div>
    </div>
    <div class="col-12">
      <table class="table table-striped tableList">
        <thead class="table-dark">
          <tr>
            <th (click)="sort('company')">
              <a [className]="(sortField | async) == 'company' ? (sortDirection | async) : 'sort'">Company</a>
            </th>
            <th (click)="sort('name')">
              <a [className]="(sortField | async) == 'name' ? (sortDirection | async) : 'sort'">User Name</a>
            </th>
            <th (click)="sort('user_name')">
              <a [className]="(sortField | async) == 'user_name' ? (sortDirection | async) : 'sort'">Login Name</a>
            </th>
            <th (click)="sort('user_type_desc')">
              <a [className]="(sortField | async) == 'user_type_desc' ? (sortDirection | async) : 'sort'">Privileges</a>
            </th>
            <th>Status</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          @for (user of rowItems; track user) {
            <tr>
              <td>{{ user.company }}</td>
              <td>{{ user.name }}</td>
              <td>{{ user.user_name }}</td>
              <td>{{ user.user_type_desc }}</td>
              <td>{{ user.status_cde.toLowerCase() == 'a' ? 'Active' : 'Inactive' }}</td>
              <td nowrap>
                <div class="btn-group" role="group" aria-label="buttons">
                  <button class="btn btn-info p-1" routerLink="/administration/user/edit/{{ user.user_id }}">
                    <i class="fa fa-pencil"></i>Edit
                  </button>
                  @if (permissions.userType !== 'E') {
                    <button class="btn btn-danger p-1" (click)="resetPassword(user.user_name, user.email)">
                      <i class="fa fa-unlock-alt" aria-hidden="true"></i>Reset Password
                    </button>
                  }
                </div>
              </td>
            </tr>
          }
        </tbody>
      </table>

      <div class="row">
        <div class="col-12">
          <app-pager [allItems]="users | async" (pagedItemsNew)="setPagedItem($event)"></app-pager>
        </div>
      </div>
    </div>
  </div>
