import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

import { Store, Select } from '@ngxs/store';
import { UserState } from '../../store/states/users.state';
import { FetchUsers, UserSort, UserFilter } from '../../store/actions/users.action';

import { UsersService } from '../../services/users.service';
import { User } from '../../model/user';
import { GroupsService } from '../../services/groups.service';
import { Group } from '../../model/group';
import { Permissions } from '../../model/permissions';

import { NgSelectModule, NgOption } from "@ng-select/ng-select";
import { PagerComponent } from '../../pager/pager.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
	public permissions = new Permissions();
	@Select(UserState.getUsers) users: Observable<User[]>
  	@Select(UserState.getSortDirection) sortDirection: Observable<string>
  	@Select(UserState.getSortField) sortField: Observable<string>
	public hasError:boolean;
	public message:string;
	public groups: Observable<Group[]>;
  	@ViewChild(PagerComponent) pager: PagerComponent;

  	public rowItems:User[] = [];

	public user_statuses: NgOption[] = [
		{ name: "All users", value: 'all' },
		{ name: "Active Users", value: 'a' },
		{ name: "Inactive users", value: 'z' }
	];
		
	pageRange=[];

	model= {
			  page: 1
			, recordsPerPage: 10
			, totalPages:0
			, status_cde: 'a'
	}
	

	constructor(private usersService: UsersService, private groupsService:GroupsService, private router: Router, private store: Store) { 
		this.groupsService.getGroups();
		this.groups	= this.groupsService.groups;
	}

	ngOnInit() {
		if( !this.permissions.hasUsersPermission() ){
		    this.router.navigateByUrl('devices');
		}
		this.filter('status_cde','a', true);
		this.store.dispatch(new FetchUsers());
	}

	resetPassword(user_name, email){
		this.message = '';
		let reset = {'user_name':user_name, 'email':email};
		this.usersService.resetPasswordAdmin(reset);
		this.usersService.hasError.subscribe(x=>this.hasError=x);
		this.usersService.message.subscribe(x=>this.message=x);
	}

  	getGroupByID(id):any{
		let jj:any = {};
		this.groups.subscribe((x)=>x.filter((a)=>a.group_id === id).map((b)=>{ jj = b;}));
		return jj;
	}

	
	setPagedItem(rows) {
		this.rowItems = rows;
	}

	sort(field){
		this.store.dispatch(new UserSort(field));
		this.pager.setPage(1)
	}

	filter(field,str, exactMatch){
		if(field === 'status_cde' && str === 'all'){
			field = '';
			str = '';
		}
		this.store.dispatch(new UserFilter({filterByList:field,searchString:str, exactMatch}));
	}
}
