import { Component, OnInit, Input } from '@angular/core';

import { Device } from '../model/device';

@Component({
  selector: 'app-silo-summary',
  templateUrl: './silo-summary.component.html',
  styleUrls: ['./silo-summary.component.css']
})
export class SiloSummaryComponent implements OnInit {
	@Input() device: Device;
	public locationDeviceName:string;
	constructor() { }

	ngOnInit() {
		this.locationDeviceName = this.device.location_name + ' / ' + this.device.device_name;
	}
}
