<table class="table tableFixed" [ngClass]="{ tableFixed: trefPosition }">
  <thead class="thead-dark" [style.width]="trefPosition ? trefPositionWidth : ''" #tref>
    <tr>
      <th (click)="sortBy('l.name')" class="sortable">
        Location
        @if (model.orderBy !== 'l.name') {
          <i class="fa fa-sort" aria-hidden="true"></i>
        }
        @if (model.orderBy === 'l.name' && model.orderDirection === 'DESC') {
          <i class="fa fa-sort-asc" aria-hidden="true"></i>
        }
        @if (model.orderBy === 'l.name' && model.orderDirection === 'ASC') {
          <i class="fa fa-sort-desc" aria-hidden="true"></i>
        }
      </th>
      <th (click)="sortBy('d.name')" class="sortable">
        Silo Name
        @if (model.orderBy !== 'd.name') {
          <i class="fa fa-sort" aria-hidden="true"></i>
        }
        @if (model.orderBy === 'd.name' && model.orderDirection === 'DESC') {
          <i class="fa fa-sort-asc" aria-hidden="true"></i>
        }
        @if (model.orderBy === 'd.name' && model.orderDirection === 'ASC') {
          <i class="fa fa-sort-desc" aria-hidden="true"></i>
        }
      </th>
      <th (click)="sortBy('d.material')" class="sortable">
        Silo Content
        @if (model.orderBy !== 'd.material') {
          <i class="fa fa-sort" aria-hidden="true"></i>
        }
        @if (model.orderBy === 'd.material' && model.orderDirection === 'DESC') {
          <i class="fa fa-sort-asc" aria-hidden="true"></i>
        }
        @if (model.orderBy === 'd.material' && model.orderDirection === 'ASC') {
          <i class="fa fa-sort-desc" aria-hidden="true"></i>
        }
      </th>
      <th>Measurement</th>
      <th (click)="sortBy('dist.timestamp')" class="sortable">
        Time/MM/DD/YY
        @if (model.orderBy !== 'dist.timestamp') {
          <i class="fa fa-sort" aria-hidden="true"></i>
        }
        @if (model.orderBy === 'dist.timestamp' && model.orderDirection === 'DESC') {
          <i class="fa fa-sort-asc" aria-hidden="true"></i>
        }
        @if (model.orderBy === 'dist.timestamp' && model.orderDirection === 'ASC') {
          <i class="fa fa-sort-desc" aria-hidden="true"></i>
        }
      </th>
      <th>Level Alarm</th>
      <th>System Alarm</th>
      <th (click)="toggleCollapseAll($event)" class="sortable">Expand all</th>
    </tr>
  </thead>
  <tbody #tref2>
    @for (device of devices | async; track device) {
      <tr class="monitor" [ngClass]="device.getAlertType()">
        <td>
          {{ device.location_name }}
        </td>
        <td>
          {{ device.device_name }}
        </td>
        <td>
          {{ device.material }}
        </td>
        <td>
          {{ device.getMeasurement() }}
        </td>
        <td>
          {{ device.timestamp | date: 'shortTime':'' + device.timezone }} {{ device.timestamp | date: 'shortDate' }}
          <!-- timezone = {{device.timezone }} |
          {{ device.timestamp | date: 'short' }} -->
        </td>
        <td>
          {{ device.getAlert() }}
        </td>
        <td
					[ngClass]="{
						'font-weight-bold': device.islocked === 1,
						'red-text': device.getStatus() !== 'A-OK' && device.getStatus() !== 'Suspended'
					}"
          >
          {{ device.getStatus() }}
        </td>
        <td>
          <a
            data-toggle="collapse"
            data-parent="#accordion"
            aria-expanded="true"
            aria-controls="collapseOne"
            (click)="toggleCollapse($event, device)"
            href="javascript:void(0)"
            >
            @if (!isOpen(device.device_id)) {
              <span>Expand</span>
            }
            @if (isOpen(device.device_id)) {
              <span>Collapse</span>
            }
          </a>
        </td>
      </tr>
      <tr role="tabpanel" aria-labelledby="headingOne" [hidden]="!isOpen(device.device_id)">
        <!-- [ngClass]="isOpen(device.device_id)?'show':''"  -->
        <td colspan="8" id="summary_{{ device.device_id }}" class="monitor" [ngClass]="device.getAlertType()">
          <app-table-view-summary
            [device]="device"
            [open]="{ open: isOpen(device.device_id), device_id: device.device_id }"
            [model]="model"
            (updateParentDevice)="updateDevice($event)"
          ></app-table-view-summary>
        </td>
      </tr>
    }
  </tbody>
</table>

<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog p-1" style="max-width: 90%" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div #placeholder></div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
