import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {Router, ActivatedRoute, Params}  from '@angular/router';
import {UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormControl, ReactiveFormsModule, FormArray } from '@angular/forms';

import { CodesService } from '../../services/codes.service';
import { Code } from '../../model/code';
import { Permissions } from '../../model/permissions';

@Component({
  selector: 'app-code-edit',
  templateUrl: './code-edit.component.html',
  styleUrls: ['./code-edit.component.css']
})
export class CodeEditComponent implements OnInit {
	hasError:boolean = false;
	message:string = '';
	permissions:Permissions = new Permissions();
	userType = '';
	codes: Observable<Code[]>;

	id			= new UntypedFormControl('', [ Validators.required ]);
	type 		= new UntypedFormControl('', [ Validators.required ]);
	code 		= new UntypedFormControl('', [ Validators.required ]);
	decode 		= new UntypedFormControl('', [ Validators.required ]);
	sort_num 	= new UntypedFormControl('', [ Validators.required ]);
	
	codeForm: UntypedFormGroup = this.builder.group({
												  id: 		this.id
												, type: 	this.type
												, code: 	this.code
												, decode: 	this.decode
												, sort_num: this.sort_num
											});

  constructor(private codesService: CodesService, private router: Router, private activatedRoute: ActivatedRoute, private builder: UntypedFormBuilder, ) { 

		if(!this.permissions.hasCodesPermission()){
			router.navigateByUrl('/devices');
		}
		this.userType = this.permissions.userType.trim().toUpperCase();
	}

  ngOnInit() {
  		let cId;
		this.activatedRoute.params.subscribe((params: Params) => {
			cId = params['id'];
			this.codesService.getCodesForAdmin(cId);
		});

		this.codesService.codes.subscribe(
													gts=> gts
															.filter( gt=> gt.id == cId )
															.map( g=>{
																		this.id			= new UntypedFormControl(g.id, [ Validators.required ]);
																		this.type 		= new UntypedFormControl(g.type, [ Validators.required ]);
																		this.code 		= new UntypedFormControl(g.code, [ Validators.required ]);
																		this.decode 	= new UntypedFormControl(g.decode, [ Validators.required ]);
																		this.sort_num 	= new UntypedFormControl(g.sort_num, [ Validators.required ]);

																		this.codeForm = this.builder.group({
																												  id: 		this.id
																												, type: 	this.type
																												, code: 	this.code
																												, decode: 	this.decode
																												, sort_num: this.sort_num
																											})
																	}
																)
												 );
  }

  submitCode(){
  	// this.codesService.saveCode(this.codeForm.value);
  	this.hasError = false;
	this.message = '';
	this.codesService.saveCode(this.codeForm.value);
	this.codesService.hasError.subscribe(x=>this.hasError=x);
	this.codesService.message.subscribe(x=>this.message=x);
	this.codesService.id.subscribe(x=> { 
											this.codeForm.controls['id'].setValue(x); 
										} );
  }
}
