import {  HttpClient, } from '@angular/common/http';
import { Injectable } 							from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';


import { environment} from '../../environments/environment';
import { Code } from '../model/code';
import { UtilService } from './util.service';

@Injectable()
export class CodesService {

	public loading:Subject<boolean> 	= new Subject<boolean>();
	public hasError:Subject<boolean> 	= new Subject<boolean>();
	public message:Subject<string> 		= new Subject<string>();
	public id:Subject<number> 	= new Subject<number>();

	private _codes: Subject<Code[]> 		= new Subject<Code[]>(  );
	public codes:   Observable<Code[]> 	= this._codes.asObservable();

	private codesUrl = `${environment.apiurl}/location/getCodes`;
	constructor (private util: UtilService, private http: HttpClient, private router: Router) {}

	public getCodes(): void {
		this.util._getData( this.codesUrl, {
			page: 1,
			recordsPerPage: 10000000
		} )
			.subscribe( res =>{
				const codes = res.data;
				localStorage.setItem( 'codes', JSON.stringify(this.util.convertToUppercaseKeys(codes)) );
			})
	}

	public getCodesForAdmin(model): void {
		let id 				= model.id || '';
		let type 			= model.type || '';
		let code 			= model.code || '';
		let decode 			= model.decode || '';
		let page 			= model.page || 1;
		let recordsPerPage 	= model.recordsPerPage || 1000000;

		const payload: any = { page, recordsPerPage };
		if(model.id) payload.id = model.id;
		if(model.type) payload.type = model.type;
		if(model.code) payload.code = model.code;
		if(model.decode) payload.decode = model.decode;
		if(model.id) payload.id = model.id;

		// + '?method=getCodes&id=' + id + '&type=' + type + '&code=' + code + '&decode=' + decode + '&page=' + page + '&recordsPerPage=' + recordsPerPage 
		this.util._getData( this.codesUrl, payload )
			.pipe(map(ret=>ret.data))
			.subscribe( code =>{
				this._codes.next( code.map(jsonobj => Code.fromJSON( jsonobj )));
				this.loading.next(false);
			})
	}

	public saveCode(body): void {
		let url = environment.apiurl + '/location/saveCode';
		this.util._postData( url,body )
			.pipe(map(ret=>ret.data))
			.subscribe( 
							res=>{
									this.id.next(res.id);
									this.hasError.next(false); 
									this.message.next('Code is saved successfully.')
									this.router.navigateByUrl('/administration/code/edit/' + res.id);
								},
							()=>{console.log('Error saving code'); this.hasError.next(true); this.message.next('There was an error saving code information. Please try again.')},
							()=>{console.log('saving code completed');this.hasError.next(false); this.message.next('Code is saved successfully.')}
						)
	}
}
