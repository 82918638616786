import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import * as $ from 'jquery';
// declare var jQuery:any;

import { AuthService } from './auth.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
	// private locations: Location[];
	private date: Date = new Date();
	errorMessage: string;
	loggedIn: boolean = false;

	// constructor( private locationService: LocationService){
	// }
	constructor(private authService: AuthService, private router: Router) {
		this.loggedIn = authService.loggedIn();
	}

	ngOnInit() {
		this.loggedIn = this.authService.loggedIn();

		if (this.authService.loggedIn()) {
			$('body').removeClass('bg-img');
		}
	}
}
