import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormControl, ReactiveFormsModule, FormArray } from '@angular/forms';
import { HeaderNavComponent } from '../header-nav/header-nav.component';
import { AuthService } from '../auth.service';
import { UsersService } from '../services/users.service';
import { Observable, BehaviorSubject, Subject } from 'rxjs';

import { User } from '../model/user';

import * as $ from 'jquery';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.css']
})
export class PasswordRecoveryComponent implements OnInit {

	private user: Observable<User[]>;
	hasError:boolean = false;
	message:string 	 = '';
	psdRecover = true;
	password 			= new UntypedFormControl('', [ Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/) ]);
	confirmpassword 	= new UntypedFormControl(''); 
	uuid 				= new UntypedFormControl(''); 
	user_name 			= new UntypedFormControl(''); 
	email 				= new UntypedFormControl(''); 
	userPassword: UntypedFormGroup = this.builder.group({
													password: 			this.password, 
													confirmpassword: 	this.confirmpassword,
													uuid: 				this.uuid,
													user_name: 			this.user_name,
													email: 				this.email													
												});

	constructor( private builder: UntypedFormBuilder, private activeRoute: ActivatedRoute, private auth: AuthService, private uService: UsersService ) { }

	ngOnInit() {  	

       	$('body').addClass('bg-img');
		let id		= this.activeRoute.snapshot.params['uuid']; 
		this.uService.recoverPassword(id);
		this.user = this.uService.user;

		this.user.subscribe(x=>
								x.map(y=>{
											this.uuid				= new UntypedFormControl(id); 
											this.user_name			= new UntypedFormControl(y.user_name); 
											this.email				= new UntypedFormControl(y.email); 
											this.userPassword 		= this.builder.group({
																							password:			this.password,
																							confirmpassword:	this.confirmpassword,
																							uuid: 				this.uuid,
																							user_name: 			this.user_name,
																							email: 				this.email	
																						});
										})
							);
	}
	savePassword(psd){
		this.uService.updatePassword(psd.value);
		this.uService.hasError.subscribe(x=>{
			this.hasError=x;
		});
		this.uService.message.subscribe(x=>this.message=x);
	}
}
