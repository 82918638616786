
import { Injectable } 							from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';


import { environment} from '../../environments/environment';
import { State } from '../model/state';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class StatesService {

  constructor(private util:UtilService) { }

  getStates(): Observable<State[]> {
		let url = environment.apiurl + '/location/getStates';
	   return this.util._requestData(url)
      .pipe(map(res => res.data.map(jsonobj => State.fromJSON(jsonobj))))
  }

}
