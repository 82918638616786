import { Injectable } from '@angular/core';
import {  HttpClient, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject, throwError } from 'rxjs';
import { map, catchError, } from 'rxjs/operators';

import { Router } from '@angular/router';

import { AuthService } from '../auth.service';



@Injectable()
export class UtilService {

	constructor( private http: HttpClient, private router: Router, private auth: AuthService ) { }

	convertToUppercaseKeys(jsonArray) {
		return jsonArray.map((item) => {
		  const newItem = {};
		  for (const key in item) {
			if (item.hasOwnProperty(key)) {
			  const uppercaseKey = key.toUpperCase();
			  newItem[uppercaseKey] = item[key];
			}
		  }
		  return newItem;
		});
	  }


	public  extractData(res) {
		// console.log('headers',res.headers.get('Authorization'));

		// let data = res.json();
		// if( data.id_token ){
		// 	let id_token = JSON.stringify( {value: JSON.stringify(data.id_token || '1.2.3')} );
		// 	localStorage.setItem( 'id_token', id_token );
		// }else{
		// 	localStorage.removeItem('id_token'); 
		// }
		// return data;

		// return res.json();
		return res;
	}

	public handleError (error: HttpErrorResponse | any) {
		console.log('error', error)
		let errMsg: string;
		console.log("error.status", error.status, error.status == 401);
		if (error instanceof HttpErrorResponse) {
		//   const body = error.json() || '';
		  const body = error || '';
		  const err = (body && body.error) || JSON.stringify(body);
		  errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
		} else {
		  errMsg = error.message ? error.message : error.toString();
		}
		if(error.status && error.status == 401){
			localStorage.removeItem('id_token');
		}
		// return Observable.throw(error.json() || error);
		// console.log("throw errMsg", errMsg);
		// console.log("throw error", error);
		return throwError(error);
	}

	public _requestData( url ): Observable<any>{	
		let headers = new HttpHeaders();
 		let token = localStorage.getItem("id_token");
 		// this.isLoggedIn();
		if (token) headers = headers.set("Authorization", token);
		
		return this.http.get( url , {headers:headers})
						.pipe(map(this.extractData), catchError(this.handleError));
	}

	public _getData( url, params: { [key: string] : any } = {} ): Observable<any>{	
		let headers = new HttpHeaders();
 		let token = localStorage.getItem("id_token");
 		// this.isLoggedIn();
		headers = headers.set("Authorization", token);
		return this.http.get( url , {headers:headers, params})
						.pipe(map(this.extractData), catchError(this.handleError));
	}

	public _postData( url, body ): Observable<any>{	
		let headers = new HttpHeaders();
		let bodyString = JSON.stringify(body);         	
 		let token = localStorage.getItem("id_token");
 		// this.isLoggedIn();
		if (token) headers = headers.set("Authorization", token);
		headers = headers.set('Content-Type', 'application/json');
        // let options	= new RequestOptions({ headers: headers });
		
		// return this.http.post( url ,body, options)
		return this.http.post( url ,body, { headers: headers })
						.pipe(map(this.extractData), catchError(this.handleError));
	}

	isLoggedIn(){
		return true;
		// if( !this.auth.loggedIn() ){
		// 						this.router.navigateByUrl('/login');
		// 					}
	}
}
