import { Gateway } from '../../model/gateway';

export class FetchGateways {
    static readonly type = '[GATEWAY] Fetch gateways';
}


export class Checked {
    static readonly type = '[GATEWAY] Checked'

    constructor(public payload: number) {}
}

export class GatewayFilter {
    static readonly type = '[GATEWAY] Gateways Filter'

    constructor(public payload: {filterByList:string,searchString:string, exactMatch: boolean}) {}
}

export class GatewaySort {
    static readonly type = '[GATEWAY] Gateway Sort'

    constructor(public payload: string) {}
}
