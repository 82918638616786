// import { AuthHttp, AuthConfig, tokenNotExpired, JwtHelper } from 'angular2-jwt';
import { JwtHelperService } from '@auth0/angular-jwt';

export class Permissions {
	companiesPermission = false;
	usersPermission = false;
	locationsPermission = false;
	gatewaysPermission = false;
	silosPermission = false;
	addSilosPermission = false;
	codesPermission = false;
	adminPermission = false;
	userType = '';
	user_id = '';
	view = 'card';
	private signalStrengthPermission = false;

	jwtHelper: JwtHelperService = new JwtHelperService();

	constructor() {
		try {
			let token = localStorage.getItem('id_token');
			if (token) {
				let decode = this.jwtHelper.decodeToken(token);
				let type = decode.type.toUpperCase();
				this.user_id = decode.user_id;
				this.view = decode.view ? decode.view.toLowerCase() : 'list';

				this.userType = type;
				// console.log(type, 'user type');
				if (type.trim().toUpperCase() == 'A' || type.trim().toUpperCase() == 'B') {
					/*Admin and super User*/
					this.companiesPermission = true;
					this.usersPermission = true;
					this.locationsPermission = true;
					this.gatewaysPermission = true;
					this.silosPermission = true;
					this.addSilosPermission = true;
					this.codesPermission = false;
					this.adminPermission = true;
					this.signalStrengthPermission = true;
				} else if (type.trim().toUpperCase() == 'D' || type.trim().toUpperCase() == 'E') {
					this.companiesPermission = false;
					this.usersPermission = true;
					this.locationsPermission = true;
					this.gatewaysPermission = true;
					this.silosPermission = true;
					this.addSilosPermission = false;
					this.codesPermission = false;
					this.adminPermission = true;
				}
			} else {
				this.companiesPermission = false;
				this.usersPermission = false;
				this.locationsPermission = false;
				this.gatewaysPermission = false;
				this.silosPermission = false;
				this.addSilosPermission = false;
				this.codesPermission = false;
				this.adminPermission = false;
			}
		} catch (e) {
			/*on logout, the token is gone, and there will be an errror from jwtHelper*/
			this.companiesPermission = false;
			this.usersPermission = false;
			this.locationsPermission = false;
			this.gatewaysPermission = false;
			this.silosPermission = false;
			this.addSilosPermission = false;
			this.codesPermission = false;
			this.adminPermission = false;
			console.log('permission error');
		}
	}

	public hasCompaniesPermission() {
		return this.companiesPermission;
	}
	public hasUsersPermission() {
		return this.usersPermission;
	}
	public hasLocationsPermission() {
		return this.locationsPermission;
	}
	public hasGatewaysPermission() {
		return this.gatewaysPermission;
	}
	public hasSilosPermission() {
		return this.silosPermission;
	}
	public hasAddSilosPermission() {
		return this.addSilosPermission;
	}
	public hasCodesPermission() {
		return this.codesPermission;
	}
	public hasAdminPermission() {
		return this.adminPermission;
	}
	public viewSignalStrength() {
		return this.signalStrengthPermission;
	}
	public hasManagerOrTechnicianRole() {
		return ['D', 'F'].includes(this.userType);
	}
	public canSuspendMeasurement() {
		return ['A', 'B', 'D', 'E'].filter((p) => p === this.userType).length > 0;
	}
}
