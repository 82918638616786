import { Component, OnInit, OnDestroy, ElementRef, Compiler } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormControl, ReactiveFormsModule, FormArray } from '@angular/forms';

import { UsersService } from '../../services/users.service';
import { GroupsService } from '../../services/groups.service';
import { LocationsService } from '../../services/locations.service';
import { DevicesService } from '../../services/devices.service';
import { CountriesService } from '../../services/countries.service';
import { StatesService } from '../../services/states.service';

import { User } from '../../model/user';
import { Group } from '../../model/group';
import { Location } from '../../model/location';
import { Device } from '../../model/device';
import { Permissions } from '../../model/permissions';
import { Country } from '../../model/country';
import { State } from '../../model/state';

declare var $: any;

@Component({
	selector: 'app-user-profile',
	templateUrl: './user-profile.component.html',
	styleUrls: ['./user-profile.component.css'],
})
export class UserProfileComponent implements OnInit {
	public permissions = new Permissions();
	private user: Observable<User[]>;
	public countries: Observable<Country[]>;
	public states: Observable<State[]>;

	private user$;
	private groups$;
	private locations;
	private userLocations$;
	private userDevices;
	private devices$;
	private loading = false;
	public showAddInfo = false;

	public hasError: boolean;
	public message: string;

	// private user_id;
	private iid;
	user_id = new UntypedFormControl(0);
	user_name = new UntypedFormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(20)]);
	password = new UntypedFormControl('', [
		Validators.required,
		Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/),
	]);
	confirmpassword = new UntypedFormControl('');
	addr1 = new UntypedFormControl('');
	addr2 = new UntypedFormControl('');
	city = new UntypedFormControl('');
	company = new UntypedFormControl('');
	country_id = new UntypedFormControl('');
	email = new UntypedFormControl('', [Validators.required, Validators.pattern(/\S+@\S+\.\S+/)]);
	fax = new UntypedFormControl('');
	name = new UntypedFormControl('');
	phone = new UntypedFormControl('', [Validators.required]);
	state_id = new UntypedFormControl('');
	status_cde = new UntypedFormControl('');
	zip = new UntypedFormControl('');
	user_type = new UntypedFormControl('F');
	group_id = new UntypedFormControl('');
	queryString = new UntypedFormControl('');
	two_factor_auth_type = new UntypedFormControl('');
	default_view = new UntypedFormControl('list');

	// userFormss: FormGroup;
	userFormss: UntypedFormGroup = this.builder.group({
		user_id: this.user_id,
		user_name: this.user_name, //[ Validators.required, Validators.minLength(3), Validators.this.user_name,
		// password: 	this.password,
		addr1: this.addr1,
		addr2: this.addr2,
		city: this.city,
		company: this.company,
		country_id: this.country_id,
		email: this.email,
		fax: this.fax,
		name: this.name,
		phone: this.phone,
		state_id: this.state_id,
		status_cde: this.status_cde,
		zip: this.zip,
		user_type: this.user_type,
		group_id: this.group_id,
		queryString: this.queryString,
		two_factor_auth_type: this.two_factor_auth_type,
		default_view: this.default_view,
	});

	userPassword: UntypedFormGroup = this.builder.group({ user_id: this.user_id, password: this.password, confirmpassword: this.confirmpassword });

	disableBtn = false;
	userTypes: any = [];
	selectedLocations: any = [];
	filteredDevices: any = [];
	selectedDevices: any = [];

	constructor(
		private groupsService: GroupsService,
		private usersService: UsersService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private builder: UntypedFormBuilder,
		private locationsService: LocationsService,
		private deviceService: DevicesService,
		private elRef: ElementRef,
		private countriesService: CountriesService,
		private statesService: StatesService
	) {
		this.usersService.hasError.subscribe((x) => (this.hasError = x));
		this.usersService.message.subscribe((x) => (this.message = x));

		const codes = JSON.parse(localStorage.getItem('codes'));

		for (let x of codes) {
			if (x.TYPE.toLowerCase() === 'user_type') {
				this.userTypes.push(x);
			}
		}
	}

	ngOnInit() {
		this.showAddInfo = ['A', 'B'].filter(type=>type===this.permissions.userType).length === 0;
		this.iid = 0;
		this.iid = this.permissions.user_id;
		this.usersService.getUser(this.iid);
		this.locationsService.getLocations();
		this.locations = this.locationsService.locations;

		this.locationsService.getUserLocations(this.iid, this.group_id.value);
		this.userLocations$ = this.locationsService.userLocations;
		// this.setUserLocations();

		this.groupsService.getGroups();
		this.groups$ = this.groupsService.groups;

		this.deviceService.getUserDevices(this.iid);
		this.userDevices = this.deviceService.userDevices;
		// this.setUserDevices();
		this.countries = this.countriesService.getCountries();
		this.states = this.statesService.getStates();

		this.user = this.usersService.user;

		this.user$ = this.user.subscribe((usr) => {
			this.loading = true;
			usr
				.filter((x) => x.user_id == this.iid)
				.map((u) => {
					const view = typeof u.default_view !== 'undefined' && u.default_view && u.default_view.length > 0 ? u.default_view : 'list';
					this.user_id.setValue(u.user_id);
					this.user_name.setValue(u.user_name);
					this.addr1.setValue(u.addr1);
					this.addr2.setValue(u.addr2);
					this.city.setValue(u.city);
					this.company.setValue(u.company);
					this.country_id.setValue(u.country_id);
					this.email.setValue(u.email);
					this.fax.setValue(u.fax);
					this.name.setValue(u.name);
					this.phone.setValue(u.phone);
					this.state_id.setValue(u.state_id);
					this.status_cde.setValue(u.status_cde.trim().toLowerCase());
					this.zip.setValue(u.zip);
					this.user_type.setValue(u.user_type.trim().toUpperCase());
					this.group_id.setValue(u.group_id);
					this.queryString.setValue('');
					this.two_factor_auth_type.setValue(u.two_factor_auth_type);
					this.default_view.setValue(view);

					this.user_id = new UntypedFormControl(u.user_id);
					this.password = new UntypedFormControl('', [
						Validators.required,
						Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/),
					]);
					this.confirmpassword = new UntypedFormControl('');
					this.userPassword = this.builder.group({ user_id: this.user_id, password: this.password, confirmpassword: this.confirmpassword });
					this.loading = false;
				});
		});
	}

	saveUser() {
		this.message = '';
		let userObj = { user: this.userFormss.value };
		console.log(userObj);
		this.usersService.saveUser(userObj, this.iid);
		this.usersService.hasError.subscribe((x) => {
			this.hasError = x;
			if (!x) {
				localStorage.setItem('default_view', this.userFormss.value.default_view);
			}
		});
		this.usersService.message.subscribe((x) => (this.message = x));
		this.usersService.user_id.subscribe((x) => {
			this.userFormss.controls['user_id'].setValue(x);
			// this.setUserLocations();
		});

		// this.userLocations 	= this.locationsService.userLocations;
	}

	preventSubmit() {
		this.hasError = false;
		this.message = '';
		return false;
	}

	savePassword(v) {
		this.usersService.savePassword(v.value);
		this.usersService.hasError.subscribe((x) => {
			if (this.hasError == false) {
				$('#updatePassword').modal('hide');
			}
		});
		this.usersService.message.subscribe((x) => (this.message = x));
	}

	resetPassword(user_name, email) {
		this.message = '';
		let reset = { user_name: user_name, email: email };
		this.usersService.resetPasswordAdmin(reset);
		this.usersService.hasError.subscribe((x) => (this.hasError = x));
		this.usersService.message.subscribe((x) => (this.message = x));
		return false;
	}

	ngOnDestroy() {
		if (this.user$) this.user$.unsubscribe();
		// if (this.groups$) this.groups$.unsubscribe();
		// if (this.locations$) this.locations$.unsubscribe();
		// if (this.userLocations$) this.userLocations$.unsubscribe();
		if (this.devices$) this.devices$.unsubscribe();
		// if (this.userDevices$) this.userDevices$.unsubscribe();
	}
}
