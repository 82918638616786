<div class="row p-1">
  <div class="col-4">
    @if (message?.length > 0) {
      <div class="row">
        <div class="col-12 alert" [ngClass]="{ 'alert-danger': hasError, 'alert-success': !hasError }">
          <span style="float: right" (click)="clearMessage()"><i class="fa fa-times-circle-o" aria-hidden="true"></i></span>
          {{ message }}
        </div>
      </div>
    }

    <div class="row">
      <div class="col-12 px-1" id="bottom-nav">
        <div class="card">
          <ul class="nav nav-pills nav-fill" role="tablist">
            <li class="nav-item">
              <a class="nav-link active px-2" data-toggle="tab" href="#overview_{{ device.device_id }}" role="tab">Overview</a>
            </li>
            <li class="nav-item">
              <a class="nav-link px-2" data-toggle="tab" href="#detail_{{ device.device_id }}" role="tab">Details</a>
            </li>
            <li class="nav-item">
              <a class="nav-link px-2" data-toggle="tab" href="#alarms_{{ device.device_id }}" role="tab">Alarms</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 tab-content p-1">
        <div
          class="tab-pane active col px-1 monitor"
          id="overview_{{ device.device_id }}"
          [ngClass]="[device.getAlertType()]"
          role="tabpanel"
          >
          <div class="col-12 py-1">
            <div class="row px-0">
              <app-silo-img [device]="device" [showStatus]="true" class="col-12 p-0"></app-silo-img>
              <div class="col-12 hidden-xs-down p-0 mt-1" role="group">
                <div class="btn-group">
                  <button
                    type="button"
                    class="btn btn-info mr-1 p-1"
                    [disabled]="measurebtn !== 'Measure' || device.islocked === 1"
                    (click)="takeSmuMeasurement(device.device_id)"
                    >
                    {{ measurebtn }}
                  </button>
                  @if (permissions.canSuspendMeasurement() && device.device_type_code == 'smu344n') {
                    <button
                      type="button"
                      class="btn btn-info pt-1 mr-1"
                      (click)="suspendMeasurement()"
                      [disabled]="permissions.userType === 'E'"
                      >
                      {{ suspend_resume }}
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tab-pane col px-1" id="detail_{{ device.device_id }}" role="tabpanel">
          <div class="card-block py-1">
            <app-silo-detail [device]="device"></app-silo-detail>
          </div>
        </div>

        <div class="tab-pane col px-1" id="alarms_{{ device.device_id }}" role="tabpanel">
          <app-alarms class="py-1 d-flex flex-row flex-wrap" [device]="device" [displayClass]="'col-6'"></app-alarms>
        </div>
      </div>
      <!-- End tab-content -->
    </div>
  </div>
  <div class="col-8">
    <app-highchart-example class="card" [device]="device" [open]="open"></app-highchart-example>
    <!-- <app-line-chart class="col-8" [device]="device"></app-line-chart> -->
    <!-- <app-line-chart class="card"  ></app-line-chart> -->
  </div>
</div>
