import { Routes, RouterModule } from "@angular/router";
import { PasswordRecoveryComponent } from "./password-recovery/password-recovery.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { LocationsComponent } from "./admin/locations/locations.component";
import { GroupsComponent } from "./admin/groups/groups.component";
import { LocationEditComponent } from "./admin/location-edit/location-edit.component";
import { GroupEditComponent } from "./admin/group-edit/group-edit.component";
import { DeviceListComponent } from "./device-list/device-list.component";
import { DeviceComponent } from "./device/device.component";
import { CustomerServiceComponent } from "./customer-service/customer-service.component";

import { DevicesComponent } from "./admin/devices/devices.component";
import { DeviceEditComponent } from "./admin/device-edit/device-edit.component";
import { RulesEditComponent } from "./admin/rules-edit/rules-edit.component";
import { RulesNotificationComponent } from "./admin/rules-notification/rules-notification.component";
import { DeviceEditNavComponent } from "./admin/device-edit-nav/device-edit-nav.component";

import { UsersComponent } from "./admin/users/users.component";
import { UserEditComponent } from "./admin/user-edit/user-edit.component";
import { UserProfileComponent } from "./admin/user-profile/user-profile.component";

import { GatewaysComponent } from "./admin/gateways/gateways.component";
import { GatewayEditComponent } from "./admin/gateway-edit/gateway-edit.component";

import { CodesComponent } from "./admin/codes/codes.component";
import { CodeEditComponent } from "./admin/code-edit/code-edit.component";

import { AdminComponent } from "./admin/admin.component";
import { ReportsComponent } from "./reports/reports.component";
import { ChannelsComponent } from "./reports/channels/channels.component";
import { AlarmlogComponent } from "./reports/alarmlog/alarmlog.component";
// import { DevicesByLocationComponent } from './devices-by-location/devices-by-location.component';
import { LoginComponent } from "./login/login.component";
import { HelpComponent } from "./help/help.component";
import { AuthGuardService } from "./auth-guard.service";
import { UsersReportComponent } from "./reports/users-report/users-report.component";
import { EmailNotificationComponent } from "./admin/email-notification/email-notification.component";
import { VerifyComponent } from "./verify/verify.component";
import { UserProfileSecurityComponent } from "./admin/user-profile-security/user-profile-security.component";
import { TwoFAGaurdService } from "./services/two-fa-gaurd.service";
import { NgModule } from "@angular/core";

const routes: Routes = [
  {
    path: "",
    redirectTo: "devices",
    pathMatch: "full",
  },
  {
    path: "administration",
    component: AdminComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: "",
        redirectTo: "users",
        pathMatch: "full",
      },
      {
        path: "locations",
        component: LocationsComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "location/edit/:id",
        component: LocationEditComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "groups",
        component: GroupsComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "group/edit/:id",
        component: GroupEditComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "gateways",
        component: GatewaysComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "gateway/edit/:id",
        component: GatewayEditComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "codes",
        component: CodesComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "code/edit/:id",
        component: CodeEditComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "silos",
        component: DevicesComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "notification",
        component: EmailNotificationComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "silo/:id",
        component: DeviceEditNavComponent,
        canActivate: [AuthGuardService],
        children: [
          {
            path: "",
            redirectTo: "administration/silos",
            pathMatch: "full",
          },
          {
            path: "edit/:id",
            component: DeviceEditComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: "rules/:id",
            component: RulesEditComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: "notifications/:id",
            component: RulesNotificationComponent,
            canActivate: [AuthGuardService],
          },
        ],
      },
      {
        path: "users",
        component: UsersComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "user/edit/:id",
        component: UserEditComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "profile",
        component: UserProfileComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "profile/security",
        component: UserProfileSecurityComponent,
        canActivate: [AuthGuardService],
      },
    ],
  },
  {
    path: "twofactor",
    component: UserProfileSecurityComponent,
    canActivate: [TwoFAGaurdService],
  },
  {
    path: "report",
    component: ReportsComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: "",
        redirectTo: "channels",
        pathMatch: "full",
      },
      {
        path: "channels",
        component: ChannelsComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "alarmlog",
        component: AlarmlogComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "userslist",
        component: UsersReportComponent,
        canActivate: [AuthGuardService],
      },
    ],
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "unauthorized",
    component: LoginComponent,
  },
  {
    path: "device/:id",
    component: DeviceComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "devices/:view",
    component: DeviceListComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "devices",
    component: DeviceListComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "recover/:uuid",
    component: PasswordRecoveryComponent,
  },
  {
    path: "reset",
    component: ResetPasswordComponent,
  },
  {
    path: "support",
    component: CustomerServiceComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "verify",
    component: VerifyComponent,
  },
  /* {
		path:'help',
		component: HelpComponent
	}, */
  { path: "**", redirectTo: "login" },
];

// export const appRoutes = RouterModule.forRoot(routes);

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
