import { Observable } from "rxjs";
import { Device } from '../../model/device';
import { Location } from '../../model/location';
import { Group } from '../../model/group';

export class FetchAllDevices {
    static readonly type = '[DEVICE] Fetch All Devices';
}

export class FetchAdminDevices {
    static readonly type = '[DEVICE] Fetch Admin Devices';
}

export class FetchLatestDevices {
    static readonly type = '[DEVICE] Fetch Latest Devices';

    constructor(public payload: number) {}
}

export class DeviceChecked {
    static readonly type = '[DEVICE] Checked'

    constructor(public payload: number) {}
}

export class FilterDeviceGroups {
    static readonly type = '[DEVICE] Device Group ID filters'

    constructor(public payload: Observable<number[]>) {}
}

export class FilterDeviceLocations {
    static readonly type = '[DEVICE] Device Location ID filters'

    constructor(public payload: Observable<number[]>) {}
}

export class AlarmFilter {
    static readonly type = '[DEVICE] Alarm filters'

    constructor(public payload: number) {}
}

export class SearchString {
    static readonly type = '[DEVICE] Search String'

    constructor(public payload: string) {}
}

export class AddToWatchList {
    static readonly type = '[DEVICE] Add Device to Watch List'

    constructor(public payload: number) {}
}

export class RemoveFromWatchList {
    static readonly type = '[DEVICE] Remove Device from Watch List'

    constructor(public payload: number) {}
}

export class DeviceSort {
    static readonly type = '[LOCATION] Selected Sort'

    constructor(public payload: string) {}
}

export class DeviceFilter {
    static readonly type = '[Device] Device Filter'

    constructor(public payload: {filterByList:string,searchString:string, exactMatch: boolean}) {}
}

export type DeviceActions = 
        | FetchAllDevices
        | FetchAdminDevices
        | FetchLatestDevices
        | DeviceChecked
        | AlarmFilter
        | SearchString
        | FilterDeviceGroups
        | FilterDeviceLocations
        | AddToWatchList
        | RemoveFromWatchList
        | DeviceSort;

