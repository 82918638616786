import { interval } from "rxjs/internal/observable/interval";
import { startWith, switchMap } from "rxjs/operators";

import { State, Action, StateContext, Selector } from "@ngxs/store";
import { Device } from "../../model/device";
import * as DeviceActions from "../actions/devices.action";

import { DevicesService } from "../../services/devices.service";
// import { select, selector } from 'd3';
import { SearchAndSortArray } from "../../model/searchAndSortArray";
import { Injectable } from "@angular/core";

export class DeviceStateModel {
  devices: Device[];
  allDevices: Device[];
  groupIDs: number[];
  locationIDs: number[];
  deviceIDs: number[];
  alarm: number;
  search: string;
  latestTime: number;
  devicesWatching: number[];
  filterByList: string[];
  sortBy: string;
  direction: number;
  exactMatch: boolean;
}

@State<DeviceStateModel>({
  name: "devices",
  defaults: {
    devices: [],
    allDevices: [],
    groupIDs: [],
    locationIDs: [],
    deviceIDs: [],
    alarm: null,
    search: "",
    latestTime: 0,
    devicesWatching: [],
    filterByList: [],
    sortBy: "device_name",
    direction: 1,
    exactMatch: false,
  },
})
@Injectable()
export class DeviceState {
  constructor(private service: DevicesService) {}

  @Selector()
  static getDevices(state: DeviceStateModel) {
    let _devices = state.allDevices;

    if (state.locationIDs.length > 0) {
      _devices = state.allDevices.filter((a) =>
        state.locationIDs.includes(a.location_id)
      );
    } else if (state.groupIDs.length > 0) {
      _devices = state.allDevices.filter((a) =>
        state.groupIDs.includes(a.group_id)
      );
    }

    _devices.map((a) => {
      a.selected = state.deviceIDs.includes(a.device_id);
    });
    return _devices;
  }

  @Selector()
  static getGroupIds(state: DeviceStateModel) {
    return state.groupIDs;
  }

  @Selector()
  static getAllDevices(state: DeviceStateModel) {
    let devices = state.allDevices;
    if (state.deviceIDs.length > 0) {
      devices = devices.filter(
        (a) => state.deviceIDs.indexOf(a.device_id) > -1
      );
    } else if (state.locationIDs.length > 0) {
      devices = devices.filter(
        (a) => state.locationIDs.indexOf(a.location_id) > -1
      );
    } else if (state.groupIDs.length > 0) {
      devices = devices.filter((a) => state.groupIDs.indexOf(a.group_id) > -1);
    }

    if (state.alarm == 1) {
      devices = devices.filter(
        (a) => a.getStatus() !== "A-OK" || a.getAlert() !== "No Alarm"
      );
    } else if (state.alarm == 0) {
      devices = devices.filter(
        (a) => a.getStatus() === "A-OK" && a.getAlert() === "No Alarm"
      );
    }

    let obj = new SearchAndSortArray(
      devices,
      state.filterByList,
      state.search,
      state.exactMatch,
      state.sortBy,
      state.direction
    );
    /*const patt = new RegExp(state.search, 'ig');
        devices = devices.filter(x => Object.keys(x).some(key => patt.test(x[key])))

        devices = devices.sort((a,b)=>{
                        let aa = a[state.sortBy];
                        let bb = b[state.sortBy];

                        if(typeof aa == 'undefined' || typeof bb == 'undefined' || !aa || !bb){
                            return 0
                        }else{
                            return ( aa.toString().toLowerCase() > bb.toString().toLowerCase()? 1: -1 ) * state.direction;
                        }
                    })*/
    devices = obj.search();
    devices = obj.sort();

    return devices;
  }

  @Action(DeviceActions.FetchAllDevices)
  fetchAllDevices({ getState, setState }: StateContext<DeviceStateModel>) {
    const state = getState();
    let allDevices: Device[] = [];

    this.service.getDevices().subscribe((y) => {
      let a = y.map((x) => x.timestamp);
      let latestTime = this.getLatestTime(y);
      // patchState({ latestTime })
      setState({ ...state, devices: state.devices, allDevices: y, latestTime });
    });
  }

  @Action(DeviceActions.FetchLatestDevices)
  fetchLatestDevices(
    { getState, patchState }: StateContext<DeviceStateModel>,
    { payload }: DeviceActions.FetchLatestDevices
  ) {
    const state = getState();
    if (payload && !isNaN(payload)) {
      let newDevice: Device[] = state.allDevices;

      this.service.getDevices(payload).subscribe((x) => {
        x.map((d) => {
          newDevice.map((aa) => {
            if (aa.device_id === d.device_id) {
              Object.assign(aa, d);
            }
          });
        });
        let latestTime = this.getLatestTime(newDevice);
        patchState({ allDevices: newDevice, latestTime });
      });
    }
  }

  @Action(DeviceActions.FetchAdminDevices)
  fetchAdminDevices({ getState, setState }: StateContext<DeviceStateModel>) {
    const state = getState();
    let allDevices: Device[] = [];

    this.service.getDevicesAdminByLocationId().subscribe((y) => {
      setState({ ...state, allDevices: y });
    });
  }

  getLatestTime(y) {
    let a = y.map((x) => x.timestamp);
    let b = y.map((x) => x.smu_timestamp);
    let c = y.map((x) => x.comm_timestamp);
    let d = [...a, ...b, ...c]
      .sort()
      .filter((d) => !isNaN(d) && d.toString().length > 0);
    return d[d.length - 1];
  }

  @Selector()
  static getLatestTime(state: DeviceStateModel) {
    return state.latestTime;
  }

  @Selector()
  static getInterval(state: DeviceStateModel) {
    return state.devicesWatching.length > 0 ? 5000 : 20000;
  }

  @Action(DeviceActions.DeviceChecked)
  check(
    { getState, patchState }: StateContext<DeviceStateModel>,
    { payload }: DeviceActions.DeviceChecked
  ) {
    let st = getState().deviceIDs;
    let idx = st.indexOf(payload);

    if (idx === -1) {
      st.push(payload);
    } else {
      st.splice(idx, 1);
    }
    patchState({ deviceIDs: st });
  }

  @Selector()
  static getSelectedDeviceIDs(state: DeviceStateModel) {
    return state.deviceIDs;
  }

  @Action(DeviceActions.FilterDeviceGroups)
  groupFilter(
    { getState, patchState }: StateContext<DeviceStateModel>,
    { payload }: DeviceActions.FilterDeviceGroups
  ) {
    let state = getState();
    payload.subscribe((p) => {
      let device_id = state.devices
        .filter(
          (d) => p.includes(d.group_id) && state.deviceIDs.includes(d.device_id)
        )
        .map((ll) => ll.device_id);
      patchState({
        deviceIDs: device_id,
        groupIDs: p,
      });
    });
  }

  @Action(DeviceActions.FilterDeviceLocations)
  locationFilter(
    { getState, patchState }: StateContext<DeviceStateModel>,
    { payload }: DeviceActions.FilterDeviceLocations
  ) {
    let state = getState();
    payload.subscribe((p) => {
      let device_id = state.devices
        .filter(
          (d) =>
            p.includes(d.location_id) && state.deviceIDs.includes(d.device_id)
        )
        .map((ll) => ll.device_id);
      patchState({
        deviceIDs: device_id,
        locationIDs: p,
      });
    });
  }

  @Action(DeviceActions.AlarmFilter)
  alarmFilter(
    { getState, patchState }: StateContext<DeviceStateModel>,
    { payload }: DeviceActions.AlarmFilter
  ) {
    patchState({ alarm: payload });
  }

  @Action(DeviceActions.SearchString)
  searchString(
    { getState, patchState }: StateContext<DeviceStateModel>,
    { payload }: DeviceActions.SearchString
  ) {
    patchState({ search: payload });
  }

  @Selector()
  static getSearchString(state: DeviceStateModel) {
    return state.search;
  }

  @Action(DeviceActions.AddToWatchList)
  addToWatchList(
    { getState, patchState }: StateContext<DeviceStateModel>,
    { payload }: DeviceActions.AddToWatchList
  ) {
    let devices = getState().devicesWatching;
    if (!devices.includes(payload)) {
      devices.push(payload);
      patchState({ devicesWatching: devices });
    }
  }

  @Action(DeviceActions.RemoveFromWatchList)
  removeFromWatchList(
    { getState, patchState }: StateContext<DeviceStateModel>,
    { payload }: DeviceActions.RemoveFromWatchList
  ) {
    let devices = getState().devicesWatching;
    if (devices.includes(payload)) {
      devices.splice(devices.indexOf(payload), 1);
      patchState({ devicesWatching: devices });
    }
  }

  @Action(DeviceActions.DeviceSort)
  locationSort(
    { getState, patchState }: StateContext<DeviceStateModel>,
    { payload }: DeviceActions.DeviceSort
  ) {
    let state = getState();
    let sortBy = state.sortBy;
    let direction = state.direction;

    if (sortBy === payload) {
      direction = direction * -1;
    } else {
      direction = 1;
    }

    patchState({ sortBy: payload, direction });
  }

  @Selector()
  static getSortField(state: DeviceStateModel) {
    return state.sortBy;
  }

  @Selector()
  static getSortDirection(state: DeviceStateModel) {
    return state.direction === 1 ? "sort-asc" : "sort-desc";
  }

  @Action(DeviceActions.DeviceFilter)
  DeviceFilter(
    { getState, patchState }: StateContext<DeviceStateModel>,
    { payload }: DeviceActions.DeviceFilter
  ) {
    let state = getState();
    let filterByList = payload.filterByList.split(",");
    let exactMatch = payload.exactMatch;
    let search = payload.searchString;
    patchState({ filterByList, search, exactMatch });
  }
}
