<div class="row">
  <div class="col-12">
    @if (location_id.value === 0) {
      <h3 class="text-center">New Location</h3>
    }
    @if (location_id.value !== 0) {
      <h3 class="text-center">Edit Location</h3>
    }
  </div>
  @if (message && message.length > 0) {
    <div class="col-12 alert text-center" [ngClass]="hasError ? 'alert-danger' : 'alert-success'">
      {{ message }}
    </div>
  }
</div>
<div class="btn-group m-1">
  <button routerLink="/administration/locations" class="btn btn-primary"><i class="fa fa-arrow-left"></i>Locations</button>
</div>

<form [formGroup]="locationForm" (ngSubmit)="locationForm.valid && submitLocation()" autocomplete="false">
  <div class="form-group row mb-1">
    <label for="group_name" class="col-2 col-form-label p-1"><span class="required">*&nbsp;</span>Company</label>
    @if (canEditCompany()) {
      <div class="col-10">
        <select class="form-control ml-auto mr-1" name="group_id" [formControl]="group_id">
          <option value=""></option>
          @for (group of groups | async; track group) {
            <option [ngValue]="group.group_id" [selected]="group.group_id === group_id.value">
              {{ group.name }}
            </option>
          }
        </select>
        <div [hidden]="group_id.valid || group_id.untouched" class="alert alert-danger p-1">Company is required</div>
      </div>
    }
    @if (!canEditCompany()) {
      <div class="col-10">
        {{ getGroupByID(locationForm.value.group_id).name }}
      </div>
    }
  </div>

  <div class="form-group row mb-1">
    <label for="name" class="col-2 col-form-label p-1"><span class="required">*&nbsp;</span>Location Name</label>
    <div class="col-10">
      <input type="text" class="form-control p-1" name="name" [formControl]="name" maxlength="25" />
      <div [hidden]="name.valid || name.untouched" class="alert alert-danger p-1">Location Name is required</div>
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="status_cde" class="col-2 col-form-label p-1">Status</label>
    <div class="col-10">
      <select class="form-control ml-auto mr-1" name="status_cde" [formControl]="status_cde">
        <option [ngValue]="'A'" [selected]="status_cde.value === 'A'">Active</option>
        <option [ngValue]="'I'" [selected]="status_cde.value === 'I'">Inactive</option>
      </select>
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="addr1" class="col-2 col-form-label p-1">Address 1</label>
    <div class="col-10">
      <input class="form-control p-1" type="text" value="" id="addr1" name="addr1" [formControl]="addr1" />
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="addr2" class="col-2 col-form-label p-1">Address 2</label>
    <div class="col-10">
      <input class="form-control p-1" type="text" value="" id="addr2" name="addr2" [formControl]="addr2" />
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="city" class="col-2 col-form-label p-1">City</label>
    <div class="col-10">
      <input class="form-control p-1" type="text" value="" id="city" name="city" [formControl]="city" />
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="state" class="col-2 col-form-label p-1">State/Province</label>
    <div class="col-10">
      <!-- <input class="form-control p-1" type="text" value="" id="state" name="state" [formControl]="state" /> -->
      <select class="form-control" name="state_id" [formControl]="state_id">
        @for (state of states | async; track state) {
          <option [value]="state.id">{{ state.state }}</option>
        }
      </select>
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="zip" class="col-2 col-form-label p-1">Zip/Postal Code</label>
    <div class="col-10">
      <input class="form-control p-1" type="text" value="" id="zip" name="zip" [formControl]="zip" />
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="country" class="col-2 col-form-label p-1">Country</label>
    <div class="col-10">
      <!-- <input class="form-control p-1" type="text" value="" id="country" name="country" [formControl]="country" /> -->
      <ng-select
        #countrySelect
        [items]="countries | async"
        bindLabel="country"
        bindValue="id"
        name="country"
        labelForId="country"
        placeholder="Select a country"
        [formControl]="country_id"
        >
      </ng-select>
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="phone" class="col-2 col-form-label p-1"><span class="required">*&nbsp;</span>Phone</label>
    <div class="col-10">
      <input class="form-control p-1" text="tel" value="" id="phone" name="phone" [formControl]="phone" maxlength="25" />
      <div [hidden]="phone.valid || phone.untouched" class="alert alert-danger p-1">Phone number is required.</div>
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="site_contact_name" class="col-2 col-form-label p-1"><span class="required">*&nbsp;</span>Site Contact Name</label>
    <div class="col-10">
      <input
        class="form-control p-1"
        type="text"
        value=""
        id="site_contact_name"
        name="site_contact_name"
        [formControl]="site_contact_name"
        />
        <div [hidden]="site_contact_name.valid || site_contact_name.untouched" class="alert alert-danger p-1">
          Site Contact name is required.
        </div>
      </div>
    </div>

    <!-- <div class="form-group row mb-1">
    <label for="latitude" class="col-2 col-form-label p-1">Latitude</label>
    <div class="col-10">
      <input class="form-control p-1" type="text" value="" id="latitude" name="latitude" [formControl]="latitude" />
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="longitude" class="col-2 col-form-label p-1">Longitude</label>
    <div class="col-10">
      <input class="form-control p-1" type="text" value="" id="longitude" name="longitude" [formControl]="longitude" />
    </div>
  </div> -->

  <div class="form-group row mb-1">
    <label for="timezone" class="col-2 col-form-label p-1"><span class="required">*&nbsp;</span>Time Zone</label>
    <div class="col-10">
      <select class="form-control ml-auto mr-1" name="timezone" [formControl]="timezone">
        <option [ngValue]="-3" [selected]="timezone.value === -3">Atlantic (GMT - 3)</option>
        <option [ngValue]="-4" [selected]="timezone.value === -4">Eastern (GMT - 4)</option>
        <option [ngValue]="-5" [selected]="timezone.value === -5">Central (GMT - 5)</option>
        <option [ngValue]="-6" [selected]="timezone.value === -6">Mountain (GMT - 6)</option>
        <option [ngValue]="-7" [selected]="timezone.value === -7">Pacific (GMT - 7)</option>
        <option [ngValue]="-8" [selected]="timezone.value === -8">Alaska (GMT - 8)</option>
        <option [ngValue]="-9" [selected]="timezone.value === -9">Hawaii (GMT - 9)</option>
        <option [ngValue]="9" [selected]="timezone.value === 9">Japan (GMT + 9)</option>
      </select>
      <div [hidden]="timezone.valid || timezone.untouched" class="alert alert-danger p-1">Time Zone is required</div>
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="observes_dst" class="col-2 col-form-label p-1"><span class="required">*&nbsp;</span>Daylight Savings Time</label>
    <div class="col-10">
      <select class="form-control ml-auto mr-1" name="observes_dst" [formControl]="observes_dst">
        <option [ngValue]="'Y'" [selected]="observes_dst.value === 'Y' || observes_dst.value === 'y'">Yes</option>
        <option [ngValue]="'N'" [selected]="observes_dst.value === 'N' || observes_dst.value === 'n'">No</option>
      </select>
      <div [hidden]="observes_dst.valid || observes_dst.untouched" class="alert alert-danger p-1">Company is required</div>
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="longitude" class="col-2 col-form-label p-1">&nbsp;</label>
    <div class="col-10">
      <button class="form-controld p-1 mr-1 btn btn-success" [disabled]="!locationForm.valid || !canUpdate">Update Location</button>
      <button
        class="form-controlz p-1 mr-1 btn btn-danger"
        data-toggle="modal"
        data-target="#delLocation"
        (click)="fakeDelete()"
        [disabled]="!canUpdate"
        >
        Delete
      </button>
      <button class="form-controlz p-1 mr-1 btn btn-default" routerLink="/administration/locations">Cancel</button>
    </div>
  </div>
  @if (!locationForm.valid) {
    <div class="col-12 alert alert-danger">Note: Please fill out all the fields marked by *.</div>
  }
</form>
<div class="modal fade" id="delLocation" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{ name.value }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">Are you sure you want to delete {{ name.value }}?</div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="deleteLocation()" [disabled]="!canUpdate" data-dismiss="modal">Delete</button>
      </div>
    </div>
  </div>
</div>
