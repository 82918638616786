interface CodeJson{
	id:			number;
	type:		string;
	code:		string;
	decode:		string;
	sort_num:	number;
	total_rows:	number;
}

export class Code {

	static fromJSON( jsonObj ): Code{
		let cd = Object.create( Code.prototype );

		let obj = Object.assign( cd, jsonObj,{	
													 id:		jsonObj.id
													,type:		jsonObj.type
													,code:		jsonObj.code
													,decode:	jsonObj.decode
													,sort_num:	jsonObj.sort_num
													,total_rows:jsonObj.total_rows													
												});
		return obj;
	}

	constructor(
				public id: 			number,
				public type: 		string,
				public code: 		string,
				public decode: 		string,
				public sort_num:	number,
				public total_rows: 	number,
			){ 	
    }
   
}
