<div class="row">
  <div class="col-12">
    <h3>Edit Gateway</h3>
  </div>
  @if (message && message.length > 0) {
    <div class="col-12 alert text-center" [ngClass]="hasError? 'alert-danger' : 'alert-success' ">
      {{message}}
    </div>
  }
</div>
<div class="btn-group m-1">
  <button routerLink="/administration/gateways" class="btn btn-primary"><i class="fa fa-arrow-left"></i>Gateways</button>
</div>

<form [formGroup]="gatewayForm" (ngSubmit)="gatewayForm.valid && submitGroup()" autocomplete="false">

  <div class="form-group row mb-1">
    <label for="group_id" class="col-2 col-form-label p-1"><span class="required">*</span>Company</label>
    @if (!canEditCompany()) {
      <div>
        <a routerLink="/administration/group/edit/{{group_id.value}}">{{ group_name.value }}</a>
      </div>
    }
    @if (canEditCompany()) {
      <div class="col-10">
        <select class="form-control ml-auto mr-1" name="group_id" [formControl]="group_id" (change)="setLocation()">
          @for (group of groups | async; track group) {
            <option [ngValue]="group.group_id" [selected]="group.group_id === group_id.value">{{group.name}}</option>
          }
        </select>
        <div [hidden]="group_id.valid || group_id.untouched" class="alert alert-danger p-1">
          Company is required
        </div>
      </div>
    }
  </div>
  <!-- {{locations | json}} -->
  <div class="form-group row mb-1">
    <label for="location_id" class="col-2 col-form-label p-1"><span class="required">*</span>Location</label>
    <div class="col-10">
      <select class="form-control ml-auto mr-1" name="location_id" [formControl]="location_id">
        <option value="">Select Location</option>
        @for (location of getLocations(); track location) {
          <option [ngValue]="location.location_id" [selected]="location.location_id === location_id.value">{{location.name}}</option>
        }
      </select>
      <div [hidden]="location_id.valid || location_id.untouched" class="alert alert-danger p-1">
        Location is required
      </div>
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="mac" class="col-2 col-form-label p-1">Mac Address</label>
    <div class="col-10">
      <label class="col-form-label">
        {{ gatewayForm.value.mac}}
      </label>
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="name" class="col-2 col-form-label p-1"><span class="required">*</span>Gateway Name</label>
    <div class="col-10">
      <input type="text" class="form-control p-1" name="name" [formControl]="name">
      <div [hidden]="name.valid || typeid.untouched" class="alert alert-danger p-1">
        Gateway Name is required
      </div>
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="type" class="col-2 col-form-label p-1"><span class="required">*</span>Gateway Type</label>
    <div class="col-10">
      <label class="col-form-label">
        {{ type }}
      </label>
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="status_cde" class="col-2 col-form-label p-1"><span class="required">*</span>Gateway Status</label>
    <div class="col-10">
      <select class="form-control ml-auto mr-1" name="status_cde" [formControl]="status_cde">
        <option [ngValue]="'A'" [selected]="model.status_cde === 'A' || model.status_cde === 'a' ">Active</option>
        <option [ngValue]="'Z'" [selected]="model.status_cde === 'Z' || model.status_cde === 'z' ">Inactive</option>
      </select>
      <div [hidden]="status_cde.valid || status_cde.untouched" class="alert alert-danger p-1">
        Gateway Status is required
      </div>
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="longitude" class="col-2 col-form-label p-1">&nbsp;</label>
    <div class="col-10">
      <button class="form-controlf p-1 mr-1 btn btn-success" [disabled]="!gatewayForm.valid">Update Gateway</button>
      <button class="form-controlz p-1 mr-1 btn btn-danger" data-toggle="modal" data-target="#delGateway" (click)="fakeDelete()">Delete</button>
      <button class="form-controlz p-1 mr-1 btn btn-default" routerLink="/administration/gateways">Cancel</button>
    </div>
  </div>

</form>
<div class="modal fade" id="delGateway" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{name.value}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Are you sure you want to delete {{name.value}}?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="deleteGateway()" data-dismiss="modal">Delete</button>
      </div>
    </div>
  </div>
</div>