import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter  } from '@angular/core';
import { Observable } from 'rxjs';

import { PagerService } from '../services/pager.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.css']
})
export class PagerComponent implements OnInit, OnChanges {
	@Input() allItems;
	// array of all items to be paged
	// private allItems: any[];
    // pager object
    pager: any = {};
    // paged items
    pagedItems = [];
    pageSize:number = 10;
    searchString: string = '';
    sortKey: string = '';
    asc: boolean = true;

    @Output() pagedItemsNew = new EventEmitter<any>();

	constructor(private pagerService:PagerService) {
	}

	ngOnInit() {
		// this.setPage(1);
	}
    page = 1;
    setPage(page: number) {
        this.page = page;
		if(!this.allItems) return;
		const patt = new RegExp(this.searchString,'ig');
		let items = this.allItems;

        this.asc = !this.asc;
        let dir = this.asc? 1: -1;

		items = this.allItems
                    .filter(x=> Object.keys(x).some(key =>  patt.test( x[key] ) ))
                    .sort((a,b)=>{
                        let aa = a[this.sortKey];
                        let bb = b[this.sortKey];

                        if(typeof aa == 'undefined' || typeof bb == 'undefined' || !aa || !bb){
                            return 0
                        }else{
                            return ( aa.toString().toLowerCase() > bb.toString().toLowerCase()? 1: -1 ) * dir;
                        }
                    });
        
		// get pager object from service
        this.pager = this.pagerService.getPager(items.length, this.page, this.pageSize);

        // get current page of items
        this.pagedItems = items.slice(this.pager.startIndex, this.pager.endIndex + 1);
		this.pagedItemsNew.emit(this.pagedItems);        
    }

    /*  setPager(page: number = 1, items) {
        if(!this.allItems) return;
        
        
        let d = (p)=>{
            // get pager object from service
            this.pager = this.pagerService.getPager(items.length, p, this.pageSize);

            // get current page of items
            this.pagedItems = items.slice(this.pager.startIndex, this.pager.endIndex + 1);
            this.pagedItemsNew.emit(this.pagedItems); 
        }  
        return d     
    }

    searchByKey(k,v){
        console.log(v.length)
        if(!v || v.length === 0 ){
            return this.setPager(1,this.allItems)(1);            
        } 
        let item = this.allItems.filter(x=>v.indexOf(x[k]) > -1);
        let s = this.setPager(1,item);
        s(1);
    } */

    sort(key){
        this.sortKey = key;
        this.setPage(1);
    }

    search(str){
        this.searchString = str;
        this.setPage(1);
    }


    updatePageSize(size){
    	this.pageSize = size;
    	this.setPage(1);
    }

    ngOnChanges(changes: SimpleChanges) {
        // only run when property "data" changed
        if (changes['allItems']) {
            setTimeout(()=>{this.setPage(this.page);})            
        }
    }

}
