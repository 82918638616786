import * as lkeys from 'lowercase-keys';
interface StateJson{
	id:			number;
	state:		string;
	abbreviation:string;
}

export class State implements StateJson {

	static fromJSON( jsonObj ): State{
		let st = Object.create( State.prototype );

		let obj = Object.assign( st, lkeys(jsonObj));
		return obj;
	}

	constructor(
				public id: 			number,
				public state:		string,
				public abbreviation:string
				){}
}
   

