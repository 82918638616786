interface TimeJson{
	device_id:			number;
	deviceTimestamp:	number;
}

export class DeviceTime {

	static fromJSON( jsonObj ): DeviceTime{
		let cd = Object.create( DeviceTime.prototype );

		let obj = Object.assign( cd, jsonObj);
		return obj;
	}

	constructor(
				public device_id: 			number,
				public deviceTimestamp: 	number
			){ 	
    }
   
}
