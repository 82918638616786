<div class="container">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">{{device_name}} - Alarms</h5>
  </div>
  <div class="row">
    @if (message && message.length > 0) {
      <div class="col-12 alert text-center" [ngClass]="hasError? 'alert-danger' : 'alert-success' " >
        {{message}}
      </div>
    }
  </div>
  <form [formGroup]="rulesForm" (ngSubmit)="submitRules()" autocomplete="false" >
    <div class="modal-body form-group row mb-1">
      <table>
        <tr>
          <td>&nbsp;</td>
          <td>
            <label><strong>Low Low</strong></label>
          </td>
          <td>
            <label><strong>Low</strong></label>
          </td>
          <td>
            <label><strong>High</strong></label>
          </td>
          <td>
            <label><strong>High High</strong></label>
          </td>
          <td>
            <label><strong>System Alarms</strong></label>
          </td>
        </tr>
        <tr>
          <td align="right"><label><strong>{{alarmLabel}}</strong></label></td>
          <td>
            <input type="number" class="form-control p-1" name="llThreshold" [formControl]="llThreshold" [class.is-invalid]="llThreshold?.invalid" >
          </td>
          <td>
            <input type="number" class="form-control p-1" name="lThreshold" [formControl]="lThreshold" [class.is-invalid]="lThreshold?.invalid" >
          </td>
          <td>
            <input type="number" class="form-control p-1" name="hThreshold" [formControl]="hThreshold" [class.is-invalid]="hThreshold?.invalid" >
          </td>
          <td>
            <input type="number" class="form-control p-1" name="hhThreshold" [formControl]="hhThreshold" [class.is-invalid]="hhThreshold?.invalid" >
          </td>
          <td>
            <input type="number" class="form-control p-1" name="status_th" [formControl]="status_threshold">
          </td>
        </tr>
        <!-- <tr>
        <td align="right"><label><strong>Deadband %</strong></label></td>
        <td>
          <input type="number" class="form-control p-1" name="llDeadband" [formControl]="llDeadband" >
        </td>
        <td>
          <input type="number" class="form-control p-1" name="lDeadband" [formControl]="lDeadband" >
        </td>
        <td>
          <input type="number" class="form-control p-1" name="hDeadband" [formControl]="hDeadband" >
        </td>
        <input type="number" class="form-control p-1" name="hhDeadband" [formControl]="hhDeadband" >
      </td>
      <td>
        <input type="number" class="form-control p-1" name="status_deadband" [formControl]="status_deadband" >
      </td>
    </tr> -->
    <tr>
      <td align="right"><label><strong>Delay (minutes)</strong></label></td>
      <td>
        <input type="number" class="form-control p-1" name="llDelay" [formControl]="llDelay" min="0">
      </td>
      <td>
        <input type="number" class="form-control p-1" name="lDelay" [formControl]="lDelay" min="0">
      </td>
      <td>
        <input type="number" class="form-control p-1" name="hDelay" [formControl]="hDelay" min="0">
      </td>
      <td>
        <input type="number" class="form-control p-1" name="hhDelay" [formControl]="hhDelay" min="0">
      </td>
      <td>
        <input type="number" class="form-control p-1" name="status_delay" [formControl]="status_delay" min="0">
      </td>
    </tr>
    <tr>
      <td align="right"><label><strong>Message Note (15 character limit)</strong></label></td>
      <td>
        <input type="text" class="form-control p-1" name="llMsg" [formControl]="llMsg"  maxlength="15">
      </td>
      <td>
        <input type="text" class="form-control p-1" name="lMsg" [formControl]="lMsg"  maxlength="15">
      </td>
      <td>
        <input type="text" class="form-control p-1" name="hMsg" [formControl]="hMsg"  maxlength="15">
      </td>
      <td>
        <input type="text" class="form-control p-1" name="hhMsg" [formControl]="hhMsg"  maxlength="15">
      </td>
      <td>
        <input type="text" class="form-control p-1" name="status_msg" [formControl]="status_msg"  maxlength="15">
      </td>
    </tr>
    <tr>
      <td>&nbsp;</td>
      <td colspan="100%">
        <label class="col-form-label p-1" for="notifyOnce"><strong>Notify Once</strong></label>
        <input type="radio" class="custom-form-control p-1" id="notifyOnce" name="notifyOnce" [value]="1" [formControl]="notifyonce"  >
        &nbsp;|&nbsp;
        <label class="col-form-label p-1" for="notifyAlways"><strong>Notify Always</strong></label>
        <input type="radio" class="custom-form-control p-1" id="notifyAlways" name="notifyOnce" [value]="0" [formControl]="notifyonce"  >
      </td>
    </tr>
    @if (thresholdError) {
      <tr>
        <td>&nbsp;</td>
        <td colspan="3">
          <div class="text-danger my-2">
            Alarm setpoint should be in between 0 and {{ maxThreshold | number: '1.0-2' }}
          </div>
        </td>
      </tr>
    }
    <tr>
      <td>&nbsp;</td>
      <td colspan="100%" class="offset-2">
        <button type="button" class="btn btn-success p-1 mr-1" (click)="submitRules()"  data-dismiss="modal"
        [disabled]="!canUpdateAlarm || thresholdError" >Save changes</button>
        <button routerLink="/administration/silos" type="button" class="btn btn-secondary p-1" data-dismiss="modal">Cancel</button>
      </td>
    </tr>
  </table>
</div>
</form>
</div>
