import { Component, OnInit, Input } from '@angular/core';

import { Device } from '../model/device';

@Component({
  selector: 'app-alarms',
  templateUrl: './alarms.component.html',
  styleUrls: ['./alarms.component.css']
})
export class AlarmsComponent implements OnInit {
	@Input() device: Device;
	@Input() displayClass;
	ll_alarm: number;
	l_alarm: number;
	h_alarm: number;
	hh_alarm: number;
	alarm_uom:string;
	deviceAlarm:Device;

	alarm_label = '';
	constructor() { 
	}
 
	ngOnInit() {
		// this.alarm_uom 	= this.device.alarm_display;
		this.deviceAlarm = Object.create(this.device);

		// this.ll_alarm	= this.getConvertedThreshold(this.device.ll_threshold);
		// this.l_alarm		= this.getConvertedThreshold(this.device.l_threshold);
		// this.h_alarm		= this.getConvertedThreshold(this.device.h_threshold);
		// this.hh_alarm	= this.getConvertedThreshold(this.device.hh_threshold);
		
	}

	getConvertedThreshold(val){
		let returnValue = val;
		if( !this.device || this.device.alarm_display == undefined|| val == 0 || isNaN(val) ){
			return val
		}		
		
		this.deviceAlarm.default_display = this.deviceAlarm.alarm_display;
		this.deviceAlarm.setDistance(val);

		returnValue = this.deviceAlarm.getMeasurement();
		
		return returnValue;
	}

	getLabel(val){
		if( !(val)){
			return;
		}
		let alarm_label;
		let unit = this.device.alarm_display.trim().toLowerCase();
		if( unit === 'distance' || unit === 'level'){
			alarm_label = this.device.linear_label;
		}else if(unit === 'weight' ){
			alarm_label = this.device.weight_label;
		}else if(unit === 'volume'){
			alarm_label = this.device.volume_label;
		}
		return alarm_label;
	}
}
