import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';


import { environment} from '../../environments/environment';
import { Country } from '../model/country';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {

  constructor( private util: UtilService) { }

  getCountries(): Observable<Country[]> {
		let url = environment.apiurl + '/location/getCountries';
	    return this.util._requestData(url)
	      .pipe(map(res => res.data.map(jsonobj => Country.fromJSON(jsonobj))))
	  }
}
