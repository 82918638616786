import { Injectable } 							from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';


import { environment} from '../../environments/environment';

import { AlarmLog } from '../model/alarmLog';
import { UtilService } from './util.service';

@Injectable()
export class AlarmlogService {

	public loading:Subject<boolean> 	= new Subject<boolean>();
	public hasError:Subject<boolean> 	= new Subject<boolean>();
	public message:Subject<string> 		= new Subject<string>();
	private channelUrl = environment.apiurl + '/location/getAlarmLog';

	private _alarms: BehaviorSubject<AlarmLog[]> 	= new BehaviorSubject<AlarmLog[]>( [] );
	public alarms: 	Observable<AlarmLog[]> 			= this._alarms.asObservable();
	
	public files: Subject<any[]> 	= new Subject<any[]>( );

	constructor (private util:UtilService, private router: Router) {}

	public getAlarms(gid?:number,lid?:number,did?:number,pNum?:number,orderBy?:string,orderDirection?:string): void {
		this.loading.next(true);
		// 'method=getAlarmLog&group_id=' + gid + '&location_id=' + lid + '&device_id=' + did + '&page=' + pNum + '&orderBy=' + orderBy + '&orderDirection=' + orderDirection
		const payload: any = {};
		if(gid) payload.group_id = gid;
		if(lid) payload.location_id = lid;
		if(did) payload.device_id = did;
		if(pNum) payload.page = pNum;
		if(orderBy) payload.orderBy = orderBy;
		if(orderDirection) payload.orderDirection = orderDirection

		this.util._getData(this.channelUrl, payload)
			.pipe(map(ret=>ret.data))
			.subscribe( cJSON =>{
				const alarm = cJSON.map( jsonobj => AlarmLog.fromJSON( jsonobj ));
				this._alarms.next( alarm );
				this.loading.next(false);
			})
	}

}
