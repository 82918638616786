interface SearchandSort {
	array: any[];
	fieldList: string[];
	searchString: string;
	exactMatch: boolean;
	sortBy: string;
	sortDirection: number;
}

export class SearchAndSortArray implements SearchandSort {

	constructor(public array: any[], public fieldList: string[], public searchString: string, public exactMatch: boolean, public sortBy: string, public sortDirection: number) { }


	search() {
		if (this.searchString.trim().length === 0) {
			return this.array;
		}

		let fields = [];
		this.searchString = this.searchString.replace('\\', '\\\\');
		let patt = new RegExp(this.searchString, 'i');
		this.array = this.array.filter(x => {
			fields = [];
			this.fieldList.forEach(srch => fields.push(x[srch]));

			if (this.exactMatch) {
				fields = fields.filter(y => y.trim().toLowerCase() === this.searchString.trim().toLowerCase())
			} else {
				fields = fields.filter(y => patt.test(y))
			}

			return fields.length > 0;
		});

		return this.array;

	}

	sort() {
		this.array = this.array
			.sort((a, b) => {
				let aa = a[this.sortBy] || '_';
				let bb = b[this.sortBy] || '_';

				if (typeof aa == 'undefined' || typeof bb == 'undefined' || !aa || !bb) {
					return 0
				} else {
					return (aa.toString().toLowerCase() > bb.toString().toLowerCase() ? 1 : -1) * this.sortDirection;
				}
			})
		return this.array;
	}
}

