import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Route } from '@angular/router';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/filter';

import { Permissions } from '../model/permissions';
import { Device } from '../model/device';
import { Distance } from '../model/distance';

import { DevicesService } from '../services/devices.service';
import { HighchartExampleComponent } from '../highchart-example/highchart-example.component';

@Component({
	selector: 'app-device',
	templateUrl: './device.component.html',
	styleUrls: ['./device.component.css'],
})
export class DeviceComponent implements OnInit, OnDestroy {
	permissions = new Permissions();
	public devices: Observable<Device[]>;
	public device: Device;
	public series: Observable<Distance[]>;
	public device_id: number;
	// private device: Device;
	public open = { open: true };
	public hasError: boolean;
	public message: string = '';
	public smu_timeStamp;

	public d: Date = new Date();
	public start = this.d.getTime() - 24 * 60 * 60 * 1000; /*Hour * min * sec*/
	public end = this.d.getTime() + 6 * 60 * 60 * 1000; /*Hour * min * sec*/
	public interval;
	public statusTime: number;
	public measurement$: any;
	public measurebtn = 'Measure';
	public suspend_resume = 'Suspend Measurement';
	public measurement_error_msg = 'Unable to take measurement. Please make sure the device is connected';

	@ViewChild(HighchartExampleComponent) highchartExampleComponent: HighchartExampleComponent;

	model: any = {
		startDate: new Date(this.start),
		endDate: new Date(this.end),
	};

	constructor(private devicesService: DevicesService, private activeRoute: ActivatedRoute) {
		// this.devices = devicesService.device;
	}

	ngOnInit() {
		this.activeRoute.params.subscribe((params) => {
			this.device_id = params['id'];
			this.devicesService.getDeviceByID(this.device_id);
			this.getDeviceTimeStatus();
			this.devicesService.deviceTime.subscribe((t) => {

				t.filter((tt) => tt.device_id == this.device_id).map((tt) => {
					this.statusTime = tt.deviceTimestamp;
				});
			});

			this.startIntevalUpdate();
			// this.device_id = this.activeRoute.snapshot.params['id'];
			// this.devicesService.getDeviceByID(this.device_id);
			this.devices = this.devicesService.device;

			this.devices.subscribe((x) => {
				x.map((y) => {
					this.smu_timeStamp = y.smu_timestamp;
					this.device = y;
					if (this.device.islocked === 1) {
						this.suspend_resume = 'Resume Measurement';
					}
				});
			});
		});
	}

	ngOnDestroy() {
		this.open.open = false;
		clearInterval(this.interval);
	}

	takeSmuMeasurement(did) {
		this.measurebtn = 'Measuring...';
		this.message = '';
		this.measurement$ = this.devicesService.takeMeasurement(did, this.device.timestamp);
		this.measurement$.subscribe(
			(x) => {
				console.log(x);
				if (x !== 'success') {
					this.measurebtn = 'Measure';
					this.hasError = true;
					this.message = this.measurement_error_msg;
					// let int = setInterval((x)=>{ clearInterval(int); this.hasError = false},10000)
				} else {
					this.refreshData();
				}
			},
			() => {
				this.measurebtn = 'Measure';
				this.hasError = true;
				this.message = this.measurement_error_msg;
				// let int = setInterval((x)=>{ clearInterval(int); this.hasError = false},10000)
			}
		);
	}

	refreshData() {
		let counter = 0;
		let timer = setInterval(() => {
			if (counter > this.getNumberOfTries()) {
				clearInterval(timer);
				this.measurebtn = 'Measure';
				this.hasError = true;
				this.message = `It is taking too long to update ${this.device.device_name}. Try again or contact administrator.`;
			}
			counter++;
			this.devicesService.getDeviceStatusTime(this.device_id);
			let _tmp = JSON.parse(localStorage.getItem('measuring'));
			if (_tmp) {
				_tmp.forEach((x, i) => {
					if (x.device_id == this.device_id && Number(x.smu_timeStamp) < Number(x.newTimeStamp)) {
						this.updateDevice();
						clearInterval(timer);
						this.measurebtn = 'Measure';

						_tmp.splice(i, 1);
						localStorage.setItem('measuring', JSON.stringify(_tmp));
						this.hasError = false;
						this.message = `The information for ${this.device.device_name} has been updated successfully.`;
					}
					console.log('time diff', counter, x.device_id, x.smu_timeStamp - x.newTimeStamp);
				});
			}
		}, 5000);
	}

	updateDevice() {
		let newDeviceValue = this.devicesService.returnDeviceByID(this.device.device_id);

		newDeviceValue.subscribe((x) => {
			this.devicesService.devices.subscribe((devices) => {
				devices
					.filter((d) => d.device_id === this.device.device_id)
					.map((d) => {
						// d = this.device;
						d = Object.assign(this.device, d);
						// if(this.open.open == true && d){
						// this.highchartExampleComponent.add(d);
						// }
					});
			});
		});
	}

	getDeviceTimeStatus() {
		// this.interval = setInterval(() => {
		this.devicesService.getDeviceListStatusTime(this.device_id);
		// }, 10000);
	}

	startIntevalUpdate() {
		this.interval = setInterval(() => {
			// console.log('time', this.device_id, this.statusTime,this.device.timestamp, this.statusTime - parseInt(this.device.timestamp))
			if (this.device.getLatestDeviceTime() && this.statusTime > this.device.getLatestDeviceTime()) {
				this.devicesService.getDeviceByID(this.device_id);
			}
		}, 6000);
	}

	clearMessage() {
		this.message = '';
		this.hasError = null;
	}

	getNumberOfTries(): number {
		let tries = 10;
		if (this.device.linear_uom.trim().toUpperCase() === 'F') {
			tries = this.device.height * 3;
		} else {
			tries = this.device.height * 3.28084 * 3;
		}
		tries = tries >= 10 ? Math.trunc(tries) : 10;
		return tries;
	}

	suspendMeasurement() {
		this.hasError = false;
		this.message = '';
		const islocked = this.device.islocked === 1 ? 0 : 1;
		this.devicesService.suspendMeasurement(this.device_id, islocked).subscribe(
			(res) => {
				const data = res.data;
				this.hasError = data.status === 'error';
				if (!this.hasError) {
					this.device.islocked = islocked;
				}
			},
			(err) => {
				this.hasError = true;
				console.log(err);
			},
			() => {
				this.updateLableMsg();
			}
		);
	}

	updateLableMsg() {
		console.log(this.device);
		let action = this.device.islocked === 1 ? 'resume' : 'suspend';
		if (this.device.islocked === 1) {
			this.suspend_resume = 'Resume Measurement';
		} else {
			this.suspend_resume = 'Suspend Measurement';
		}

		if (this.hasError) {
			this.message = `Unable to ${action} measurement`;
		} else if (this.device.islocked === 1) {
			this.message = `Measurement has been suspended`;
		} else if (this.device.islocked === 0) {
			this.message = `Measurement has been resumed`;
		}
	}
}
