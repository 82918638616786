<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
<meta http-equiv="Pragma" content="no-cache" />
<meta http-equiv="Expires" content="0" />
<div class="row">
  <div class="col-12">
    @if (user_id.value === 0) {
      <h3>New User</h3>
    }
    @if (user_id.value !== 0) {
      <h3>Edit User</h3>
    }
  </div>
  @if (message && message.length > 0) {
    <div
      class="col-12 alert text-center"
      id="user_msg"
      [ngClass]="hasError ? 'alert-danger' : 'alert-success'"
      >
      {{ message }}
    </div>
  }
</div>
<div class="btn-group justify-content-start m-1">
  <button routerLink="/administration/users" class="btn btn-primary"><i class="fa fa-arrow-left"></i>Users</button>
</div>
<form [formGroup]="userFormss" (ngSubmit)="saveUser()" autocomplete="off">
  <div class="form-group row mb-1">
    @if (!userFormss.valid) {
      <div class="col-12">* Denotes Required Fields.</div>
    }
    <label for="user_name" class="col-2 col-form-label p-1"><span class="required">*</span>Login Name</label>
    <div class="col-10">
      <input type="text" class="form-control p-1" name="user_name" id="user_name" [formControl]="user_name" [readonly]="!isEditable" />
      <div [hidden]="user_name.valid || user_name.untouched" class="alert alert-danger p-1">
        <div [hidden]="!user_name.hasError('minlength')">Login Name can not be shorter than 3 characters.</div>
        <div [hidden]="!user_name.hasError('maxlength')">Login Name can not be logner than 20 characters.</div>
        <div [hidden]="!user_name.hasError('required')">Login Name is required.</div>
      </div>
    </div>
  </div>

  @if (isEditable && canUpdate) {
    <div class="form-group row mb-1">
      <label for="password" class="col-2 col-form-label p-1">Password</label>
      <div class="col-10">
        <button class="form-controlz p-1 btn btn-danger mr-1" data-toggle="modal" data-target="#updatePassword" (click)="preventSubmit()">
          Update Password
        </button>
        <button class="btn btn-danger p-1" (click)="resetPassword(user_name.value, email.value)">
          <i class="fa fa-unlock-alt" aria-hidden="true"></i>Reset Password
        </button>
      </div>
    </div>
  }

  <div class="form-group row mb-1">
    <label for="name" class="col-2 col-form-label p-1"><span class="required">*</span>Name</label>
    <div class="col-10">
      <input type="text" class="form-control p-1" name="name" id="name" [formControl]="name" [readonly]="!isEditable" />
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="addr1" class="col-2 col-form-label p-1">Address</label>
    <div class="col-10">
      <input
        type="text"
        class="form-control p-1"
        name="addr1"
        id="addr1"
        [formControl]="addr1"
        [readonly]="!isEditable"
        [readonly]="!isEditable"
        />
      </div>
    </div>

    <div class="form-group row mb-1">
      <label for="addr2" class="col-2 col-form-label p-1">Address 2</label>
      <div class="col-10">
        <input
          type="text"
          class="form-control p-1"
          name="addr2"
          id="addr2"
          [formControl]="addr2"
          [readonly]="!isEditable"
          [readonly]="!isEditable"
          />
        </div>
      </div>

      <div class="form-group row mb-1">
        <label for="city" class="col-2 col-form-label p-1">City</label>
        <div class="col-10">
          <input
            type="text"
            class="form-control p-1"
            name="city"
            id="city"
            [formControl]="city"
            [readonly]="!isEditable"
            [readonly]="!isEditable"
            />
          </div>
        </div>

        <div class="form-group row mb-1">
          <label for="state_id" class="col-2 col-form-label p-1"><span class="required">*</span>State/Province</label>
          <div class="col-10">
            <ng-select
              #stateSelect
              [items]="states | async"
              bindLabel="state"
              bindValue="id"
              name="state_id"
              labelForId="state"
              placeholder="Select a state"
              [formControl]="state_id"
              >
            </ng-select>
            <div [hidden]="state_id.valid || state_id.untouched" class="alert alert-danger p-1">State/Province is required.</div>
          </div>
        </div>

        <div class="form-group row mb-1">
          <label for="zip" class="col-2 col-form-label p-1">Zip</label>
          <div class="col-10">
            <input
              type="text"
              class="form-control p-1"
              name="zip"
              id="zip"
              [formControl]="zip"
              [readonly]="!isEditable"
              [readonly]="!isEditable"
              />
            </div>
          </div>
          <!-- <div class="form-group row mb-1">
          <label for="country" class="col-2 col-form-label p-1">Country</label>
          <div class="col-10">
            <input type="text" class="form-control p-1" name="country" id="country" [formControl]="country"
              [readonly]="!isEditable" [readonly]="!isEditable" />
            </div>
          </div> -->

          <div class="form-group row mb-1">
            <label for="country" class="col-2 col-form-label p-1"><span class="required">*</span>Country</label>
            <div class="col-10">
              <!-- <input class="form-control p-1" type="text" value="" id="country" name="country" [formControl]="country" /> -->
              <ng-select
                #countrySelect
                [items]="countries | async"
                bindLabel="country"
                bindValue="id"
                name="country_id"
                labelForId="country"
                placeholder="Select a country"
                [formControl]="country_id"
                >
              </ng-select>
              <div [hidden]="country_id.valid || country_id.untouched" class="alert alert-danger p-1">Country is required.</div>
            </div>
          </div>

          <div class="form-group row mb-1">
            <label for="phone" class="col-2 col-form-label p-1"><span class="required">*</span>Phone</label>
            <div class="col-10">
              <input text="tel" class="form-control p-1" name="phone" id="phone" [formControl]="phone" maxlength="25" [readonly]="!isEditable" />
              <div [hidden]="phone.valid || phone.untouched" class="alert alert-danger p-1">phone number is required.</div>
            </div>
          </div>

          <div class="form-group row mb-1">
            <label for="email" class="col-2 col-form-label p-1"><span class="required">*</span>Email</label>
            <div class="col-10">
              <input
                type="email"
                class="form-control p-1"
                name="email"
                id="email"
                placeholder="user@example.com"
                [formControl]="email"
                [readonly]="!isEditable"
                />
                <div [hidden]="email.valid || email.untouched" class="alert alert-danger p-1">Valid email is required.</div>
              </div>
            </div>

            <div class="form-group row mb-1">
              <label for="user_type" class="col-2 col-form-label p-1"><span class="required">*</span>Privileges</label>
              <div class="col-10">
                <select class="form-control ml-auto mr-1" name="user_type" id="user_type" [formControl]="user_type" (change)="isUserAdmin()">
                  @for (userType of userTypes; track userType) {
                    <option
                      [ngValue]="userType.CODE"
                      [selected]="user_type.value == userType.CODE"
                      [disabled]="!isEditable"
                      >
                      {{ userType.DECODE }}
                    </option>
                  }
                </select>
              </div>
            </div>

            @if (!isAdmin) {
              <div class="form-group row mb-1">
                <label for="group_id" class="col-2 col-form-label p-1"><span class="required">*</span>Company</label>
                <div class="col-10">
                  @if (getUserType() == 'D' && isLoggedInUser(userFormss.value.user_id)) {
                    {{ getGroupByID(group_id.value).name }}
                  } @else {
                    <select
                      class="form-control ml-auto mr-1"
                      name="group_id"
                      id="group_id"
                      [formControl]="group_id"
                      (ngModelChange)="getLocationsByGroupID($event)"
                      >
                      <option value=""></option>
                      @for (group of groups | async; track group) {
                        <option [ngValue]="group.group_id" selected="group_id.value == group.group_id">
                          {{ group.name }}
                        </option>
                      }
                    </select>
                    <div [hidden]="group_id.valid || group_id.untouched" class="alert alert-danger p-1">Company is required.</div>
                  }
                </div>
              </div>
            }

            @if (!isAdmin) {
              <div class="form-group row mb-1">
                <label for="location_id" class="col-2 col-form-label p-1"><span class="required">*</span>Location</label>
                <div class="col-10">
                  <input type="text" [formControl]="queryString" class="form-control p-1" id="search" placeholder="Search for location" />
                  @for (x of selectedLocations; track x) {
                    <span class="badge badge-success m-1">
                      <i class="fa fa-close" (click)="removeLocation(x.id)"></i>{{ x.name }}
                    </span>
                  }
                  <div id="locationContainer" class="card px-2">
                    @for (location of locations | async | FilterPipe: queryString.value; track location) {
                      <div>
                        <!-- | slice:0:2; let i = index; -->
                        <input
                          type="checkbox"
                          name="loc"
                          class="loc_{{ location.location_id }}"
                          id="loc_{{ location.location_id }}"
                          value="{{ location.location_id }}"
                          (click)="addLocation(location.name, location.location_id, $event)"
                          [checked]="isLocationSelected(location.location_id)"
                          />
                          &nbsp;<label for="loc_{{ location.location_id }}" style="margin-bottom: 0">{{ location.name }}</label>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              }

              @if (!isAdmin) {
                <div class="form-group row mb-1">
                  <label for="device_id" class="col-2 col-form-label p-1"><span class="required">*</span>Silos</label>
                  <div class="col-10">
                    <input type="text" [formControl]="queryStringDevice" class="form-control p-1" id="search_device" placeholder="Search for Silo" />
                    @for (device of selectedDevices; track device) {
                      <span class="badge badge-success m-1">
                        <i class="fa fa-close" (click)="removeDevice(device.id)"></i>{{ device.name }}
                      </span>
                    }
                    <div id="deviceContainer" class="card px-2" style="height: 10rem; overflow-y: auto">
                      @for (device of filteredDevices | FilterPipe: queryStringDevice.value; track device) {
                        <div>
                          <input
                            type="checkbox"
                            name="dev"
                            class="device_{{ device.id }}"
                            id="device_{{ device.id }}"
                            value="{{ device.id }}"
                            (click)="addDevice(device.name, device.id, $event)"
                            [checked]="isDeviceSelected(device.id)"
                            />
                            &nbsp;<label for="device_{{ device.id }}" style="margin-bottom: 0">{{ device.name }}</label>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                }

                <div class="form-group row mb-1">
                  <label for="status_cde" class="col-2 col-form-label p-1"><span class="required">*</span>User Status</label>
                  <div class="col-10">
                    <select class="form-control ml-auto mr-1" name="status_cde" id="status_cde" [formControl]="status_cde">
                      <option [ngValue]="'a'" [selected]="status_cde.value === 'a'" [disabled]="!isEditable">Active</option>
                      <option [ngValue]="'z'" [selected]="status_cde.value !== 'a'" [disabled]="!isEditable">Inactive</option>
                    </select>
                  </div>
                </div>

                <div class="form-group row mb-1">
                  <label class="col-2 col-form-label p-0">&nbsp;</label>
                  <div class="col-10">
                    <!--  || disableBtn -->
                    <button class="p-1 btn btn-success mr-1" [disabled]="!userFormss.valid || !isEditable == true || !canUpdate || ((!selectedDevices?.length || !selectedLocations?.length) && permissions.userType != 'B')">Save User</button>
                    @if (user_id.value !== 0) {
                      <button
                        [disabled]="!isEditable || !canUpdate"
                        class="p-1 mr-1 btn btn-danger"
                        data-toggle="modal"
                        data-target="#delDevice"
                        (click)="deleteUser()"
                        >
                        Delete
                      </button>
                    }
                    @if (user_id.value !== 0) {
                      <button class="p-1 btn btn-info mr-1" (click)="resendWelcomeEmail()" [disabled]="!canUpdate">
                        Re-Send Welcome Email
                      </button>
                    }
                    <button class="p-1 btn btn-light" routerLink="/administration/users">Cancel</button>
                  </div>
                </div>
              </form>

              <div class="modal fade" id="updatePassword" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                  <div class="modal-content">
                    @if (message && message.length > 0) {
                      <div
                        class="modal-body alert text-center"
                        [ngClass]="hasError ? 'alert-danger' : 'alert-success'"
                        >
                        {{ message }}
                      </div>
                    }
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">{{ user_name.value }}</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <form
                      [formGroup]="userPassword"
                      (ngSubmit)="password.value === confirmpassword.value && savePassword(userPassword)"
                      autocomplete="off"
                      >
                      <input type="hidden" class="form-control p-1" name="user_id" id="user_id" [formControl]="user_id" />
                      <div class="modal-body">
                        <div class="form-group row mb-1">
                          <label for="password" class="col-2 col-form-label p-1"><span class="required">*</span>Password</label>
                          <div class="col-10">
                            <input type="password" class="form-control p-1" name="password" id="password" [formControl]="password" autocomplete="off" />
                          </div>
                        </div>
                        <div class="form-group row mb-1">
                          <label for="confirmpassword" class="col-2 col-form-label p-1"><span class="required">*</span>Confirm Password</label>
                          <div class="col-10">
                            <input
                              type="password"
                              class="form-control p-1"
                              name="confirmpassword"
                              id="confirmpassword"
                              [formControl]="confirmpassword"
                              autocomplete="off"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                          <button
                            type="submit"
                            class="btn btn-danger"
                            [disabled]="password.invalid || password.value !== confirmpassword.value || userFormss.value.user_name === password.value"
                            >
                            Update
                          </button>
                        </div>
                        <div class="modal-body">
                          @if (password.valid && password.value !== confirmpassword.value && password.touched && confirmpassword.touched) {
                            <div
                              class="col-12 alert alert-danger p-1"
                              >
                              The Password provided do not match.
                            </div>
                          }
                          @if (password.invalid && password.touched) {
                            <div class="col-12 alert alert-danger p-1">
                              <p>Password must have</p>
                              <ul>
                                <li>At least 8 characters</li>
                                <li>At least one number</li>
                                <li>At least one capital letter</li>
                                <li>At least one small letter</li>
                                <li>At least one special character (*!&#64;#$%^&)</li>
                              </ul>
                            </div>
                          }
                          @if (password.valid && user_name.value === password.value) {
                            <div class="col-12 alert alert-danger p-1">
                              Password should be different from Login Name.
                            </div>
                          }
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
