import * as lkeys from 'lowercase-keys';
interface CountryJson{
	id:			number;
	country:	string;
	abbreviation:string;
	code: 		number;
}

export class Country implements CountryJson {

	static fromJSON( jsonObj ): Country{
		let st = Object.create( Country.prototype );

		let obj = Object.assign( st, lkeys(jsonObj));
		return obj;
	}

	constructor(
				public id: 			number,
				public country:		string,
				public abbreviation:string,
				public code:		number
				){}
}
   

