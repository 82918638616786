<div class="row navrow" [hidden]="hideNav()">
  <nav class="navbar fixed-top navbar-expand-md navbar-light bg-light">
    <button
      class="navbar-toggler navbar-toggler-right"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
      >
      <span class="navbar-toggler-icon"></span>
    </button>
    <a class="navbar-brand" routerLink="/devices/{{ getView() }}">
      <img src="../assets/images/ST-Cloud-Header-Logo.jpg" width="211" height="40" alt="" />
    </a>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li data-toggle="collapse" data-target="#navbarSupportedContent" class="nav-item" routerLinkActive="active">
          <a href="#" class="nav-link" routerLink="/devices/{{ getView() }}"
            ><i class="fa fa-home" aria-hidden="true"></i>&nbsp;Home<span class="sr-only">(current)</span></a
            >
          </li>
          @if (showAdminLink()) {
            <li data-toggle="collapse" data-target="#navbarSupportedContent" class="nav-item" routerLinkActive="active">
              <a href="#" class="nav-link" routerLink="/administration"><i class="fa fa-cog" aria-hidden="true"></i>&nbsp;Admin</a>
            </li>
          }
          <li data-toggle="collapse" data-target="#navbarSupportedContent" class="nav-item" routerLinkActive="active">
            <a href="#" class="nav-link" routerLink="/report"><i class="fa fa-signal" aria-hidden="true"></i>&nbsp;Report</a>
          </li>
          <li data-toggle="collapse" data-target="#navbarSupportedContent" class="nav-item" routerLinkActive="active">
            <a href="#" class="nav-link" routerLink="/support"><i class="fa fa-envelope-o" aria-hidden="true"></i>&nbsp;Support</a>
          </li>
          <li data-toggle="collapse" data-target="#navbarSupportedContent" class="nav-item" routerLinkActive="active">
            <a href="#" class="nav-link" routerLink="/administration/profile"><i class="fa fa-cog" aria-hidden="true"></i>&nbsp;Profile</a>
          </li>
          <!-- <li data-toggle="collapse" data-target="#navbarSupportedContent"  class="nav-item" routerLinkActive="active">
          <a href="#" class="nav-link" routerLink="/help"><i class="fa fa-cog" aria-hidden="true" ></i>&nbsp;Help</a>
        </li> -->
        <li data-toggle="collapse" data-target="#navbarSupportedContent" class="nav-item">
          <a href="#" class="nav-link" (click)="logout()" routerLink="/"><i class="fa fa-sign-out" aria-hidden="true"></i>&nbsp;Logout</a>
        </li>
      </ul>
    </div>
  </nav>
</div>
<div class="clearfix">&nbsp;</div>
