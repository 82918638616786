<div class="row">
  <div class="col-12">
    <h3>Edit Company</h3>
  </div>
  @if (message && message.length > 0) {
    <div class="col-12 alert text-center" [ngClass]="hasError ? 'alert-danger' : 'alert-success'">
      {{ message }}
    </div>
  }
</div>
<div class="btn-group m-1">
  <button routerLink="/administration/groups" class="btn btn-primary"><i class="fa fa-arrow-left"></i>Companies</button>
</div>

<form [formGroup]="groupForm" (ngSubmit)="groupForm.valid && submitGroup()" autocomplete="false" name="l;kasdl;kasd;asd;">
  <div class="form-group row mb-1">
    <label for="name" class="col-2 col-form-label p-1"><span class="required">*</span>Company</label>
    <div class="col-10">
      <input type="text" class="form-control p-1" name="name" id="name" [formControl]="name" maxlength="35" />
      <div [hidden]="name.valid || name.untouched" class="alert alert-danger p-1">Company name is required</div>
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="customer_number" class="col-2 col-form-label p-1"><span class="required">*</span>Account #</label>
    <div class="col-10">
      <input type="text" class="form-control p-1" name="customer_number" id="customer_number" [formControl]="customer_number" />
      <div [hidden]="customer_number.valid || customer_number.untouched" class="alert alert-danger p-1">Account # is required</div>
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="customer_number" class="col-2 col-form-label p-1"><span class="required">*</span>Bill To Key</label>
    <div class="col-10">
      <input type="text" class="form-control p-1" name="bill_to_key" id="bill_to_key" [formControl]="bill_to_key" />
      <div [hidden]="bill_to_key.valid || bill_to_key.untouched" class="alert alert-danger p-1">Bill To Key is required</div>
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="status_cde" class="col-2 col-form-label p-1">Status</label>
    <div class="col-10">
      <select class="form-control ml-auto mr-1" name="status_cde" [formControl]="status_cde">
        <option [ngValue]="'A'" [selected]="status_cde.value === 'A' || status_cde.value == ''">Active</option>
        <option [ngValue]="'I'" [selected]="status_cde.value === 'I'">Inactive</option>
      </select>
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="addr1" class="col-2 col-form-label p-1"><span class="required">*</span>Address 1</label>
    <div class="col-10">
      <input class="form-control p-1" type="text" value="" id="addr1" name="addr1" [formControl]="addr1" />
      <div [hidden]="addr1.valid || addr1.untouched" class="alert alert-danger p-1">Address is required</div>
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="addr2" class="col-2 col-form-label p-1">Address 2</label>
    <div class="col-10">
      <input class="form-control p-1" type="text" value="" id="addr2" name="addr2" [formControl]="addr2" />
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="city" class="col-2 col-form-label p-1"><span class="required">*</span>City</label>
    <div class="col-10">
      <input class="form-control p-1" type="text" value="" id="city" name="city" [formControl]="city" />
      <div [hidden]="city.valid || city.untouched" class="alert alert-danger p-1">City is required</div>
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="state" class="col-2 col-form-label p-1"><span class="required">*</span>State/Province</label>
    <div class="col-10">
      <!-- <input class="form-control p-1" type="text" value="" id="state" name="state" [formControl]="state" /> -->
      <select class="form-control" name="state_id" [formControl]="state_id">
        @for (state of states | async; track state) {
          <option [value]="state.id">{{ state.state }}</option>
        }
      </select>
      <div [hidden]="state_id.valid || state_id.untouched" class="alert alert-danger p-1">State/Province is required</div>
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="zip" class="col-2 col-form-label p-1"><span class="required">*</span>Zip/Postal Code</label>
    <div class="col-10">
      <input class="form-control p-1" type="text" value="" id="zip" name="zip" [formControl]="zip" />
      <div [hidden]="zip.valid || zip.untouched" class="alert alert-danger p-1">Zip/Postal Code is required</div>
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="country" class="col-2 col-form-label p-1"><span class="required">*</span>Country</label>
    <div class="col-10">
      <!-- <input class="form-control p-1" type="text" value="" id="country" name="country_id" [formControl]="country_id" /> -->
      <ng-select
        #countrySelect
        [items]="countries | async"
        bindLabel="country"
        bindValue="id"
        name="country"
        labelForId="country"
        placeholder="Select a country"
        [formControl]="country_id"
        >
      </ng-select>
      <div [hidden]="country_id.valid || country_id.untouched" class="alert alert-danger p-1">Country is required</div>
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="phone" class="col-2 col-form-label p-1"><span class="required">*</span>Phone</label>
    <div class="col-10">
      <input class="form-control p-1" type="tel" value="" id="phone" name="phone" [formControl]="phone" maxlength="25" />
      <div [hidden]="phone.valid || phone.untouched" class="alert alert-danger p-1">phone number is required.</div>
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="phone" class="col-2 col-form-label p-1">Two Factor Authentication</label>
    <div class="col">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" [formControl]="is_2fa_required" [value]="1" />
        <label class="form-check-label" for="inlineCheckbox1">Yes</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" [formControl]="is_2fa_required" [value]="0" />
        <label class="form-check-label" for="inlineCheckbox2">No</label>
      </div>
    </div>
  </div>

  <div class="form-group row mb-1">
    <label for="longitude" class="col-2 col-form-label p-1">&nbsp;</label>
    <div class="col-10">
      <button class="form-controlz p-1 btn btn-success mr-1" [disabled]="!groupForm.valid">Update Company</button>
      <button class="form-controlz p-1 btn btn-danger mr-1" data-toggle="modal" data-target="#delGroup" (click)="fakeDelete(0)">
        Delete
      </button>
    </div>
    @if (!groupForm.valid) {
      <div class="col-12 alert alert-danger">Note: Please fill out all the fields marked by *.</div>
    }
  </div>
</form>
<div class="modal fade" id="delGroup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{ name.value }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">Are you sure you want to delete {{ name.value }}?</div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary mr-1" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="deleteGroup(group_id.value)" data-dismiss="modal">Delete</button>
      </div>
    </div>
  </div>
</div>
