import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormsModule, UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormControl, ReactiveFormsModule, FormArray } from '@angular/forms';

import { Observable } from 'rxjs';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/filter';

import { LocationsService } from '../../services/locations.service';
import { GroupsService } from '../../services/groups.service';
import { StatesService } from '../../services/states.service';
import { CountriesService } from '../../services/countries.service';

import { Location } from '../../model/location';
import { Group } from '../../model/group';
import { State } from '../../model/state';
import { Country } from '../../model/country';
import { Permissions } from '../../model/permissions';
import { Codes } from '../codes';

import { NgSelectModule, NgOption } from '@ng-select/ng-select';

@Component({
	selector: 'app-location-edit',
	templateUrl: './location-edit.component.html',
	styleUrls: ['./location-edit.component.css'],
})
export class LocationEditComponent implements OnInit {
	public permissions = new Permissions();
	public hasError: boolean;
	public message: string;
	public location: Location;
	public groups: Observable<Group[]>;
	public codes: Codes = new Codes();
	public states: Observable<State[]>;
	public countries: Observable<Country[]>;
	public canUpdate = false;

	location_id = new UntypedFormControl(0);
	name = new UntypedFormControl('', [Validators.required, Validators.maxLength(25)]);
	group_id = new UntypedFormControl('', [Validators.required]);
	status_cde = new UntypedFormControl('A');
	addr1 = new UntypedFormControl('');
	addr2 = new UntypedFormControl('');
	city = new UntypedFormControl('');
	state_id = new UntypedFormControl('');
	zip = new UntypedFormControl('');
	country_id = new UntypedFormControl('');
	phone = new UntypedFormControl('', [Validators.required]);
	observes_dst = new UntypedFormControl('', [Validators.required]);
	timezone = new UntypedFormControl('', [Validators.required]);
	site_contact_name = new UntypedFormControl('', [Validators.required]);
	_group: Group;

	locationForm: UntypedFormGroup = this.builder.group({
		location_id: this.location_id,
		name: this.name,
		group_id: this.group_id,
		status_cde: this.status_cde,
		addr1: this.addr1,
		addr2: this.addr2,
		city: this.city,
		state_id: this.state_id,
		zip: this.zip,
		country_id: this.country_id,
		phone: this.phone,
		observes_dst: this.observes_dst,
		timezone: this.timezone,
		site_contact_name: this.site_contact_name,
	});

	constructor(
		private activatedRoute: ActivatedRoute,
		private locationsService: LocationsService,
		private statesService: StatesService,
		private groupsService: GroupsService,
		private countriesService: CountriesService,
		private builder: UntypedFormBuilder,
		private router: Router
	) {
		this.canUpdate = ['A', 'B', 'D'].filter((type) => this.getUserType() === type).length > 0;
	}

	ngOnInit() {
		this.groupsService.getGroups();
		this.groups = this.groupsService.groups;
		this.states = this.statesService.getStates();
		this.countries = this.countriesService.getCountries();

		if (!this.permissions.hasLocationsPermission()) {
			this.router.navigateByUrl('devices');
		}
		let locationId;
		this.activatedRoute.params.subscribe((params: Params) => {
			locationId = params['id'];
			this.locationsService.getLocations(locationId);
		});

		this.locationsService.locations.subscribe((locs) =>
			locs
				.filter((loc) => loc.location_id == locationId)
				.map((l) => {
					this.location_id = new UntypedFormControl(l.location_id);
					this.name = new UntypedFormControl(l.name, [Validators.required, Validators.maxLength(25)]);
					this.group_id = new UntypedFormControl(l.group_id, [Validators.required]);
					this.status_cde = new UntypedFormControl(l.status_cde.toUpperCase());
					this.addr1 = new UntypedFormControl(l.addr1);
					this.addr2 = new UntypedFormControl(l.addr2);
					this.city = new UntypedFormControl(l.city);
					this.state_id = new UntypedFormControl(l.state_id);
					this.zip = new UntypedFormControl(l.zip);
					this.country_id = new UntypedFormControl(l.country_id);
					this.phone = new UntypedFormControl(l.phone, [Validators.required]);
					this.observes_dst = new UntypedFormControl(l.observes_dst, [Validators.required]);
					this.timezone = new UntypedFormControl(l.timezone, [Validators.required]);
					this.site_contact_name = new UntypedFormControl(l.site_contact_name, [Validators.required]);

					this.locationForm = this.builder.group({
						location_id: this.location_id,
						name: this.name,
						group_id: this.group_id,
						status_cde: this.status_cde,
						addr1: this.addr1,
						addr2: this.addr2,
						city: this.city,
						state_id: this.state_id,
						zip: this.zip,
						country_id: this.country_id,
						phone: this.phone,
						observes_dst: this.observes_dst,
						timezone: this.timezone,
						site_contact_name: this.site_contact_name,
					});

					// this.setGroupByID(l.group_id);
				})
		);
	}

	submitLocation() {
		// console.log(this.model);
		this.hasError = false;
		this.message = '';

		if (!this.canUpdate) {
			this.hasError = true;
			this.message = 'Sorry! You do not have permission to edit.';
			return false;
		}

		this.locationsService.saveLocation(this.locationForm.value);
		this.locationsService.hasError.subscribe((x) => (this.hasError = x));
		this.locationsService.message.subscribe((x) => (this.message = x));
		this.locationsService.location_id.subscribe((x) => {
			this.locationForm.controls['location_id'].setValue(x);
		});
	}
	fakeDelete() {
		return false;
	}
	deleteLocation() {
		if (!this.canUpdate) {
			this.hasError = true;
			this.message = 'Sorry! You do not have permission to delete.';
			return false;
		}
		this.locationsService.deleteLocation({ location_id: this.location_id.value });
		this.locationsService.hasError.subscribe((x) => (this.hasError = x));
		this.locationsService.message.subscribe((x) => (this.message = x));
	}

	canEditCompany() {
		if (this.permissions.hasCompaniesPermission()) {
			return true;
		} else if (!this.permissions.hasCompaniesPermission() && this.location_id.value === 0) {
			return true;
		} else {
			return false;
		}
	}

	getGroupByID(id): any {
		let jj: any = {};
		this.groups.subscribe((x) =>
			x
				.filter((a) => a.group_id === id)
				.map((b) => {
					jj = b;
				})
		);
		return jj;
	}

	getStatusCode(cde) {
		return this.codes.deCode('status_cde', cde);
	}

	getUserType() {
		return this.permissions.userType;
	}
}
