import { User } from '../../model/user';

export class FetchUsers {
    static readonly type = '[USER] Fetch Users';
}

export class UserSort {
    static readonly type = '[USER] User Sort'

    constructor(public payload: string) {}
}

export class UserFilter {
    static readonly type = '[USER] User Filter'

    constructor(public payload: {filterByList:string,searchString:string, exactMatch: boolean}) {}
}
