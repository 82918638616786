interface ChannelJson{
	id: 			number;
	name: 			string;
	datatype: 		string;
	dia_name: 		string;
	channelType: 	string;
}

export class Channel {

	static fromJSON( jsonObj ): Channel{
		let channel = Object.create( Channel.prototype );

		let obj = Object.assign( channel, jsonObj,{	
													id:				jsonObj.ID
													, name:			jsonObj.NAME
													, datatype:		jsonObj.DATATYPE
													, dia_name:		jsonObj.DIA_NAME
													, channelType:	jsonObj.CHANNELTYPE
												});
		return obj;
	}

	constructor(
			  	public id: 				number
				, public name: 			number
				, public datatype: 		string
				, public dia_name:		string
				, public channelType: 	string
			){ 		
    }
   
}
