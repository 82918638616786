import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

import { Store, Select } from '@ngxs/store';
import { GatewayState } from '../../store/states/gateways.state';
import { FetchGateways, GatewayFilter, GatewaySort } from '../../store/actions/gateways.action';
import { PagerComponent } from '../../pager/pager.component';

import { Gateway } from '../../model/gateway';
import { Permissions } from '../../model/permissions';

import { AuthService } from '../../auth.service';

@Component({
	selector: 'app-gateways',
	templateUrl: './gateways.component.html',
	styleUrls: ['./gateways.component.css']
})
export class GatewaysComponent implements OnInit {

	permissions: Permissions = new Permissions();
	userType = '';


	@Select(GatewayState.getGateways) gateways: Observable<Gateway[]>
	@Select(GatewayState.getSortDirection) sortDirection: Observable<string>
	@Select(GatewayState.getSortField) sortField: Observable<string>
	@ViewChild(PagerComponent) pager: PagerComponent;
	public rowItems: Gateway[] = [];

	constructor(private store: Store, authService: AuthService, router: Router) {

		if (!this.permissions.hasGatewaysPermission()) {
			router.navigateByUrl('/devices');
		}
		this.userType = this.permissions.userType.trim().toUpperCase();
	}

	ngOnInit() {
		this.store.dispatch(new FetchGateways());
	}


	setPagedItem(rows) {
		this.rowItems = rows;
	}

	sort(field) {
		this.store.dispatch(new GatewaySort(field));
		this.pager.setPage(1)
	}

	filter(field, str, exactMatch) {
		field = 'group_name,location_name,mac,name';
		this.store.dispatch(new GatewayFilter({ filterByList: field, searchString: str, exactMatch }));
	}

}
