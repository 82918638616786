<div class="row">
  <div class="col-12">
    <h3>Gateways</h3>
  </div>
  <div class="col-12">
    <div class="row m-1">
      <app-admin-nav></app-admin-nav>
      <!-- <button routerLink="/administration/gateway/edit/0" class="btn btn-primary py-0" *ngIf="userType == 'A' || userType == 'B'"><i
    class="fa fa-plus"></i>Add Gateway</button> -->
    <input type="text" name="search" value="" class="col-12 col-sm-6 col-md-3 form-control" placeholder="keyword"
      (keyup)="filter('',$event.target.value, false)">
    </div>
  </div>
  <div class="col-12">
    <table class="table table-striped tableList">
      <thead class="table-dark">
        <tr>
          <th (click)="sort('group_name')">
            <a [className]="(sortField | async) == 'group_name'? (sortDirection | async) : 'sort'">Company</a>
          </th>
          <th (click)="sort('location_name')">
            <a [className]="(sortField | async) == 'location_name'? (sortDirection | async) : 'sort'">Location</a>
          </th>
          <th (click)="sort('mac')">MAC
            <a [className]="(sortField | async) == 'mac'? (sortDirection | async) : 'sort'">Address</a>
          </th>
          <th (click)="sort('name')">
            <a [className]="(sortField | async) == 'name'? (sortDirection | async) : 'sort'">Name</a>
          </th>
          <th>Status</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        @for (gateway of rowItems; track gateway) {
          <tr>
            <td>{{ gateway.group_name }}</td>
            <td>{{ gateway.location_name }}</td>
            <td>{{ gateway.mac }}</td>
            <td>{{ gateway.name }}</td>
            <td>{{ gateway.status_cde.toLowerCase() == 'a'?'Active':'Inactive' }}</td>
            <td nowrap>
              <div class="btn-group" role="group" aria-label="buttons" [hidden]="!(userType == 'A' || userType == 'B')">
                <button class="btn btn-info p-1 pointer" routerLink="/administration/gateway/edit/{{gateway.gateway_id}}"><i
                class="fa  fa-pencil"></i>Edit</button>
                <!-- <button class="disabled btn btn-danger p-1"><i class="fa pointer fa-trash" alt="Delete"></i></button>  (click)="delete(gateway.gateway_id)"  -->
              </div>
            </td>
          </tr>
        }
      </tbody>
    </table>


    <div class="row">
      <div class="col-12">
        <app-pager [allItems]="gateways | async" (pagedItemsNew)="setPagedItem($event)"></app-pager>
      </div>
    </div>
  </div>
</div>