import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

import { Permissions } from '../../model/permissions';

import { Group } from '../../model/group';
import { Codes } from '../codes';


import { Store, Select } from '@ngxs/store';
import { GroupState } from '../../store/states/groups.state';
import { FetchGroups, GroupFilter, GroupSort } from '../../store/actions/groups.action';
import { PagerComponent } from '../../pager/pager.component';

@Component({
	selector: 'app-groups',
	templateUrl: './groups.component.html',
	styleUrls: ['./groups.component.css']
})
export class GroupsComponent implements OnInit {
	permissions: Permissions = new Permissions();
	codes: Codes = new Codes();
	
	@Select(GroupState.getGroups) groups: Observable<Group[]>
	@Select(GroupState.getSortDirection) sortDirection: Observable<string>
	@Select(GroupState.getSortField) sortField: Observable<string>
	@ViewChild(PagerComponent) pager: PagerComponent;
	public rowItems: Group[] = [];

	constructor(private router: Router, private store: Store) {
		if (!this.permissions.hasCompaniesPermission()) {
			this.router.navigateByUrl('devices');
		}
	}

	ngOnInit() {
		this.store.dispatch(new FetchGroups());
	}


	getStatusCode(cde) {
		return this.codes.deCode('status_cde', cde);
	}


	setPagedItem(rows) {
		this.rowItems = rows;
	}

	sort(field) {
		this.store.dispatch(new GroupSort(field));
		this.pager.setPage(1)
	}

	filter(field, str, exactMatch) {
		this.store.dispatch(new GroupFilter({ filterByList: field, searchString: str, exactMatch }));
	}
}
