import { Component, OnInit } from '@angular/core';
import { Permissions } from '../model/permissions';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
	permissions = new Permissions();
	isAdmin = false;
	disabled:boolean = true;
	constructor() { }

	ngOnInit() {
		this.isAdmin = this.permissions.userType === 'A' || this.permissions.userType === 'B';
	}

}
