import { Observable } from 'rxjs';
import { Group } from '../../model/group';

export class FetchLocations {
	static readonly type = '[LOCATION] Fetch Locations';
}

export class LocationChecked {
	static readonly type = '[LOCATION] Checked';

	constructor(public payload: number) {}
}

export class LocationGroupIDs {
	static readonly type = '[LOCATION] Selected Group';

	constructor(public payload: Observable<number[]>) {}
}

export class LocationSort {
	static readonly type = '[LOCATION] Selected Sort';

	constructor(public payload: string) {}
}

export class LocationFilter {
	static readonly type = '[LOCATION] Location Filter';

	constructor(public payload: { filterByList: string; searchString: string; exactMatch: boolean }) {}
}
